import * as Actions from '../actions'

const initialState = {
    data: []
}

const screenshotsdata = function (state=initialState, action) {
    switch(action.type){
        case Actions.GET_KUBE_SCREENSHOTS_DATA:
        {
            return {
                ...state,
                data: action.payload.body
            }
        }
        default:
        {
            return state
        }
    }
}

export default screenshotsdata