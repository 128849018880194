import React, { useEffect, useState } from 'react'
import { Usertable, Usercard } from '../index'
import { MdTableRows, MdGridView } from 'react-icons/md'
import DatePicker from "react-datepicker";
import moment from 'moment'
import '../../App.css'

const Userselect = () => {

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startD, setSDate] = useState();
    const [endD, setEDate] = useState();
    const [display, setDisplay] = useState(true);
    function changeDate(date) {
        const dateFormat = 'YYYY-MM-DD';
        const newDate = moment(new Date(date)).format(dateFormat);
        return newDate;
    }

    useEffect(() => {
        const date = new Date() - 518400000;
        const newDate = changeDate(date);
        setStartDate(new Date(newDate));
        const EndDate = changeDate(new Date(date + 518400000));
        setSDate(newDate);
        setEndDate(new Date(EndDate));
        setEDate(EndDate);
    }, [])


    const handleStartDateChange = (date) => {
        const var1 = changeDate(date);
        setStartDate(new Date(var1));
        setSDate(var1);
    }

    const handleEndDateChange = (date) => {
        const var1 = changeDate(date);
        setEDate(var1);
        setEndDate(new Date(var1));
    }

    const handleGridClick = () => {
        setDisplay(false)
    }

    const handleListClick = () => {
        setDisplay(true)
    }

    return (
        <div className='userselect--top--level--wrapper'>
            <div className='userselect--top--section'>
                    <button className="userselect__grid-button" onClick={handleGridClick}><MdGridView /></button>
                    <button className="userselect__list-button" onClick={handleListClick}><MdTableRows /></button>
                <div className='userselect--datepickers--wrapper'>
                    <DatePicker selected={startDate} onChange={handleStartDateChange} />
                    <DatePicker selected={endDate} onChange={handleEndDateChange} />
                </div>
            </div>
            <div className='userselect--usertable--or--usercard--wrapper'>
                {display ? <Usertable startDate={startD} endDate={endD} /> : <Usercard startDate={startD} endDate={endD} />}
            </div>
        </div>
    )
}

export default Userselect