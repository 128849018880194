import React from 'react'

export const Filter = ({ filter, setFilter}) => {
  return (
  <div className='kube__userselect-filter'>
      <input value={filter || ''}
      onChange={e => setFilter(e.target.value)}
      placeholder='Search' />
  </div>
  );
};
