import React, { useState, useEffect } from 'react'
import reducer from '../store/reducers'
import withReducer from '../store/withReducer'
import { useParams } from 'react-router-dom'
import PieChart from './landingPageGraphs/PieChart'
import DatePicker from "react-datepicker";
import moment from 'moment';
import styled from 'styled-components'
import BarGraphWidget from './landingPageGraphs/BarGraphWidget'
import ComplianceOverlayCard from './landingCompliance/ComplianceOverlayCard'
import { RectButton } from '../../workspace/Bar'
import '../../../../src/App.css'
import { Dialog } from '@material-ui/core'
import Invalidslotstable from './invalidatedtable'
import { IconButton } from '@mui/material';
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from '@mui/material/Tooltip'

const KubeLandingWrapper = styled.div`
  display: flex;
  border-radius: 2rem;
  padding: 2.75rem;
  padding-top: 3.5rem;
  height: 40em;
  width: 66rem;
  margin : auto;
  max-width: 67rem;
  text-align: center;
  position: relative;
  background: #ECF0F3;
  box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #D1D9E6;
  border-radius: 10px;
`

const LandingTop = styled.div`
 width: 100%;
  height: auto;
`
const LandingLeft = styled.div` 
  width: 50%;
  margin-top: 7rem;
  margin-left: 2rem;
`

const LandingRight = styled.div`
  margin-left: 2rem;
  margin-right: 45rem;
  padding-bottom: 10px;
`
const LandingDatepicker = styled.div`
  margin-left: 3rem;
  margin-right: -28rem;
  display:flex;
  flex-direction: row;
  gap: 1.5rem;
  margin-bottom: 5rem;
  padding-top: 15px;
  padding-bottom: 10px;
`
const SmallButton = styled(RectButton)`
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    width: 10rem;
    height: 3rem;
    display:inline-block
    font-size: 1rem;
    margin-bottom: 4rem;
    background: #ECF0F3;
    box-shadow: -8px -8px 16px #ffffff, 8px 8px 16px #d1d9e6;
    border-radius: 10px;
    border: none;
    color: #4B566C;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    border-radius: .7rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    
    transition: 0.3s;
    &:hover{
      color: #ECF0F3;
      background: #F3795E;
      box-shadow: -8px -8px 16px #ffffff, 8px 8px 16px #d1d9e6;
    }
`

const LandingIcon = () => {
  const { email } = useParams();
  const [startDate, setStartDate] = useState(new Date().getTime() - 518400000);//Set the start date to current date
  const [endDate, setEndDate] = useState(new Date().getTime());//Set the end date to 7 days after today {Tell how much steps tp cover}
  const [startD, setSDate] = useState(new Date() - 518400000);//Tell what is exact date in utc form
  const [endD, setEDate] = useState(new Date());//use for exact date in utc form
  const [showOverlayCard, setShowOverlayCard] = useState(false)
  const [dilagshowstatus,setdialogstatus] = useState(false)
  const [invalidatedslotsdetailsobj,setinvalidatedslotsdetails] = useState({
    'email' :email,
    'startDate' :changeDate(startDate) ,
    'endDate' : changeDate(endDate)
  }

)
  const changeOverlayCardState = () => {
    setShowOverlayCard(false)
  }

  //hnadle start date cahnge and hande end change
  function changeDate(date) {
    const dateFormat = 'YYYY-MM-DD';
    const newDate = moment(new Date(date)).format(dateFormat);
    return newDate
  }

  const handleStartDateChange = (date) => {
    const newDate = changeDate(date)
    setStartDate(new Date(newDate).getTime());
    setinvalidatedslotsdetails((prevstate)=>{
      return{
      ...prevstate,
      startDate:newDate
    }})
  }

  const handleEndDateChange = (date) => {
    const newDate = changeDate(date)
    setEndDate(new Date(newDate).getTime());
    setinvalidatedslotsdetails((prevstate)=>{
      return{
      ...prevstate,
      endDate:newDate
    }})
  }



  return (
    <>
      <ComplianceOverlayCard currUserEmail={email} isVisible={showOverlayCard} changeOverlayCardState={changeOverlayCardState} />
      <KubeLandingWrapper>
        <LandingTop>
          <LandingDatepicker>
            <DatePicker selected={startDate} onChange={handleStartDateChange} />
            <DatePicker selected={endDate} onChange={handleEndDateChange} />
          </LandingDatepicker>
        </LandingTop>
        <LandingLeft>
          <BarGraphWidget email={email} startDate={startDate} endDate={endDate} />
        </LandingLeft>
        <LandingRight>
          <div id ='landingright-buttons'>
          
            <SmallButton onClick={() => setShowOverlayCard(true)}>Compliance</SmallButton>
            <Tooltip title="Invalidated slots details between the dates show on left" arrow>
              <SmallButton onClick={()=>setdialogstatus(true)}>Invalidated slots Details</SmallButton>
            </Tooltip>
          </div>
          
          <PieChart email={email} startDate={startDate} endDate={endDate} />
        </LandingRight>
      </KubeLandingWrapper>

      <Dialog open={dilagshowstatus} fullWidth ={true} maxWidth="lg" style ={{minHeight:520}} onClose = {()=>{setdialogstatus(false)}}>
        <IconButton  aria-label="close" onClick={()=>setdialogstatus(false)}>
          <CloseIcon  />
        </IconButton>
        <Invalidslotstable  invalidobj= {invalidatedslotsdetailsobj} />
      </Dialog>
    </>
  )
}

export default withReducer(
  "LandingIcon",
  reducer
)(LandingIcon)
