import * as Actions from '../actions'

const initialState = {
  data: [],
  userGraph: {},
  userGraphDataLoading : false,
  timeworked: [],
  isUserTimeWorkedDataLoading : false
}

const usersReducer = function (state=initialState, action) {
    switch (action.type) {
      case Actions.GET_KUBE_USERS: {
        return {
          ...state,
          data: action.payload,
        }
      }
      case Actions.GET_KUBE_USERS_TIMEWORKED: {
        return {
          ...state,
          timeworked: action.payload,
        }
      }
      case Actions.IS_KUBE_USERS_TIMEWORKED_DATA_LOADING:
        {
          return {
            ...state,
            isUserTimeWorkedDataLoading: action.payload
          }
        }   
      case Actions.GET_KUBE_USERSGRAPHS: {
        return {
          ...state,
          userGraph: action.payload,
        }
      }
      case Actions.USER_GRAPH_DATA_LOADING:
        {
          return {
            ...state,
            userGraphDataLoading: action.payload
          }
        }   
      default: {
        return state
      }
    }
}

export default usersReducer