// Packages:
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import * as Actions from '../dashboard/store/actions'
import reducer from '../dashboard/store/reducers'
import withReducer from '../dashboard/store/withReducer'

// Imports:
import { IoPlay } from 'react-icons/io5'
import { IoMdPause } from 'react-icons/io'
import { IoMdRefresh } from 'react-icons/io'
import { MdDownload } from 'react-icons/md'
import { ImConnection } from 'react-icons/im'


// Constants:
import COLORS from './colors'


// Styles:
const BarWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 4rem;
  background-color: ${COLORS.LIGHT};
  border-bottom: 2px solid ${COLORS.DARK};
`

const Name = styled.div`
  padding: 0.5rem 1.5rem;
  background-color: ${props => props.isActive ? COLORS.GREEN : `#FFFFFF`};;
  font-weight: 500;
  font-size: 0.9rem;
  border-radius: 1rem;
  box-shadow: ${props => props.isActive ? '0 0 0 0 transparent' : `0 2px 10px 0px ${COLORS.DARK}`};
  user-select: none;
  cursor: pointer;
  transition: all 0.25s ease;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
`

const Tag = styled.div`
  margin: 0 0.75rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 0.75rem;
  border-radius: 0.75rem;
  background-color: white;
  user-select: none;
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin: 0 0.75rem;
  background-color: #F3795E;
  color: #FFF8F0;
  border-radius: 50%;
  box-shadow: ${props => props.isDisabled ? '0 0 0 0 transparent' : `0px 8px 20px rgba(0, 0, 0, 0.25)`};
  filter: ${props => props.isDisabled ? 'opacity(0.5)' : 'opacity(1)'};
  user-select: none;
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  transition: all 0.25s ease;
  &:active {
    box-shadow: 0 0 0 0 transparent;
  }
  &:hover {
    background-color: #E24623;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

export const RectButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin: 0 0.75rem;
  background-color: #F3795E;
  color: #FFF8F0;
  filter: ${props => props.isDisabled ? 'opacity(0.5)' : 'opacity(1)'};
  user-select: none;
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  transition: all 0.25s ease;
`

const BoxWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: ${props => props.isActive ? `50vh` : '0'};
  border-bottom: ${props => props.isActive ? `2px solid ${COLORS.DARK}` : 'none'};
  filter: ${props => props.isActive ? 'opacity(1)' : 'opacity(0)'};
  background: #F3DB88;
  transition: all 0.25s ease;
`

const List = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-size: 1rem;
  overflow: hidden;
  & > span {
    margin: 0.5rem 2rem;
  }
`


// Functions:
const Bar = (props) => {
  // State:
  const state = props.data.State
  const [command, setCommand] = useState("")
  const [refresh, setRefresh] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions.getKubeWorkspaces());
  }, [dispatch, refresh])

  useEffect(() => {
    dispatch(Actions.manageWorkspaces(command));
  }, [dispatch, command])

  const handleStart = () => {
    setCommand("start")
  }

  const handleStop = () => {
    setCommand("stop")
  }

  const handleRefresh = () => {
    setRefresh(refresh + 1)
  }

  // Return:
  return (
    <>
      <BarWrapper style={props.style}>
        <span style={{ marginLeft: '1.5rem' }}><Name isActive={props.isActive} onClick={() => props.isActive ? props.setSelectedBar(undefined) : props.setSelectedBar(props.WorkspaceId)}>{props.WorkspaceId}</Name></span>
        <Buttons style={{ marginRight: '1.5rem' }}>
          <Tag>Root Volume: {props.data.WorkspaceProperties.RootVolumeSizeGib} GB</Tag>
          <Tag>User Volume: {props.data.WorkspaceProperties.UserVolumeSizeGib} GB</Tag>
          <Button isDisabled={state === "AVAILABLE"} onClick={handleStart}><IoPlay /></Button>
          <Button isDisabled={state === "STOPPED"} onClick={handleStop}><IoMdPause /></Button>
          <Button onClick={handleRefresh}><IoMdRefresh /></Button>
          <Button><MdDownload /></Button>
          <Button><ImConnection /></Button>
        </Buttons>
      </BarWrapper>
      <BoxWrapper isActive={props.isActive} style={{ zIndex: props.isActive ? '1' : '-1' }}>
        <List style={{ flexDirection: 'row' }}>
          <List style={{ alignItems: 'end', marginRight: '-2.5rem', fontWeight: '500' }}>
            <span>User ID</span>
            <span>Directory ID</span>
            <span>Bundle ID</span>
            <span>Subnet ID</span>
            <span>Running Mode</span>
          </List>
          <List>
            <span>{props.data.UserName}</span>
            <span>{props.data.DirectoryId}</span>
            <span>{props.data.BundleId}</span>
            <span>{props.data.SubnetId}</span>
            <span>{props.data.WorkspaceProperties.RunningMode}</span>
          </List>
        </List>
        <List style={{ flexDirection: 'row' }}>
          <List style={{ alignItems: 'end', marginRight: '-2.5rem', fontWeight: '500' }}>
            <span>Workspace IP</span>
            <span>Computer Name</span>
            <span>AutoStop Time</span>
            <span>State</span>
          </List>
          <List>
            <span>{props.data.IpAddress}</span>
            <span>{props.data.ComputerName}</span>
            <span>{props.data.WorkspaceProperties.RunningModeAutoStopTimeoutInMinutes} minutes</span>
            <span>{state}</span>
          </List>
        </List>
      </BoxWrapper>
    </>
  )
}


// Exports:
export default withReducer(
  "bar",
  reducer
)(Bar)