export const ADMINHEADERS = [
    {
        Header: "Name",
        accessor: "user_id",
        maxWidth: 40,
        minWidth: 30,
        width: 32,
    },
    {
        Header: "Total Working Hours",
        accessor: "total_hours",
    },
    {
        Header: "Public Time Off",
        accessor: "public_PTO",
    },
    {
        Header: "Personal Time Off",
        accessor: "PTO_count"
    },
    {
        Header: "Effective Working Hours",
        accessor: "effective_hours"
    },
    {
        Header: "Hours Worked",
        accessor: "totalworkinghours"
    },
    {
        Header: "Average Intensity  %",
        accessor: "averageintensity"
    },
    {
        Header: "Work Units",
        accessor: "Done"
    },
    {
        Header: "Compliance  %",
        accessor: "compliance"
    },
    {
        Header: "Effective Compliance  %",
        accessor: "eff_compliance"
    }
]