import React from 'react'
import { Navigate } from 'react-router-dom'
import {Auth} from 'aws-amplify'


export const RequireAuth = ({ children }) => {

    async function getAmplifyUserAgent()
    {
        try 
        {
           await Auth.currentAuthenticatedUser();
           return true;
        }
        catch{
            return false;
        }
    };
    
    if(!getAmplifyUserAgent) {
        return <Navigate to='/login' />
    }

    return children;
}