import React, { useState, useEffect } from 'react'
import { DataGrid, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from '../../dashboard/store/actions'
import OverlayCard from './OverlayCard'
// import { useState } from 'react'
import moment from 'moment';
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Button } from "@material-ui/core";
import RefreshIcon from '@mui/icons-material/Refresh';
import { toggleSnackbar } from '../../dashboard/store/actions/kube.actions.js'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { MdOutlineSettings } from 'react-icons/md'
import { useNavigate } from "react-router-dom"
import IconButton from '@material-ui/core/IconButton'


function CustomEditComponent(props) {
  const { id, value: valueProp, field } = props;
  const [value, setValue] = useState(valueProp);
  const apiRef = useGridApiContext();

  const handleChange = (event) => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 6000 });
    setValue(newValue);
  };

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  return <input type="text" value={value} onChange={handleChange} />;
}

export default function AllUsersDetails() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [spinner, setSpinner] = useState(true);
  const logInStatus = useSelector((state) => state.loginUser.LoggedInUser);
  const authEmail = logInStatus.attributes.email

  useEffect(() => {
    setTimeout(() => setSpinner(false), 1000) //Spinner for user-experience
  }, []);

  const workspacesData = useSelector((workspace) => workspace.workspaces.adminAccessManagementData) //fetching data of overall user
  const isDataLoading = useSelector((workspace) => workspace.workspaces.isadminAccessManagementDataLoading)
  const deleteUser = useSelector((workspace) => workspace.workspaces.deleteUser) //fetching data for deleted user
  const [refresh, setRefresh] = useState(false)

  const [data, setData] = useState(workspacesData)

  var date = new Date()
  const dateFormat = "YYYY-MM-DD"
  const newDate = moment(new Date(date)).format(dateFormat)

  //Change the updates and send it to backend
  function ChangeData(username, parameter, valueParam) {
    dispatch(Actions.getWorkspaceEdit(username, parameter, valueParam));
    dispatch(toggleSnackbar({
      status: true,
      message: "Updating...Please refresh the table once",
      severity: "info"
    }))
    setTimeout(() => {
      dispatch(Actions.getAccessManagemntData(newDate));
    }, 1000);
  }

  useDeepCompareEffect(
    () => {
      setData(workspacesData);
    },
    [data],
  )
  useEffect(() => {
    if (refresh === true) {
      if (deleteUser === "Sucessfully deleted the user ") {
        dispatch(toggleSnackbar({
          status: true,
          message: deleteUser,
          severity: "success"
        }))
      }
      else if (deleteUser.length > 0) {
        dispatch(toggleSnackbar({
          status: true,
          message: deleteUser,
          severity: "error"
        }))
      }
    }
  }, [deleteUser])

  //function to delete specific id:
  const handleDelete = (id) => {
    setRefresh(true)
    dispatch(Actions.getDeleteUsers(authEmail, id.row.username));
  }

  //function to refresh:

  const handleRefresh = () => {
    dispatch(Actions.getAccessManagemntData(newDate));
    setRefresh(false)
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'username', headerName: 'User-name', width: 230 },
    {
      field: 'managername', headerName: 'Manager', width: 200, editable: true,
      renderEditCell: (params) => {
        return <CustomEditComponent {...params} />;
      },
      preProcessEditCellProps: (params) => {
        let arr = [];
        const paramChange = params.hasChanged;
        if (paramChange === true) {
          arr[0] = params.row.username;
          arr[1] = 'Managername';
          arr[2] = params.props.value;
          ChangeData(arr[0], arr[1], arr[2]);
          return arr;
        }
        return { ...params.props };
      },
    },
    {
      field: 'Group', headerName: 'Groups', editable: true, type: 'singleSelect',
      valueOptions: ['Engineering', 'Buisness'], width: 100,
      sortable: false, description: 'This column has a status value of Engineering & Buisness',
      preProcessEditCellProps: (params) => {
        let arr = [];
        const paramChange = params.hasChanged;
        if (paramChange === true) {
          arr[0] = params.row.username
          arr[1] = 'Group';
          arr[2] = params.props.value;
          ChangeData(arr[0], arr[1], arr[2]);
          return arr;
        }
        return { ...params.props };
      },
    },
    {
      field: 'status', headerName: 'Status', editable: true, type: 'singleSelect',
      valueOptions: ['Active', 'Inactive'], width: 100,
      sortable: false, description: 'This column has a status value of active & inactive',
      preProcessEditCellProps: (params) => {
        let arr = [];
        const paramChange = params.hasChanged;
        if (paramChange === true) {
          arr[0] = params.row.username
          arr[1] = 'status';
          arr[2] = params.props.value;
          ChangeData(arr[0], arr[1], arr[2]);
          return arr;
        }
        return { ...params.props };
      },
    },
    {
      field: 'Role', headerName: 'Role', editable: true, type: 'singleSelect',
      valueOptions: ['User', 'Manager', 'Admin'], width: 100,
      sortable: false, description: 'This column has a status value of User, Manager and Admin',
      preProcessEditCellProps: (params) => {
        let arr = [];
        const paramChange = params.hasChanged;
        if (paramChange === true) {
          arr[0] = params.row.username
          arr[1] = 'Role';
          arr[2] = params.props.value;
          ChangeData(arr[0], arr[1], arr[2]);
          return arr;
        }
        return { ...params.props };
      },
    },
    { field: 'lastseen', headerName: 'Last Activity', type: 'number', width: 180 },
    {
      field: "action",
      headerName: "Action",
      width: 70,

      // Important: passing id from customers state so I can delete or edit each user
      renderCell: (id) => (
        <>
          <Button
            style={{
              margin: '1rem',
              color: '#f3795e'
            }}
            onClick={() => handleDelete(id)}
            color="primary"
            type="submit"
          >
            <DeleteOutlineIcon />
          </Button>
        </>
      )
    },
    {
      field: "manage_workspace",
      headerName: "Manage",
      width: 100,

      //For letting the admin manage the users workspace
      renderCell: (id) => (
        <>
          <Button
            style={{
              color: '#f3795e'
            }}
            title="Manage workspace/EC2"
            onClick={() => {
              // navigate("/workspace/" + id.row.username + "/manage", { state: { userEmail: id.row.username } })
              navigate("/accessmanagement/" + id.row.username + "/manage", { state: { userEmail: id.row.username } })
              }
            }
          >
            <MdOutlineSettings />
          </Button>
        </>
      )
    }

  ];

  useEffect(() => {
    dispatch(Actions.getAccessManagemntData(newDate));
  }, [])

  //Search Bar operation :
  const [filtername, setFilterName] = useState("")
  const [showOverlayCard, setShowOverlayCard] = useState(false) //Modal for add user
  let newFilteredItems = workspacesData?.filter((item) => {
    if (filtername?.toLowerCase() === "") {
      return item
    } else if (item.username?.toLowerCase().includes(filtername?.toLowerCase())) {
      return item
    } else {
      return ""
    }
  })

  return (
    !spinner &&
    <div className='all--users--toplevel--wrapper'>
      {isDataLoading ? (
        <div className="kube--util--center--div">
          <div className='dot-flashing'></div>
        </div>
      ) : (
        <div className='all--users--content'>

          <div className='all--users--top--section'>
            <button className="kube--action--button" type="addUser" onClick={() => setShowOverlayCard(true)} >Add User</button>

            <div className="kube--admintable--filter">
              <input type="text" placeholder="Search" name="search" onChange={(e) => {
                setFilterName(e.target.value)
              }} />
            </div>

            <div>
              <IconButton style={{ color: "#F3795E" }} onClick={handleRefresh}>
                <RefreshIcon />
              </IconButton>
            </div>

            <OverlayCard isVisible={showOverlayCard} setShowOverlayCard={setShowOverlayCard} />
          </div>

          <div className='all--users--datagrid kube--util--center--div'>
            <DataGrid
              rows={newFilteredItems}
              columns={columns}
              editMode="row"
              pageSize={8}
              rowsPerPageOptions={[8]}
              experimentalFeatures={{ newEditingApi: true }}
            />
          </div>
        </div>
      )
      }
    </div>
  );

  // return (
  //   !spinner &&
  //   <>
  //     {isDataLoading ? (
  //       <div className="kube--util--center--div">
  //         <div className='dot-flashing'></div>
  //       </div>
  //     ) : (
  //       <div className='kube--all--userdetails--wrapper'>

  //         <div style={{ textAlign: 'center', cursor: 'pointer', width: '100%', display: 'flex', flexDirection: 'row' }}>
  //           <input type="text" placeholder="Search..." name="search" onChange={(e) => {
  //             setFilterName(e.target.value)
  //           }} style={{ height: '35px', width: '50rem', marginLeft: '12rem' }} />

  //           <div style={{ display: 'flex' }}>
  //             <button type="addUser" onClick={() => setShowOverlayCard(true)} style={{ marginLeft: '5rem', height: '2.3rem', width: '5rem', backgroundColor: '#4CAF50', border: 'none', cursor: 'pointer', borderRadius: '4px', color: '#fff' }}>Add User</button>
  //             <div className='refresh_option'>
  //               <RefreshIcon onClick={handleRefresh} />
  //             </div>
  //           </div>

  //           <OverlayCard isVisible={showOverlayCard} setShowOverlayCard={setShowOverlayCard} />

  //         </div>

  //         <div style={{ height: 550, width: '98%', marginLeft: '4rem', marginTop: '3rem', border: '2px solid black', borderRadius: '8px' }}>
  //           <DataGrid
  //             rows={newFilteredItems}
  //             columns={columns}
  //             editMode="row"
  //             pageSize={8}
  //             rowsPerPageOptions={[8]}
  //             experimentalFeatures={{ newEditingApi: true }}
  //           />
  //         </div>

  //       </div>
  //     )
  //     }
  //   </>
  // );
}
