import React, { useState, useMemo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as Actions from "../dashboard/store/actions"
import reducer from "../dashboard/store/reducers"
import withReducer from "../dashboard/store/withReducer"
import "../../App.css"
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table"
import { Filter } from "./Filter"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import male from "../../assets/male.png"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Popup from "./Popup"
import moment from "moment"
import statusgreen from "../../assets/kube-app/statusgreen.svg"
import statusgrey from "../../assets/kube-app/statusgrey.svg"
import statusred from "../../assets/kube-app/statusred.svg"


toast.configure()

const Icon = styled.img`
  width: 30px;
  padding: 5px;
`
const Avatar = styled.img`
  width: 30px;
`

export const COLUMNS = [
  {
    Header: "Profile",
    accessor: "profile",
  },
  {
    Header: "Email",
    accessor: "name",
  },
  {
    Header: "Last seen",
    accessor: "lastseen",
  },
  {
    Header: "Time Worked",
    accessor: "workedhours",
  },
  {
    Header: "User Status",
    accessor: "userstatus",
  },
]

const Usertable = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false) //Modal for graph
  const [testData, setTestData] = useState([]) //Data to be modified
  const timeworked = useSelector((usertable) => usertable.users.timeworked) //Data
  const isUserTimeWorkedDataLoading = useSelector((usersReducer) => usersReducer.users.isUserTimeWorkedDataLoading)
  const logInStatus = useSelector((state) => state.loginUser.LoggedInUser);
  const email = logInStatus.attributes.email

  let date = new Date()
  const dateFormat = "YYYY-MM-DD"
  const newDate = moment(new Date(date)).format(dateFormat)
  let time = date.toLocaleTimeString("en-GB")

  const isOnline = (userstatus) => {
    if (userstatus === "Online") return 2
    if (userstatus === "Away") return 1
    return 0
  }

  //custom sort function
  const compare = (a, b) => {
    if (isOnline(a.userstatus) > isOnline(b.userstatus)) return 1
    if (isOnline(a.userstatus) < isOnline(b.userstatus)) return -1
    if (isOnline(a.userstatus) === isOnline(b.userstatus)) return 0
  }

  const sortedUsersArr = timeworked?.sort(compare).reverse()

  useEffect(() => {
    if (sortedUsersArr.length > 0) {
      setTestData(sortedUsersArr)
      setLoading(false)
    }
  }, [sortedUsersArr])

  const columns = useMemo(() => COLUMNS, [])
  const data = useMemo(() => testData, [testData])

  // To navigate to grid view with help of email
  function handleUserselect(cell) {
    var email = cell.value
    navigate("/LandingIcon/" + email)
  }

  var getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt))
    }
    return arr
  }

  let rangeDates = getDaysArray(
    new Date(props.startDate),
    new Date(props.endDate)
  )
  rangeDates = rangeDates.map((v) => {
    var date = new Date(v),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2)
    return [date.getFullYear(), mnth, day].join("-")
  })

  //Function for graph modal and dispatch function for it
  function handleTotalTime(cell) {
    var email = cell.row.original.name
    setOpenModal(true)
    dispatch(Actions.getKubeUsersGraphs(email, rangeDates))
  }

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setGlobalFilter,
    prepareRow,
  } = tableInstance

  // Dispatch action for Time worked
  useEffect(() => {
    if (props.startDate && props.endDate && newDate && time) {
      dispatch(
        Actions.getKubeUserTimeWorked(
          email,
          props.startDate,
          props.endDate,
          newDate,
          time,
        )
      )
    }
  }, [props.startDate, props.endDate])


  const { pageIndex, globalFilter } = state
  return (
    <>
      {isUserTimeWorkedDataLoading ? (
        <div className="kube__userselect-loading"
          style={{
            width: "70vw",
            height: "85vh",
            display: "flex",
            justifyContent: "center",
            zIndex: 100,
            margin: "16rem auto auto"
          }}
        >
          <div className='dot-flashing'></div>
        </div>
      ) : (
        <>
          {openModal && <Popup open={openModal} setOpenModal={setOpenModal} />}
          <Filter filter={globalFilter} setFilter={setGlobalFilter} />
            <table className='table' {...getTableProps()}>
              <thead className='thead'>
              {headerGroups.map((headerGroup) => (
                <tr className='trHead' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className='th' {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "  ▲"
                            : "  ▼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
              <tbody className='tbody' {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <tr className='trBody' {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.Header === "Profile") {
                        let currStatus = cell.row.original.userstatus
                        return (
                          <td className='td' {...cell.getCellProps()}>
                            {
                              <>
                                <Avatar src={male} alt="image" />{" "}
                                <Icon
                                  src={
                                    currStatus === "Online"
                                      ? statusgreen
                                      : currStatus === "Away"
                                        ? statusgrey
                                        : statusred
                                  }
                                />
                              </>
                            }
                          </td>
                        )
                      } else if (cell.column.Header === "Email") {
                        return (
                          <td
                            onClick={() => handleUserselect(cell)}
                            style={{ backgroundColor: "#4caf50", color: "white" }}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      } else if (cell.column.Header === "Time Worked") {
                        if (cell.row.original.workedhours === null) {
                          return (
                            <td
                              style={{ backgroundColor: "#4caf50", color: "white" }}
                              {...cell.getCellProps()}
                            >
                              {
                                <div className="loaderDots">
                                  <div className="dot-flashing"></div>
                                </div>
                              }
                            </td>
                          )
                        } else {
                          return (
                            <td
                              onClick={() => handleTotalTime(cell)}
                              style={{ backgroundColor: "#4caf50", color: "white" }}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          )
                        }
                      } else {
                        return (
                          <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        )
                      }
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>)}

      <div className="kube__userselect-pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          First
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Prev
        </button>
        <span className="kube__userselect-pagination-details">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <input
              type="number"
              min={0}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(pageNumber)
              }}
              style={{ width: "50px" }}
            />
          </span>
        </span>
        <button className="kube--pagination--button"  onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </button>
        <button className="kube--pagination--button"  onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          Last
        </button>
      </div>
    </>
  )
}
export default withReducer("usertable", reducer)(Usertable)
