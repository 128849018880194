import React, { Component } from "react";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux'
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height:"52%",
  bgcolor: "#ffffff",
};
 function Popup(props){
  const usersData = useSelector((userGraph)=>userGraph.users.userGraph);
  let hr = [];
  let newDataValue = Object.values(usersData);
  if(newDataValue.length>0){
    for(let i=0; i<7; i++) {
      let x = typeof(newDataValue[i]);
      let y,z=0;
      if(x === 'string'){
          y = (newDataValue[i]).substring(3);
          z = parseInt(y);
          z = z/60;
      }
      hr[i] = ((parseInt(newDataValue[i]) + z).toFixed(2));
     }
   }
   const  options= {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: Object.keys(usersData),
          tickPlacement: 'on',
        },
        yaxis:{
            tickAmount:8,
        },
        
      }
     const series= [
        {
          name: "Working Time",
          data: hr
        }
      ]

return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-body" sx={style}>
        <div className="app">
        <div className="row">
          <div className="mixed-chart" style={{padding:"5%"}}>
            <Chart
              options={options}
              series={series}
              type="bar"
              width="500"
            />
          </div>
        </div>
      </div>
        </Box>
      </Modal>
    </div>
  );
}


export default Popup;
