import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
const columns =[
    {
              field: 'Tasks',
              headerName: 'Ticket',
              type: 'String',
              description: 'Work units tickets description',
              flex: 1,
              minWidth: 150,
              renderCell: ((params) => {
                        return (<Link href={params.value.URL} target = '_blank' underline="always" >{params.value.Ticketdescription}</Link>);
                      })
            },
    {
              field: 'Work_units',
              headerName: 'Work units',
              type: 'String',
              description: 'Work units in hrs',
              flex:0.3
            }
]
const rows = []
export default function DataTable(props) {
  const rows = props.Tasks.map((item,index)=>{
    return({id:index,Tasks:{"Ticketdescription":item.KEY+ ' | ' +item.TASK,"URL":item.URL},Work_units:item.WORKUNITS})
  })
  return (
    <div style={{ height: '70vh'}}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[8]}
      />
    </div>
  );
}