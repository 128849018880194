import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from '../dashboard/store/actions'
import reducer from '../dashboard/store/reducers'
import withReducer from '../dashboard/store/withReducer'
import styled from 'styled-components'
import Navbar from '../navbar/Navbar'
import User from './userWorkspace/User'
import Admin from './adminWorkspace/Admin'
import SideNavbar from './SideNavbar'


const Wrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: #FFF8F0;
`

const Workspace = (props) => {
  var CryptoJS = require("crypto-js");
  let admin;
  let key = localStorage.getItem('role')
  let bytes = CryptoJS.AES.decrypt(key, process.env.REACT_APP_SECRET)
  let v1 = bytes.toString(CryptoJS.enc.Utf8);
  let v2 = (JSON.parse(v1));
  if (v2 === 'Admin' || v2 === 'Superadmin') {
    admin = true;
  }
  else {
    admin = false;
  }


  return (
    <div className="accessmanagement--top--level--wrapper">
      {/* <Wrapper> */}
      {/* <Navbar admin={admin}></Navbar> */}
      {/* <Navbar /> */}
      {admin ?
        <>
          <SideNavbar />
        </>
        : <User />}
      {/* </Wrapper> */}
    </div>
  )
}

export default withReducer(
  "workspace",
  reducer
)(Workspace)