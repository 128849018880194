import * as Actions from '../actions'

const initialState = {
    data: {},
    appData: {},
    doughnut: {},
    total_time: [],
    userAppdataLoading : false,
    userTotalTimeDataLoading : false,
}

const statisticsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_KUBE_STATISTICS:
            {
                return {
                    ...state,
                    data: action.payload
                }
            }
        case Actions.GET_KUBE_APPDATA:
            {
                return {
                    ...state,
                    appData: action.payload
                }
            }
        case Actions.USER_APPDATA_LOADING: 
                {
                    return{
                        ...state,
                        userAppdataLoading: action.payload
                    }
                }   
        case Actions.GET_KUBE_DOUGHNUT:
            {
                return {
                    ...state,
                    doughnut: action.payload
                }
            }
        case Actions.GET_KUBE_USERS_TIME_WIDGET_TOTAL_TIME:
            {
                return {
                    ...state,
                    total_time: action.payload
                }
            }
        case Actions.USER_TOTAL_TIME_DATA_LOADING: 
            {
                return{
                    ...state,
                    userTotalTimeDataLoading: action.payload
                }
            }   
        default:
            {
                return state
            }
    }
}

export default statisticsReducer;