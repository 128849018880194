import { combineReducers } from 'redux'
import screenshots from './screenshots.reducer'
import statistics from './statistics.reducer'
import time from './time.reducer'
import users from './users.reducer'
import workspaces from './workspaces.reducer'
import manageWorkspaces from './manageWorkspaces.reducer'
import screenshotsdata from './screenshotsdata.reducer'
import LoggedInUserReducer from './auth.reducer'
import errorSnackbar from './errorSnackbar.reducer'
import userSessionReducer from './userSession.reducer'
import leavemanagementReducer from './leavemanagement.reducer'
import complianceReducer from './compliance.reducer'
import invalidatedreducer from './invalidatedslotsreducer'


const reducer = asyncReducers => 
    combineReducers ({
        screenshots,
        statistics,
        time,
        users,
        workspaces,
        invalidslots:invalidatedreducer,
        manageWorkspaces,
        screenshotsdata,
        loginUser:LoggedInUserReducer,
        userSessionReducer,
        leavemanagementReducer,
        complianceReducer,
        errorSnackbar,
        ...asyncReducers
    });

export default reducer