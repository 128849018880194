import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as Actions from '../../dashboard/store/actions'
import styled from 'styled-components'
import CryptoJS from 'crypto-js'
import { Auth } from "aws-amplify";
import Bar from '../Bar'
import { Button } from '../Bar'
import { MdRefresh, MdCached, MdOutlineDelete } from 'react-icons/md'
import "../../../App.css"
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table"
import { toggleSnackbar } from '../../dashboard/store/actions/kube.actions'
import { CircularProgress } from '@material-ui/core'
import ConfirmationModal from './ConfirmationModal.js'
import DeleteWorkspaceModal from './DeleteWorkspaceModal.js'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const COLUMNS = [
  {
    Header: "Workspace Id",
    accessor: "expand-view"
  },
  {
    Header: "Username",
    accessor: (originalRow) => {
      return originalRow.workspace.Workspaces.length > 0 && originalRow.workspace.Workspaces[0].UserName !== undefined && originalRow.workspace.Workspaces[0].UserName
    },
  },
  {
    Header: "Compute",
    accessor: (originalRow) => {
      return originalRow.workspace.Workspaces.length > 0 && originalRow.workspace.Workspaces[0].WorkspaceProperties.ComputeTypeName !== undefined && originalRow.workspace.Workspaces[0].WorkspaceProperties.ComputeTypeName
    },
  },
  {
    Header: "Root Volume",
    accessor: (originalRow) => {
      return originalRow.workspace.Workspaces.length > 0 && originalRow.workspace.Workspaces[0].WorkspaceProperties.RootVolumeSizeGib !== undefined && originalRow.workspace.Workspaces[0].WorkspaceProperties.RootVolumeSizeGib
    },
  },
  {
    Header: "User Volume",
    accessor: (originalRow) => {
      return originalRow.workspace.Workspaces.length > 0 && originalRow.workspace.Workspaces[0].WorkspaceProperties.UserVolumeSizeGib !== undefined && originalRow.workspace.Workspaces[0].WorkspaceProperties.UserVolumeSizeGib
    },
  },
  {
    Header: "Running Mode",
    accessor: (originalRow) => {
      return originalRow.workspace.Workspaces.length > 0 && originalRow.workspace.Workspaces[0].WorkspaceProperties.RunningMode !== undefined && originalRow.workspace.Workspaces[0].WorkspaceProperties.RunningMode
    },
  },
  {
    Header: "Status",
    accessor: (originalRow) => {
      return originalRow.workspace.Workspaces.length > 0 && originalRow.workspace.Workspaces[0].State !== undefined && originalRow.workspace.Workspaces[0].State
    },
  },
  {
    Header: "Start/Stop",
    accessor: "toggle_button",
  }
]

const ADMIN_COLUMNS = [
  ...COLUMNS,
  {
    Header: "Action",
    accessor: "delete_workspace",
  }
]

const Wrapper = styled.div` 
  position: absolute;
  top: 10rem;
  width: 100vw;
  height: calc(100vh - 8rem - 2px);
  /* margin-top: 3rem; */
`

const SmallButtonSection = styled.div`
  margin-right: 2.4rem;
  display: flex;
  justify-content: right;
`

const SmallButton = styled(Button)`
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  margin: 0 2rem;
  font-size: 2rem;
  &:hover {
    background: #E24623;
    box-shadow: inset 0px 8px 20px rgba(0, 0, 0, 0.25);
  }
`

const EmptyTextContainer = styled.div`
    margin-top: 3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
`

const User = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [selectedBar, setSelectedBar] = useState()
  const [useWorkspaceData, setLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false)


  const user_workspace_data = useSelector((state) => state.workspaces.userWorkspaceData)
  const is_user_workspace_data_loading = useSelector((state) => state.workspaces.userWorkspaceDataLoading)
  const [userWorkspaceData, setUserWorkspaceData] = useState(user_workspace_data)

  const logInStatus = useSelector((state) => state.loginUser.LoggedInUser)
  const userEmail = location?.state?.userEmail ? (location.state.userEmail) : (logInStatus?.attributes.email)

  const [admin, setAdmin] = useState(false)

  useEffect(() => {
    const authenticate = async () => {
      try {
        const userDetails = await Auth.currentAuthenticatedUser();
        let key = localStorage.getItem('role')
        let bytes = CryptoJS.AES.decrypt(key, process.env.REACT_APP_SECRET)
        let v1 = bytes.toString(CryptoJS.enc.Utf8);
        let v2 = (JSON.parse(v1));
        if (v2 === 'Admin' || v2 === 'Superadmin') {
          setAdmin(true);
        }
      } catch (err) {
        console.log(err);
      }
    };
    authenticate();
  }, []);

  /* Dispatch action for fetching the userworkspace data */
  useEffect(() => {
    dispatch(Actions.getUserWorkspaceDetails(userEmail))
  }, [userEmail])

  const columns = useMemo(() => admin ? (ADMIN_COLUMNS) : (COLUMNS), [admin])
  const data = useMemo(() => user_workspace_data, [user_workspace_data])

  function handleWorkspaceToggle(cell) {

    const currentWorkspaceID = cell.row.original.workspace.Workspaces[0].WorkspaceId
    const currentWorkspaceStatus = cell.row.values['Status']

    if (currentWorkspaceStatus === 'STOPPED') {
      dispatch(toggleSnackbar({
        status: true,
        message: `Starting the workspace ${currentWorkspaceID}`,
        severity: "success"
      }))
      dispatch(Actions.toggleUserWorkspaceStatus(currentWorkspaceID, currentWorkspaceStatus, userEmail))
    }
    else if (currentWorkspaceStatus === 'AVAILABLE') {
      dispatch(Actions.toggleUserWorkspaceStatus(currentWorkspaceID, currentWorkspaceStatus, userEmail))
      dispatch(toggleSnackbar({
        status: true,
        message: `Stopping the workspace ${currentWorkspaceID}`,
        severity: "warning"
      }))
    }
    else if (currentWorkspaceStatus === 'STARTING') {
      dispatch(toggleSnackbar({
        status: true,
        message: "Workspace is starting, Please wait",
        severity: "error"
      }))
    }
    else if (currentWorkspaceStatus === 'STOPPING') {
      dispatch(toggleSnackbar({
        status: true,
        message: "Workspace is stopping, Please wait",
        severity: "error"
      }))
    }
  }

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance

  return (
    <>
      {is_user_workspace_data_loading ? (
        <div className="kube--util--center--div">
          <div className='dot-flashing'></div>
        </div>
      ) : (
        user_workspace_data.length > 0 ? (
          <>
            <Wrapper>
              <SmallButtonSection>
                <SmallButton onClick={() => {
                  dispatch(Actions.getUserWorkspaceDetails(userEmail))
                  dispatch(toggleSnackbar({
                    status: true,
                    message: `Refreshing Status...`,
                    severity: "info"
                  }))
                }
                } ><MdCached /></SmallButton></SmallButtonSection>
              {
                props.data && props.data.body && props.data.body.Workspaces &&
                props.data.body.Workspaces.map((workspace, i) => (
                  <Bar
                    key={workspace.WorkspaceId}
                    {...workspace}
                    barIndex={i}
                    isActive={workspace.WorkspaceId === selectedBar}
                    setSelectedBar={setSelectedBar}
                    data={props.data.body.Workspaces[0]}
                  />
                ))
              }
                <table className='table' {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th className='th' {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, idx) => {
                    prepareRow(row)
                    return (
                      <>
                        <tr {...row.getRowProps()} key={idx}>
                          {row.cells.map((cell) => {
                            if (cell.column.Header === "Start/Stop") {
                              return (
                                <>
                                  <td
                                    {...cell.getCellProps()}
                                  >
                                    <ConfirmationModal handleWorkspaceToggle={handleWorkspaceToggle} cell={cell} />
                                  </td>
                                </>
                              )
                            }
                            else if (cell.column.Header === "Workspace Id") {
                              return (
                                <td
                                  style={{ width: "35%" }}
                                  {...cell.getCellProps()}
                                >
                                  <Accordion style={{ background: '#ECF0F3'}}>
                                    <AccordionSummary
                                      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <div style={{ justifyContent: "center", textAlign: "center" }} >{row.original.workspace.Workspaces[0].WorkspaceId}</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div className="detail-container" style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start" }}>
                                        <span><strong>Username : </strong> <a>{row.original.workspace.Workspaces[0].UserName}</a></span>
                                        <span><strong>Directory ID : </strong> <a>{row.original.workspace.Workspaces[0].DirectoryId}</a></span>
                                        <span><strong>Registration Code : </strong><a>{row.original['registration code']}</a></span>
                                        <span><strong>Connection State :</strong><a>{row.original['connection state']}</a></span>
                                        <span><strong>WorkSpace IP : </strong> : <a>{row.original.workspace.Workspaces[0].IpAddress}</a></span>
                                        <span><strong>AutoStop Time : </strong><a>{row.original.workspace.Workspaces[0].WorkspaceProperties.RunningModeAutoStopTimeoutInMinutes} minutes</a></span>
                                        <span><strong>Computer Name : </strong><a>{row.original.workspace.Workspaces[0].ComputerName} </a></span>
                                        <span><strong>Clients Link : </strong><a href="https://clients.amazonworkspaces.com/" target="_blank">{row.original['clients link']}</a></span>
                                        <div style={{
                                          display: "flex",
                                          alignItems: "center"
                                        }}><strong style={{
                                        }}>Launch Bundle: </strong><p>{row.original['Description']}</p></div>
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                </td>
                              )
                            }
                            else if (cell.column.Header === "Action") {
                              return (
                                <td>
                                  <DeleteWorkspaceModal cell={cell} />
                                </td>
                              )
                            }
                            else {
                              return (
                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                              )
                            }
                          })}
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </Wrapper>
          </>
        ) : (
          <div>
            <EmptyTextContainer>
              <h2>No workspaces assigned</h2>
            </EmptyTextContainer>
          </div>
        )
      )
      }
    </>
  )
}

export default User
