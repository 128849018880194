import { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"
import styled from 'styled-components'
import axios from '../../../api/axios'
import * as Actions from '../../dashboard/store/actions'
import * as Endpoint from '../../../Endpoint'
// import "../../../App.css"
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
} from "react-table"
import { CircularProgress } from '@material-ui/core'
import { toggleSnackbar } from '../../dashboard/store/actions'
import ApplyLeaveDialogBox from '../ApplyLeaveDialogBox'
import { Filter } from '../admin/Filter'

export const COLUMNS = [
    {
        Header: "Start Date",
        accessor: "start_date"
    },
    {
        Header: "End Date",
        accessor: "end_date"
    },
    {
        Header: "Notes",
        accessor: "reason"
    },
    {
        Header: "Status",
        accessor: "status"
    },
]

const UserLeaveManagement = () => {
    const { email: userEmail } = useParams()
    const dispatch = useDispatch()
    const [userData, setUserData] = useState([])
    const userLeavesData = useSelector((leavemanagement) => leavemanagement.leavemanagementReducer.userActiveLeaveApplicationsData)
    const currentYear = new Date().getFullYear().toString()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(Actions.getUserActiveLeaveApplications(userEmail, currentYear))
    }, [])

    useEffect(() => {
        if (Object.keys(userLeavesData).length > 0) {
            setUserData(userLeavesData)
        }
    }, [Object.keys(userLeavesData).length])


    const handleApplyLeave = async (dates, status, reason, userId, year) => {

        const postData = {
            dates: dates,
            status: status,
            reason: reason,
            user_id: userId,
            year: year,
        }
        const headers = {
            "X-API-Key": process.env.REACT_APP_API_KEY
          };
          

        const res = await axios.post(Endpoint.API_APPLY_AND_SANCTION_LEAVE, postData,{
            headers: headers
          })

        dispatch(toggleSnackbar({
            status: true,
            message: "Applying...",
            severity: "success"
        }))

        dispatch(Actions.getUserActiveLeaveApplications(userEmail, currentYear))
    }


    const columns = useMemo(() => COLUMNS, [COLUMNS])
    const data = useMemo(() => userData, [userData])

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        prepareRow,
    } = tableInstance

    const { pageIndex, globalFilter } = state
    return (
        <div className="admin--leavemanagement--wrapper">
            {loading ? (
                <div className="kube--util--center--div">
                    <div className='dot-flashing'>
                    </div>
                </div>
            ) : (
                <div className="admin--leavemanagement--content">

                    <div className="admin--leavemanagement--top--section">
                        <div className="apply-leave-dialog">
                            <ApplyLeaveDialogBox handleApplyLeave={handleApplyLeave} />
                        </div>
                    </div>

                    <div className="admin--leavemanagement--hero--section">
                            <table className='table' {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th className='th' {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render("Header")}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, idx) => {
                                        prepareRow(row)
                                        return (
                                            <>
                                                <tr {...row.getRowProps()} key={idx}>
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                        )
                                                    })}
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                    </div>

                    <div className="admin--leavemanagement--bottom--section">
                        <div className="kube__userselect-pagination">
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                Prev
                            </button>
                            <span className="kube__userselect-pagination-details">
                                <span>
                                    Page{" "}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{" "}
                                </span>
                            </span>
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                Next
                            </button>
                        </div>
                    </div>

                </div>
            )}
        </div>
    )

}

export default UserLeaveManagement