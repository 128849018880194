import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom"
import CryptoJS from 'crypto-js'
import { Auth } from "aws-amplify";
import axios from '../../api/axios'
import Button from '@mui/material/Button'
import * as Actions from '../../components/dashboard/store/actions'
import * as Endpoint from '../../Endpoint'
// import "../../../App.css"
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table"
import AddHolidayDialogBox from './admin/AddHolidayDialogBox'
import { toggleSnackbar } from '../../components/dashboard/store/actions'
import { Filter } from './admin/Filter'

export const COLUMNS = [
  {
    Header: "Date",
    accessor: "date"
  },
  {
    Header: "Occasion",
    accessor: "Occasion"
  },
  {
    Header: "Country",
    accessor: "country"
  },
]

const ADMIN_COLUMNS = [
  ...COLUMNS,
  {
    Header: "Remove",
    accessor: "remove_holiday",
  }
]


const HolidayTable = () => {
  const { email } = useParams()
  const dispatch = useDispatch()
  const [holidayDates, setHolidayDates] = useState([])
  const holidaysData = useSelector((leavemanagement) => leavemanagement.leavemanagementReducer.publicHolidaysData)
  const [loading, setLoading] = useState(true)
  const [admin, setAdmin] = useState(false)

  useEffect(() => {
    const authenticate = async () => {
      try {
        const userDetails = await Auth.currentAuthenticatedUser()
        let key = localStorage.getItem('role')
        let bytes = CryptoJS.AES.decrypt(key, process.env.REACT_APP_SECRET)
        let v1 = bytes.toString(CryptoJS.enc.Utf8);
        let v2 = (JSON.parse(v1))
        if (v2 === 'Admin' || v2 === 'Superadmin') {
          setAdmin(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
    authenticate()
  }, [])

  useEffect(() => {
    const getHolidayDates = async () => {
      try {
        const headers = {
          "X-API-Key": process.env.REACT_APP_API_KEY
        };
        const res = await axios.get(
          Endpoint.API_GET_USER_ROLE,
          {
            params: {
              user_id: email,
            },
            headers:headers
          }
        )
        window.country = res.data.Country
        let currentYear = new Date().getFullYear().toString()
        currentYear = currentYear.toString()
        dispatch(Actions.getPublicHolidays(currentYear, window.country))

        if (Object.keys(holidaysData).length > 0) {
          setHolidayDates(holidaysData)
          setLoading(false)
        }
      } catch (err) {
        console.log(err)
      }
    }
    getHolidayDates()
  }, [])

  useEffect(() => {
    if (Object.keys(holidaysData).length > 0) {
      setHolidayDates(holidaysData)
      setLoading(false)
    }
  }, [Object.keys(holidaysData).length])

  const handleDeleteHoliday = async (cell) => {
    if (!admin) {
      return
    }

    try {
      const date = cell.row.original.date
      let currentYear = new Date().getFullYear()
      currentYear = currentYear.toString()

      const postData = {
        status: "Del",
        country: window.country,
        year: currentYear,
        date: date
      };
      const headers = {
        "X-API-Key": process.env.REACT_APP_API_KEY
      };
      

      const res = await axios.post(Endpoint.API_PUBLIC_HOLIDAYS_ADD_DELETE, postData,{
        headers: headers
      })
      if (res.data === "succesfully deleted") {
        dispatch(toggleSnackbar({
          status: true,
          message: "Deleting...",
          severity: "success"
        }))
        dispatch(Actions.getPublicHolidays(currentYear, window.country))
      }
    } catch (err) {
      dispatch(toggleSnackbar({
        status: true,
        message: "Something went wrong...Try again later",
        severity: "error",
      }))
    }
  }

  const handleAddHoliday = async (dateAndOccasion) => {
    if (!admin) {
      return
    }

    try {
      let currentYear = new Date().getFullYear()
      currentYear = currentYear.toString()

      const postData = {
        status: "Add",
        country: window.country,
        year: currentYear,
        date: dateAndOccasion
      }
      const headers = {
        "X-API-Key": process.env.REACT_APP_API_KEY
      };
      
      const res = await axios.post(Endpoint.API_PUBLIC_HOLIDAYS_ADD_DELETE, postData,{
        headers: headers
      })
      if (res.data === "successfully Added") {
        dispatch(toggleSnackbar({
          status: true,
          message: "Adding...",
          severity: "success"
        }))
        dispatch(Actions.getPublicHolidays(currentYear, window.country))
      }
    } catch (err) {
      dispatch(toggleSnackbar({
        status: true,
        message: "Something went wrong...Try again later",
        severity: "error",
      }))
    }
  }

  const columns = useMemo(() => admin ? (ADMIN_COLUMNS) : (COLUMNS))
  const data = useMemo(() => holidayDates, [holidayDates])

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 8 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    prepareRow,
  } = tableInstance

  const { pageIndex, globalFilter } = state

  return (
    <div className="admin--leavemanagement--wrapper">
      {loading ? (
        <div className="kube--util--center--div">
          <div className='dot-flashing'>
          </div>
        </div>
      ) : (
        <div className="admin--leavemanagement--content">

          <div className="admin--leavemanagement--top--section">
            <Filter filter={globalFilter} setFilter={setGlobalFilter} />
            {admin && <div className="apply-leave-dialog">
              <AddHolidayDialogBox handleAddHoliday={handleAddHoliday} />
            </div>}
          </div>

          <div className="admin--leavemanagement--hero--section">
            <table className='table' {...getTableProps()}>
              <thead className='thead'>
                {headerGroups.map((headerGroup) => (
                  <tr className='trHead' {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th className='th' {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='tbody' {...getTableBodyProps()}>
                {page.map((row, idx) => {
                  prepareRow(row)
                  return (
                    <>
                      <tr className='trBody' {...row.getRowProps()} key={idx}>
                        {row.cells.map((cell) => {
                          if (cell.column.Header === "Remove") {
                            return (
                              <td className='td'>
                                <Button color="error" onClick={() => { handleDeleteHoliday(cell) }}>
                                  Remove
                                </Button>
                              </td>
                            )
                          } else {
                            return (
                              <td className='td' {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            )
                          }
                        })}
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className="admin--leavemanagement--bottom--section">
            <div className="kube__userselect-pagination">
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                Prev
              </button>
              <span className="kube__userselect-pagination-details">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </button>
            </div>
          </div>

        </div>
      )}
    </div>
  )

}

export default HolidayTable