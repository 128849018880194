import axios from "../../../../api/axios"
import * as Endpoint from "../../../../Endpoint"
import { Snackbar } from "@material-ui/core"
export const GET_KUBE_SCREENSHOTS = "GET_KUBE_SCREENSHOTS"
export const GET_KUBE_STATISTICS = "GET_KUBE_STATISTICS"
export const GET_KUBE_TIME_DETAIL = "GET_KUBE_TIME_DETAIL"
export const GET_KUBE_USERS = "GET_KUBE_USERS"
export const GET_KUBE_WORKSPACES = "GET_KUBE_WORKSPACES"
export const MANAGE_KUBE_WORKSPACES = "MANAGE_WORKSPACES"
export const GET_KUBE_SCREENSHOTS_DATA = "GET_KUBE_SCREENSHOTS_DATA"
export const GET_KUBE_USERSGRAPHS = "GET_KUBE_USERSGRAPHS"
export const GET_KUBE_USERS_TIMEWORKED = "GET_KUBE_USERS_TIMEWORKED"
export const IS_KUBE_USERS_TIMEWORKED_DATA_LOADING = "IS_KUBE_USERS_TIMEWORKED_DATA_LOADING"
export const GET_KUBE_DOUGHNUT = "GET_KUBE_DOUGHNUT"
export const GET_KUBE_APPDATA = "GET_KUBE_APPDATA"
export const GET_KUBE_USERS_TIME_WIDGET_TOTAL_TIME = "GET_KUBE_USERS_TIME_WIDGET_TOTAL_TIME"
export const GET_ACCESS_MANAGEMENT_DATA = 'GET_ACCESS_MANAGEMENT_DATA'
export const IS_ACCESS_MANAGEMENT_DATA_LOADING = 'IS_ACCESS_MANAGEMENT_DATA_LOADING'
export const GET_WORKSPACE_EDIT = 'GET_WORKSPACE_EDIT'
export const GET_ADD_USERS = `GET_ADD_USERS`
export const OPEN_ERRORSNACKBAR = 'OPEN_ERRORSNACKBAR'
export const GET_USER_WORKSPACE_DETAILS = 'GET_USER_WORKSPACE_DETAILS'
export const TOGGLE_USER_WORKSPACE = 'TOGGLE_USER_WORKSPACE'
export const GET_DELETE_USERS = `GET_DELETE_USERS`
export const GET_ROLE_USERS = `GET_ROLE_USERS`
export const POST_ADD_NEW_WORKSPACE = `POST_ADD_NEW_WORKSPACE`
export const POST_DELETE_WORKSPACE = `POST_DELETE_WORKSPACE`
export const IS_USER_WORKSPACE_DATA_LOADING = 'IS_USER_WORKSPACE_DATA_LOADING'
export const GET_USER_SESSION_DETAILS = 'GET_USER_SESSION_DETAILS'
export const USER_GRAPH_DATA_LOADING = 'USER_GRAPH_DATA_LOADING'
export const USER_TOTAL_TIME_DATA_LOADING = 'USER_TOTAL_TIME_DATA_LOADING'
export const USER_APPDATA_LOADING = 'USER_APPDATA_LOADING'
export const GET_PUBLIC_HOLIDAYS = 'GET_PUBLIC_HOLIDAYS'
export const IS_ADMIN_ACTIVE_LEAVE_APPLICATIONS_DATA_LOADING = 'IS_ADMIN_ACTIVE_LEAVE_APPLICATIONS_DATA_LOADING'
export const GET_ADMIN_ACTIVE_LEAVE_APPLICATIONS = 'GET_ADMIN_ACTIVE_LEAVE_APPLICATIONS'
export const GET_USER_ACTIVE_LEAVE_APPLICATIONS = 'GET_USER_ACTIVE_LEAVE_APPLICATIONS'
export const GET_WEEKLY_COMPLIANCE_DATA = 'GET_WEEKLY_COMPLIANCE_DATA'
export const IS_WEEKLY_COMPLIANCE_DATA_LOADING = 'IS_WEEKLY_COMPLIANCE_DATA_LOADING'
export const GET_MONTHLY_COMPLIANCE_DATA = 'GET_MONTHLY_COMPLIANCE_DATA'
export const IS_MONTHLY_COMPLIANCE_DATA_LOADING = 'IS_MONTHLY_COMPLIANCE_DATA_LOADING'
export const GET_ADMIN_WEEKLY_COMPLIANCE_DATA = 'GET_ADMIN_WEEKLY_COMPLIANCE_DATA'
export const IS_ADMIN_WEEKLY_COMPLIANCE_DATA_LOADING = 'IS_ADMIN_WEEKLY_COMPLIANCE_DATA_LOADING'
export const GET_ADMIN_MONTHLY_COMPLIANCE_DATA = 'GET_ADMIN_MONTHLY_COMPLIANCE_DATA'
export const IS_ADMIN_MONTHLY_COMPLIANCE_DATA_LOADING = 'IS_ADMIN_MONTHLY_COMPLIANCE_DATA_LOADING'
export const GET_USER_JIRA_COMPLIANCE_DATA = 'GET_USER_JIRA_COMPLIANCE_DATA'
export const IS_USER_JIRA_COMPLIANCE_DATA_LOADING = 'IS_USER_JIRA_COMPLIANCE_DATA_LOADING'
export const GET_USER_WEEKLY_JIRA_COMPLIANCE_DATA = 'GET_USER_WEEKLY_JIRA_COMPLIANCE_DATA'
export const IS_USER_WEEKLY_JIRA_COMPLIANCE_DATA_LOADING = 'IS_USER_WEEKLY_JIRA_COMPLIANCE_DATA_LOADING'
export const GET_ALL_USERS_JIRA_COMPLIANCE_DATA = 'GET_ALL_USERS_JIRA_COMPLIANCE_DATA'
export const IS_ALL_USERS_JIRA_COMPLIANCE_DATA_LOADING = 'IS_ALL_USERS_JIRA_COMPLIANCE_DATA_LOADING'
export const GET_ALL_USERS_WEEKLY_JIRA_COMPLIANCE_DATA = 'GET_ALL_USERS_WEEKLY_JIRA_COMPLIANCE_DATA'
export const IS_ALL_USERS_WEEKLY_JIRA_COMPLIANCE_DATA_LOADING = 'IS_ALL_USERS_WEEKLY_JIRA_COMPLIANCE_DATA_LOADING'
export const GET_INVALIDATED_SLOTS_DETAILS = 'GETSLOTSDATA'
export const GET_INVALIDATED_SLOTS_DETAILS_LOADING = 'GETSLOTSDATA_LOADING'

const headers = {
  "X-API-Key": process.env.REACT_APP_API_KEY
};


export function getKubeWorkspaces() {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        Endpoint.API_GET_KUBE_WORKSPACE,
        { headers:headers }
      )
      dispatch({
        type: GET_KUBE_WORKSPACES,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}

export function getScreenshotsData(selectedDate, email) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        Endpoint.API_SCREENSHOTS_DATA,
        { params: { selectedDate: selectedDate, userEmail: email },headers:headers }
      )
      dispatch({
        type: GET_KUBE_SCREENSHOTS_DATA,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}

export function manageWorkspaces(command) {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        Endpoint.API_MANAGE_KUBE_WORKSPACES,
        null,
        { params: { command: command },headers:headers }
      )
      dispatch({
        type: MANAGE_KUBE_WORKSPACES,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}


export function getKubeUserTimeWorked(userEmail, start_date, end_date, newDate, time) {

  return async (dispatch) => {
    try {
      dispatch({
        type: IS_KUBE_USERS_TIMEWORKED_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_TIME_WORKED, { params: { user_id: userEmail, start_date: start_date, end_date: end_date, date: newDate, time: time, },headers:headers })
      dispatch({
        type: GET_KUBE_USERS_TIMEWORKED,
        payload: response.data,
      })
      dispatch({
        type: IS_KUBE_USERS_TIMEWORKED_DATA_LOADING,
        payload: false,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      dispatch({
        type: IS_KUBE_USERS_TIMEWORKED_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getKubeUsersGraphs(user_id, dates) {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_GRAPH_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_GRAPH_DATA, { params: { user_id: user_id, dates: JSON.stringify(dates) }, headers: headers })
      dispatch({
        type: GET_KUBE_USERSGRAPHS,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
    }
    finally {
      dispatch({
        type: USER_GRAPH_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getKubeDoughnut(user_id, start_date, end_date) {
  return async (dispatch) => {
    try {
      const response = await axios.get(Endpoint.API_DOUGHNUT, {
        params: {
          user_id: user_id,
          start_date: start_date,
          end_date: end_date,
        },
        headers:headers
      })
      dispatch({
        type: GET_KUBE_DOUGHNUT,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}

export function getKubeUserTimeWidgetTotalTime(emailIds, start_date, end_date) {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_TOTAL_TIME_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_TOTAL_TIME, {
        params: {
          user_id: JSON.stringify(emailIds),
          start_date: start_date,
          end_date: end_date,
        },
        headers:headers
      })
      dispatch({
        type: GET_KUBE_USERS_TIME_WIDGET_TOTAL_TIME,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
    }
    finally {
      dispatch({
        type: USER_TOTAL_TIME_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getKubeAppData(user_id, start_date, end_date) {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_APPDATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_APPDATA, {
        params: {
          user_id: user_id,
          start_date: start_date,
          end_date: end_date,
        },
        headers: headers
      })
      dispatch({
        type: GET_KUBE_APPDATA,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
    }
    finally {
      dispatch({
        type: USER_APPDATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getKubeScreenshots(
  selectedDate,
  selectedTime,
  email,
  setloading
) {
  return async (dispatch) => {
    try {
      setloading(true)
      const response = await axios.get(Endpoint.API_KUBE_SCREENSHOTS, {
        params: {
          selectedDate: selectedDate,
          selectedTime: selectedTime,
          userEmail: email,
        },
        headers:headers
      })
      dispatch({
        type: GET_KUBE_SCREENSHOTS,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    } finally {
      setloading(false)
    }
  }
}

//For getting the access management data 
export function getAccessManagemntData(newDate) {
  return async (dispatch) => {
    try {
      dispatch({
        type: IS_ACCESS_MANAGEMENT_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_ADMIN_ACCESS_MANAGEMENT, { params: { date: newDate },headers:headers })
      dispatch({
        type: GET_ACCESS_MANAGEMENT_DATA,
        payload: response.data,
      })
      dispatch({
        type: IS_ACCESS_MANAGEMENT_DATA_LOADING,
        payload: false,
      })
    } catch (ex) {
      dispatch({
        type: IS_ACCESS_MANAGEMENT_DATA_LOADING,
        payload: false,
      })
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
    }
  }
}
export function getWorkspaceEdit(email, parameter, value) {
  return async (dispatch) => {
    try {
      const postData = {
        user_id: email,
        parameter: parameter,
        value: value
      }
      const response = await axios.put(Endpoint.API_WORKSPACE_EDIT, postData,{
        headers: headers
      })
      dispatch({
        type: GET_WORKSPACE_EDIT,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
    }
  }
}

export function getAddUsers(authEmail, name, email, orgId, group, role, timezone, country, managerEmail) {
  return async (dispatch) => {
    try {
      const postData = {
        auth_user_id: authEmail,
        user_details: {
          Name: name,
          Email: email,
          Org_id: orgId,
          Group: group,
          Role: role,
          Timezone: timezone,
          Country: country,
          Managername: managerEmail
        }
      }
      const response = await axios.post(Endpoint.GET_ADD_USERS, postData,{
        headers: headers
      })
      dispatch({
        type: GET_ADD_USERS,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}

export function getDeleteUsers(authEmail, email) {
  return async (dispatch) => {
    try {
      const response = await axios.get(Endpoint.API_DELETE_USER, {
        params: {
          auth_user_id: authEmail,
          user_id: email
        },
        headers:headers
      })
      dispatch({
        type: GET_DELETE_USERS,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}

export function getRoleUsers(orgId) {
  return async (dispatch) => {
    try {
      const response = await axios.get(Endpoint.API_ROLE_USERS, {
        params: {
          org_id: orgId
        },
        headers:headers
      })
      dispatch({
        type: GET_ROLE_USERS,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}


export function getUserWorkspaceDetails(email) {
  return async (dispatch) => {
    try {
      dispatch({
        type: IS_USER_WORKSPACE_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_GET_USER_WORKSPACE_DETAILS, {
        params: {
          user_id: email,
        },
        headers:headers
      })
      dispatch({
        type: GET_USER_WORKSPACE_DETAILS,
        payload: response.data,
      })
      dispatch({
        type: IS_USER_WORKSPACE_DATA_LOADING,
        payload: false,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
      dispatch({
        type: IS_USER_WORKSPACE_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getUserSessionDetails(email, date) {
  return async (dispatch) => {
    try {
      const response = await axios.get(Endpoint.API_USER_SESSION_DETAILS, {
        params: {
          user_id: email,
          date: date
        },
        headers:headers
      })
      dispatch({
        type: GET_USER_SESSION_DETAILS,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}


export function toggleUserWorkspaceStatus(workspaceID, workspaceStatus, userEmail) {
  return async (dispatch) => {
    try {
      const response = await axios.get(Endpoint.API_TOGGLE_USER_WORKSPACE, {
        params: {
          workspace_id: workspaceID,
          state: workspaceStatus
        },
        headers:headers
      })
      dispatch({
        type: TOGGLE_USER_WORKSPACE,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    } finally {
      dispatch(getUserWorkspaceDetails(userEmail))
    }
  }
}

export function addNewWorkspace(DirectoryId, username, bundleId, runningMode, timeout, userencryption, rootencryption, tags_key, tags_value) {
  return async (dispatch) => {
    try {
      const postData = {
        DirectoryId: DirectoryId,
        username: username,
        bundleid: bundleId,
        runningMode: runningMode,
        timeout: timeout,
        userencryption: userencryption,
        rootencryption: rootencryption,
        tags_key: tags_key,
        tags_value: tags_value
      }
      const response = await axios.post(Endpoint.API_ADD_NEW_WORKSPACE, postData,{
        headers: headers
      })
      const reqMessage = response.data
      if (reqMessage === 'A WorkSpace already exists for the specified user.' || reqMessage === 'The specified user could not be found in the directory.') {
        throw new Error(reqMessage)
      }
      dispatch({
        type: POST_ADD_NEW_WORKSPACE,
        payload: response.data,
      })
      dispatch(toggleSnackbar({
        message: `Workspace ${reqMessage}`,
        status: true,
        severity: "success",
      }))
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}

export function deleteWorkspace(DirectoryId, userEmail, workspaceID) {
  return async (dispatch) => {
    try {
      const postData = {
        DirectoryId: DirectoryId,
        username: userEmail,
        WorkspaceId: workspaceID
      }
      const response = await axios.post(Endpoint.API_DELETE_WORKSPACE, postData,{
        headers: headers
      })
      dispatch({
        type: POST_DELETE_WORKSPACE,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}

export function getPublicHolidays(year, country) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        Endpoint.API_PUBLIC_HOLIDAYS,
        { params: { year: year, country: country } ,headers:headers}
      )
      dispatch({
        type: GET_PUBLIC_HOLIDAYS,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}

export function getInvalidatedslotsdetails(email,startdate,enddate) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_INVALIDATED_SLOTS_DETAILS_LOADING,
        payload: true,
      })
      const response = await axios.get(
        Endpoint.API_INVALIDATED_SLOTS_DETAILS,
        { params: { userid: email, startdate: startdate, enddate: enddate } ,headers:headers}
      )
      dispatch({
        type: GET_INVALIDATED_SLOTS_DETAILS,
        payload: response.data,
      })
      dispatch({
        type: GET_INVALIDATED_SLOTS_DETAILS_LOADING,
        payload: false,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}

export function getAdminActiveLeaveApplications(year, loggedInUserEmail) {
  return async (dispatch) => {
    try {
      dispatch({
        type: IS_ADMIN_ACTIVE_LEAVE_APPLICATIONS_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(
        Endpoint.API_ADMIN_ACTIVE_LEAVE_APPLICATIONS,
        { params: { year: year, userid: loggedInUserEmail },headers:headers}
      )
      dispatch({
        type: GET_ADMIN_ACTIVE_LEAVE_APPLICATIONS,
        payload: response.data,
      })
      dispatch({
        type: IS_ADMIN_ACTIVE_LEAVE_APPLICATIONS_DATA_LOADING,
        payload: false,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
      dispatch({
        type: IS_ADMIN_ACTIVE_LEAVE_APPLICATIONS_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getUserActiveLeaveApplications(userEmail, year) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        Endpoint.API_USER_ACTIVE_LEAVE_APPLICATIONS,
        { params: { user_id: userEmail, year: year },headers:headers}
      )
      dispatch({
        type: GET_USER_ACTIVE_LEAVE_APPLICATIONS,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
      // alert("error")
    }
  }
}

export function getWeeklyComplianceData(userId, startDate, endDate, year, country) {
  return async (dispatch) => {
    try {
      dispatch({
        type: IS_WEEKLY_COMPLIANCE_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(
        Endpoint.API_COMPLIANCE_DATA,
        {
          params:
          {
            user_id: userId,
            start_date: startDate,
            end_date: endDate,
            year: year,
            country: country,
          },
          headers:headers
        }
      )
      dispatch({
        type: GET_WEEKLY_COMPLIANCE_DATA,
        payload: response.data,
      })
      dispatch({
        type: IS_WEEKLY_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    } catch (ex) {
      console.log(ex)
      // alert("error")
      dispatch({
        type: IS_WEEKLY_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getMonthlyComplianceData(userId, startDate, endDate, year, country) {
  return async (dispatch) => {
    try {
      dispatch({
        type: IS_MONTHLY_COMPLIANCE_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(
        Endpoint.API_COMPLIANCE_DATA,
        {
          params:
          {
            user_id: userId,
            start_date: startDate,
            end_date: endDate,
            year: year,
            country: country,
          },
          headers:headers
        }
      )
      dispatch({
        type: GET_MONTHLY_COMPLIANCE_DATA,
        payload: response.data,
      })
      dispatch({
        type: IS_MONTHLY_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    } catch (ex) {
      console.log(ex)
      dispatch({
        type: IS_MONTHLY_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getAdminWeeklyComplianceData(start_date, end_date, year, loggedInUserEmail) {
  return async (dispatch) => {
    try {
      dispatch({
        type: IS_ADMIN_WEEKLY_COMPLIANCE_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_ADMIN_COMPLIANCE_DATA, {
        params: {
          start_date: start_date,
          end_date: end_date,
          year: year,
          userid: loggedInUserEmail
        },
        headers:headers
      })
      dispatch({
        type: GET_ADMIN_WEEKLY_COMPLIANCE_DATA,
        payload: response.data,
      })
      dispatch({
        type: IS_ADMIN_WEEKLY_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
    }
    finally {
      dispatch({
        type: IS_ADMIN_WEEKLY_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getAdminMonthlyComplianceData(start_date, end_date, year, loggedInUserEmail) {
  return async (dispatch) => {
    try {
      dispatch({
        type: IS_ADMIN_MONTHLY_COMPLIANCE_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_ADMIN_COMPLIANCE_DATA, {
        params: {
          start_date: start_date,
          end_date: end_date,
          year: year,
          userid: loggedInUserEmail
        },
        headers:headers
      })
      dispatch({
        type: GET_ADMIN_MONTHLY_COMPLIANCE_DATA,
        payload: response.data,
      })
      dispatch({
        type: IS_ADMIN_MONTHLY_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
    }
    finally {
      dispatch({
        type: IS_ADMIN_MONTHLY_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getUserWeeklyJIRAComplianceData(startDate, endDate, userEmail) {
  return async (dispatch) => {
    try {
      dispatch({
        type: IS_USER_WEEKLY_JIRA_COMPLIANCE_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_USER_JIRA_COMPLIANCE_DATA, { params: { startDate: startDate, endDate : endDate, userEmail : userEmail } ,headers:headers})
      dispatch({
        type: GET_USER_WEEKLY_JIRA_COMPLIANCE_DATA,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
    }
    finally {
      dispatch({
        type: IS_USER_WEEKLY_JIRA_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getUserJIRAComplianceData(startDate, endDate, userEmail) {
  return async (dispatch) => {
    try {
      dispatch({
        type: IS_USER_JIRA_COMPLIANCE_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_USER_JIRA_COMPLIANCE_DATA, { params: { startDate: startDate, endDate : endDate, userEmail : userEmail },headers:headers })
      dispatch({
        type: GET_USER_JIRA_COMPLIANCE_DATA,
        payload: response.data,
        // payload: response.data,  change this if this doesnt work
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
    }
    finally {
      dispatch({
        type: IS_USER_JIRA_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    }
  }
}


export function getAllUsersWeeklyJIRAComplianceData(startDate, endDate) {
  return async (dispatch) => {
    try {
      dispatch({
        type: IS_ALL_USERS_WEEKLY_JIRA_COMPLIANCE_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_ALL_USERS_JIRA_COMPLIANCE_DATA, { params: { startDate: startDate, endDate : endDate} ,headers:headers})
      dispatch({
        type: GET_ALL_USERS_WEEKLY_JIRA_COMPLIANCE_DATA,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
    }
    finally {
      dispatch({
        type: IS_ALL_USERS_WEEKLY_JIRA_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function getAllUsersJIRAComplianceData(startDate, endDate) {
  return async (dispatch) => {
    try {
      dispatch({
        type: IS_ALL_USERS_JIRA_COMPLIANCE_DATA_LOADING,
        payload: true,
      })
      const response = await axios.get(Endpoint.API_ALL_USERS_JIRA_COMPLIANCE_DATA, { params: { startDate: startDate, endDate : endDate} ,headers:headers})
      dispatch({
        type: GET_ALL_USERS_JIRA_COMPLIANCE_DATA,
        payload: response.data,
      })
    } catch (ex) {
      dispatch(toggleSnackbar({
        message: ex.message,
        status: true,
        severity: "error",
      }))
    }
    finally {
      dispatch({
        type: IS_ALL_USERS_JIRA_COMPLIANCE_DATA_LOADING,
        payload: false,
      })
    }
  }
}

export function toggleSnackbar(status) {
  return (dispatch) => {
    dispatch({
      type: OPEN_ERRORSNACKBAR,
      payload: status
    })
  }
}
