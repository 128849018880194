import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom"
import styled from 'styled-components'
import "../../../App.css"
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
} from "react-table"
import * as Actions from '../../dashboard/store/actions'
import { CircularProgress } from '@material-ui/core'

export const COLUMNS = [
    {
        Header: "Start",
        accessor: "start"
    },
    {
        Header: "Stop",
        accessor: "stop"
    },
    {
        Header: "Duration",
        accessor: "duration"
    }
]

const Title = styled.div`
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #4B566C;
`

const DurationTimeTable = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [sessionData, setSessionData] = useState([])
    const [totalTime, setTotalTime] = useState('00:00')
    const { email, date } = useParams()

    const userSessionDetails = useSelector((state) => state.userSessionReducer.userSessionDetails)

    useEffect(() => {
        dispatch(Actions.getUserSessionDetails(email, date))
    }, [date])

    const convertData = () => {
        let userSession = [];
        const time = userSessionDetails.final_hours

        let durations = userSessionDetails.duration;
        let start = userSessionDetails.start;
        let stop = userSessionDetails.stop;

        let NoOfSession = durations.length;

        for (let i = 0; i < NoOfSession; i++) {
            let obj = { duration: durations[i], start: start[i], stop: stop[i] }
            userSession.push(obj);
        }
        setSessionData(userSession)
        setTotalTime(time)
        setLoading(false)
    }

    useEffect(() => {
        if (Object.keys(userSessionDetails).length > 0) {
            convertData()
        }
    }, [userSessionDetails])


    const columns = useMemo(() => (COLUMNS), [COLUMNS])
    const data = useMemo(() => sessionData, [sessionData])

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 4 }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        prepareRow,
    } = tableInstance

    const { pageIndex } = state

    return (
        <>
            {loading ? (
                <div className="kube__userselect-loading"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        zIndex: 100,
                        margin: "auto",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                sessionData.length > 0 ? (
                    <>
                        <Title>Total Time: {totalTime} hours</Title>
                        <div>
                            <table className='table' {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th className='th' {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render("Header")}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, idx) => {
                                        prepareRow(row)
                                        return (
                                            <>
                                                <tr {...row.getRowProps()} key={idx}>
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                        )
                                                    })}
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div style={{marginTop : '2rem'}} className="kube__userselect-pagination">
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                Prev
                            </button>
                            <span className="kube__userselect-pagination-details">
                                <span>
                                    Page{" "}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{" "}
                                </span>
                            </span>
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                Next
                            </button>
                        </div>
                    </>
                ) : (
                    <div>
                        <h3>Session data not found</h3>
                    </div>
                )
            )
            }
        </>
    )
}

export default DurationTimeTable
