import React, { useState, useEffect} from 'react'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import IconButton from "@material-ui/core/IconButton";
import WeeklyCompliance from './admin/WeeklyComplaince'
import MonthlyComplaince from './admin/MonthlyComplaince'

// Styles:
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`

const Card = styled.div`
  width: 90rem;
  height: 28rem;
  width: 67rem;
  background-color: #FFF8F0;
  box-shadow: 0 4px 15px -5px black;
  transition: all 0.25s ease;
  text-align: center;
  border-radius : 2rem;
  background-color: #ECF0F3;
  border-radius: 10px;
`


const ComplianceOverlayCard = ({ currUserEmail, isVisible, changeOverlayCardState }) => {

  const [wrapperVisibility, setWrapperVisibility] = useState(false)
  const [wrapperZIndex, setWrapperZIndex] = useState(-1)
  const [cardVisibility, setCardVisibility] = useState(false)

  useEffect(() => {
    if (isVisible) {
      setWrapperZIndex(1)
      setWrapperVisibility(true)
      setCardVisibility(true)
    } else {
      setCardVisibility(false)
      setWrapperVisibility(false)
      setWrapperZIndex(-1)
    }
  }, [isVisible])

  return (
    <div className="overlay--card--wrapper">
      <Wrapper style={{ zIndex: wrapperZIndex, filter: wrapperVisibility ? 'opacity(1)' : 'opacity(0)' }}>
        <Card style={{ filter: cardVisibility ? 'opacity(1)' : 'opacity(0)', display: 'flex', flexDirection: 'column', color: 'green' }}>
          <div className="overlay--card--tabs--container">
            <IconButton className="overlay--card--close--button" onClick={changeOverlayCardState}>
              <MdClose />
            </IconButton>
            <div className="overlay-col-container" style={{ height: '25em' }} >
              <div className="div--num--1" style={{ color: 'black' }}>
                <WeeklyCompliance />
              </div>
              <div className="div--num--2" style={{ color: 'white' }}>
                <MonthlyComplaince />
              </div>
            </div>
          </div>
        </Card>
      </Wrapper>
    </div>
  )
}

export default ComplianceOverlayCard

