import React,{useState} from 'react'
import '../../App.css'
import male from '../../../src/assets/male.png'
import app1 from '../../../src/assets/kube-app/app3.svg'
import app2 from '../../../src/assets/kube-app/app2.svg'
import app3 from '../../../src/assets/kube-app/app4.svg'
import styled from 'styled-components'
import {Auth} from 'aws-amplify'
import Link from '@material-ui/core/Link'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
    width: 21.688rem;
    height: 35.438rem;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    text-align: center;
    margin-top: 3rem;
`
const Avatar = styled.img`
    margin-top: 2.5rem;
    width: 95px;
`
const Apps = styled.div`
    margin-top: 2rem;
`
const Icon = styled.img`
    padding: 10px;
`
const Line = styled.hr`
    width: 222.5px;
    margin: 0 auto;
`
const Heading = styled.div`
    margin-top: 1rem;
`
const Name = styled.div`
    font-size: 1.25rem;
    color: #212121;
    font-weight: 500;
`
const Designation = styled.div`
    margin-top: 0.5rem;
    margin-bottom: 3rem;
    font-size: 0.9rem;
    color: #212121;
`
const Content = styled.div`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    color: #212121;
`
const Field = styled.div`
    margin-top: 15px;
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 500;
    color: #212121;
`
const Value = styled.div`
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 12px;
    color: #212121;
`

const Profile = (props) => {
    const logInStatus = useSelector((state)=>state.loginUser.LoggedInUser);
    let currentUserEmail=logInStatus?.attributes?.email
    return (
        <div className='kube__profile-container'> 
        {/* <Wrapper> */}
            <Avatar src={male} alt='profile' />
            <Heading>
                <Name>{currentUserEmail}</Name>
                <Designation>Software Engineer</Designation>
            </Heading>
            <Content>
                <Field>Manager</Field>
                <Value>Vijay Jagoori</Value>
            </Content>
            <Line />
            <Content>
                <Field>Team</Field>
                <Value>Readyly</Value>
            </Content>
            <Line />
            <Content>
                <Field>E-mail</Field>
                <Value>{currentUserEmail}</Value>
            </Content>
            <Link href="/changepassword">
                    Change Password?
                </Link> 
            <Apps>
                <Icon src={app1} alt='app' />
                <Icon src={app2} alt='app' />
                <Icon src={app3} alt='app' />
            </Apps>
        {/* </Wrapper> */}
        </div>
    )
}

export default Profile