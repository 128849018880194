import React from 'react'
import { Modal } from "@mui/material";
import { Box } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

//--------------------------------MODAL FOR IMAGES------------------------------------------//
 function PopApp(props){

return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        animation={false}
      >
        <Box className="modal-body" sx={style}>
            <div>
                <img src={props.ImgUrl} height="700px" alt='Not available'/>
            </div>
        </Box>
      </Modal>
    </div>
  );
}


export default PopApp;
