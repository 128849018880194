import React, { useState } from 'react'
import '../../App.css'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useAuth } from '../../utils/auth'
import { useNavigate } from 'react-router-dom'

const ForgotPassword = () => {
  const [code, setCode] = useState('')
  const [username, setUser] = useState('')
  const auth = useAuth()
  const navigate = useNavigate()
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const changePassword = (event) => {
    event.preventDefault();
    auth.validatePassword(username, code, values.password);
  }
  const sendcode = (event) => {
    event.preventDefault();
    auth.sendCode(username);
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginBackButton = () => {
    navigate('/login')
  }

  return (
    <div className="kube--login--wrapper util--container">
      <div className="kube--login--logo--container">

        <h3 style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          lineHeight: '5rem',
          color: '#4B566C',
        }}>Forgot Password</h3>
      </div>

      <div className="kube--login--organization">
        <input type="email" className="kube--login--inputs" placeholder='Enter your email ID' onChange={e => setUser(e.target.value)} />
        <button onClick={sendcode} style={{ cursor: 'pointer' }} className='kube--login--buttons'>Send Code</button>
      </div>

      <div className="kube--login--divider--comp">
        <hr style={{ border: "1px solid rgba(130, 130, 130, 0.1)" }} />
      </div>

      <div className="kube--login--credentials">
        <form id="login-form" noValidate autoComplete="off">
          <div style={
            {
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              alignItems: 'center'
            }
          }>
            <input type="text" className='kube--login--inputs' placeholder="Enter the code" onChange={e => setCode(e.target.value)} />
            <div className="visibility--pass--eye">

              <input
                id="kube--forgotpassword--password--input"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                placeholder="Enter New Password" />
              <InputAdornment
                position="end"
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </div>

          </div>
        </form>
      </div>
      <div className="kube--login--loginbutton--container">
        <button className='kube--login--buttons' style={{ cursor: 'pointer' }} onClick={changePassword} >Change Password</button>
      </div>
    </div>
  )
}

export default ForgotPassword