import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import '../../App.css'
import styled from 'styled-components'
import axios from '../../api/axios';
import * as Endpoint from "../../Endpoint"
import { toggleSnackbar } from '../dashboard/store/actions';


const Input = styled.div`
  margin-top: 1rem;
`
const Input2 = styled.div`
  margin-top: 1rem;
  margin-left: 1.85rem;
  display: flex;
  flex-direction: row;
`
const InputTag = styled.div`
  margin-bottom: 0.25rem;
  font-weight: 700;
  font-size: 0.75rem;
`
const Para = styled.div`
  margin-top: 1rem;
  font-weight: 700;
  font-size: 0.75rem;
`
const InputField = styled.input`
  padding-left: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1rem;
  outline: none;
  height: 2rem;
  width: 4rem;
  margin-top: 0.5rem;
  background: rgba(243, 121, 94, 0.2);
  border: 1px solid #F3795E;
  box-sizing: border-box;
  &:active, &:focus {
    background: #FFFFFF;
    border: 1px solid black;
    box-shadow: none;
  }
`
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.3rem;
  margin: 0 auto;
  margin-top: 2rem;
  background-color: #F3795E;
  color: #FFF8F0;
  border-radius: 50%;
  box-shadow: ${props => props.isDisabled ? '0 0 0 0 transparent' : `0px 8px 20px rgba(0, 0, 0, 0.25)`};
  filter: ${props => props.isDisabled ? 'opacity(0.5)' : 'opacity(1)'};
  user-select: none;
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  transition: all 0.25s ease;
  &:active {
    box-shadow: 0 0 0 0 transparent;
  }
  &:hover {
    background-color: #E24623;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

const minutes = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

const Settings = (props) => {
  const orgid = '5e9706c43ee0632bad80b887'
  const [cf, setcf] = useState(0);
  const [sf, setsf] = useState(0);//Declare variable and change value accordingly
  const [inactivityTimeout, setInactivityTimeout] = useState(15);
  const logInStatus = useSelector((state) => state.loginUser.LoggedInUser);
  const authEmail = logInStatus.attributes.email //Email Id of current logged in 
  const dispatch = useDispatch();


  const saveData = async (e) => {
  
    const postData = {
      orgid,
      cap_frequency: parseInt(cf),
      sync_frequency: parseInt(sf),
      inactivity_timeout: parseInt(inactivityTimeout),
      user_id: authEmail
    }
    const headers = {
      "X-API-Key": process.env.REACT_APP_API_KEY
    };
    

    try{
      const response = await axios.put(Endpoint.API_KUBE_UPDATE_TIME_CONFIG, postData,{
        headers: headers
      });
      if (response.data === "sucessfully update the frequencies") {
        dispatch(toggleSnackbar({
          status: true,
          message: "Successfully Updated!",
          severity: "success",
        }))
      }
    }catch(err){
      dispatch(toggleSnackbar({
        status: true,
        message: err.message,
        severity: "error",
      }))
    }
  }

  return (
    <div className='kube__settings-container settings-util'>
      <div className="kube__settings-container--items">

        <Input>
          <InputTag>Inactivity Timeout</InputTag>
          <select name='InactivityTimeout' value={inactivityTimeout} onChange={(e) => setInactivityTimeout(e.target.value)} style={{ width: '18rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem', boxShadow: '0px 0px' }}>
            {minutes.map((minute) => {
              return <option value={minute}>{minute}</option>
            })}
          </select>
        </Input>

        <Input>
          <InputTag>Capture Frequency</InputTag>
          <select name='CaptureFreq' value={cf} onChange={(e) => setcf(e.target.value)} style={{ width: '18rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem', boxShadow: '0px 0px' }}>
            {minutes.map((minute) => {
              return <option value={minute}>{minute}</option>
            })}
          </select>
        </Input>

        <Input>
          <InputTag>Sync Frequency</InputTag>
          <select name='SyncFreq' value={sf} onChange={(e) => setsf(e.target.value)} style={{ width: '18rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem', boxShadow: '0px 0px' }}>
            {minutes.map((minute) => {
              return <option value={minute}>{minute}</option>
            })}
          </select>
        </Input>
      </div>

      <div className="kube--login--loginbutton--container">
        <button className='kube--login--buttons' style={{ cursor: 'pointer' }} onClick={saveData} >Save Changes</button>
      </div>
    </div>
    
  )
}

export default Settings