import React from "react";
import { Image } from "semantic-ui-react";
import styled from 'styled-components'

const thumbnail = styled.img`
    background-color: gray;
    color: white;
    font-weight: bolder;
    font-size: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`


const ImageThumbnail = ({src,style,app_url }) => {

  return (
    <div>
      {src && (
        <Image
          width={36}
          height={39}
          src={src}
          style={style}
          className={`thumbnail`}
          title={app_url}
        />
      )}
    </div>
  );
};

export default ImageThumbnail;