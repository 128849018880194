import React, { useEffect, useState } from 'react';
import '../../../../App.css';
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from '../../store/actions'
import moment from 'moment'

const TimeWidget = (props) => {
  const dispatch = useDispatch();
  const timeworked = useSelector((total_time) => total_time.statistics.total_time);
  const isUserTotalTimeDataLoading = useSelector((total_time) => total_time.statistics.userTotalTimeDataLoading)
  const [dataValue, setDataValue] = useState("00\xa0\xa0:\xa0\xa000\xa0\xa0:\xa0\xa000");
  function changeDate(date) {
    const dateFormat = 'YYYY-MM-DD';
    const newDate = moment(new Date(date)).format(dateFormat);
    return newDate;
  }

  useEffect(() => {
    const d1 = changeDate(props.startDate);
    const d2 = changeDate(props.endDate);
    var data1 = [];
    data1.push(props.email)
    dispatch(Actions.getKubeUserTimeWidgetTotalTime(data1, d1, d2));
  }, [props.startDate, props.endDate])

  useEffect(() => {
    setDataValue(timeworked + ":00")
  }, [timeworked])

  return (
    <div onClick={props.handleTimeClick}>
      {isUserTotalTimeDataLoading === false && <div className='landing__TimeWidget'>{dataValue}</div>}
    </div>
  );
}

export default TimeWidget;