import React from 'react'
import '../../App.css'
import { Link } from '@material-ui/core';

const InstallationStepsIntel = () => {
    const handleLoginBackButton = () => {
        window.history.back();
    }
    return (
        <div style={{ marginLeft: '20px' }}>
            <img style={{ marginTop: '20px' }} src="assets/back-button.png" alt="back" onClick={handleLoginBackButton} />
            <h2 style={{ color: '#212121', marginTop: '20px' }}>Installation Steps for Mac Intel</h2>
            <p style={{ color: '#212121', marginTop: '25px' }} >1. If your using the kube for the first time,you need to check your reaadyly mail for temporary password and username
                <br></br>
                <br></br>
                2. You need to change the temporary password at kube webapp, from the given link <Link href='/downloads'>Downloads</Link>
                <br></br>
                <br></br>
                3. Now go to downloads section in kube webapp and download kube desktop app for your operating system.
                <br></br>
                <br></br>
                4. After downloading, you will have .zip extension file downloaded. Extract this .zip extension file
                <br></br>
                <br></br>
                5. after extracting the file,please navigate to the Macos folder inside dist folder (dist->kube.app->Contents->MacOS) .
                <br></br>
                <br></br>
                6. Use your credentials and <b>now Kube is ready to run.</b>
                <br></br>
                <br></br>
                ~<b>For any further help and bug reporting contact on: <i>manpreet.singh@readyly.com</i></b>
                <br></br>
                <br></br>
            </p>
        </div>
    )
}

export default InstallationStepsIntel