import React, {useState, useEffect} from 'react'
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from 'react-redux'
import Chart from "react-apexcharts";

const style = {
  position: "absolute",
  overflow:"scroll",
  boxSizing:'borderbox',
  border: '5px solid #ECF0F3',
  top: "50%",
  left: "50%",
  width: "80%",
  height:"90%",
  transform: "translate(-50%, -50%)",
  // bgcolor: "#ffffff",
  bgcolor: "#ECF0F3;",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
  borderRadius : "10px",
  // border : 'none',
  fontFamily : "Inter",
  scrollY : "hidden"
};

//------------------------------- MODAL FOR BAR GRAPH  IN DETAILED VIEW WITH TIMING IN 4TH QUADRANT--------------------------//

 function PopApp(props){
  const usersData = useSelector((appData)=>appData.statistics.appData);//get data for app data
  const [appName, setAppName] = useState([]);
  const [appTime, setAppTime] = useState([]);
  useEffect(() => {
    if(usersData.appnames<1){
        setAppName(["No Data"]);
        setAppTime(["0"]);
    }
    else{
    setAppName(usersData.appnames);
    setAppTime(usersData.time);
    }
}, [usersData])

const  options= {
  colors: ['#F3795e'],
  chart: {
    id: "basic-bar"
  },
  xaxis: {
    categories: appName||[0],
    tickPlacement: 'on',
  },
  yaxis:{
      tickAmount:8,
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      horizontal: true,
    }
  },
  
}
const series= [
  {
    name: "Working Time",
    data: appTime||[0],
  }
]
return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-body" sx={style}>
        <Chart
              options={options}
              series={series}
              type="bar"
            />
        </Box>
      </Modal>
    </div>
  );
}


export default PopApp;
