import Tabs from './Tabs'

const LeaveDashboard = () => {

  return (
    <div className="leave-wrapper">
      <Tabs />
    </div>
  )
}

export default LeaveDashboard
