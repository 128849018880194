import * as Actions from '../actions'

const initialState = {
    data: {}
}

const timeReducer = function (state=initialState, action) {
    switch(action.type){
        case Actions.GET_KUBE_TIME_DETAIL:
        {
            return {
                ...state,
                data: action.payload
            }
        }
        default:
        {
            return state
        }
    }
}

export default timeReducer;