export const API_TIME_WORKED = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/rolebasedsummarypage`;
export const API_GRAPH_DATA = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/week-workedhours`;
export const API_DOUGHNUT = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/activeappperweek`;
export const API_APPDATA = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/activeappperweek`;
export const API_DELETE_SCREEN_SHOT = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/deleteimage`;
export const API_INVALIDATE_SCREEN_SHOT_CUSTOM = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/invalidCustomdata`;
export const API_TOGGLE_SCREEN_SHOT_ROW_STATUS =  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/invalidRowData`;
export const API_DOWNLOAD = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/downloadurl`;
export const API_VPN_DOWNLOAD = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/downloadVpn`;
export const API_TOTAL_TIME = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/workedhours`;

export const API_GET_KUBE_WORKSPACE = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/workspace`;
export const API_SCREENSHOTS_DATA = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/`;
export const API_MANAGE_KUBE_WORKSPACES = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/workspace`;
export const API_KUBE_SCREENSHOTS = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/getimages`;
export const API_ADMIN_ACCESS_MANAGEMENT = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/usertable`;
export const API_WORKSPACE_EDIT = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/Updateusertable`; 
export const GET_ADD_USERS = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/adduser`;
export const API_USER_SESSION_DETAILS = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/worked-hours`;
export const API_GET_USER_WORKSPACE_DETAILS = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/workspace`;

export const API_TOGGLE_USER_WORKSPACE = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/toggle-workspace`;
export const API_DELETE_USER = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/deleteuser`;
export const API_ROLE_USERS = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/getorgdetails`;
export const API_ADD_NEW_WORKSPACE = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/createworkspace`;
export const API_DELETE_WORKSPACE = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/deleteworkspace`;
export const API_GET_USER_ROLE = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/userPoolRole`;
export const API_PUBLIC_HOLIDAYS = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/holidaysView`;
export const API_PUBLIC_HOLIDAYS_ADD_DELETE = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/addDeleteEventCalender`;
export const API_APPLY_AND_SANCTION_LEAVE = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/leaveApply`;

export const API_ADMIN_ACTIVE_LEAVE_APPLICATIONS = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/leavesAdminView`;
export const API_USER_ACTIVE_LEAVE_APPLICATIONS = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/leavesUserView`;
export const API_COMPLIANCE_DATA = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/userCompliance`;
export const API_ADMIN_COMPLIANCE_DATA = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/adminViewCompliance`;
export const API_USER_JIRA_COMPLIANCE_DATA = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/jiraUserCompliance`;
export const API_ALL_USERS_JIRA_COMPLIANCE_DATA = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/jiraAllUsers`;
export const API_KUBE_UPDATE_TIME_CONFIG = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/updatetimerconfig`;
export const API_INVALIDATED_SLOTS_DETAILS = `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/invalidatedslotdetails`;