import React, { useState } from 'react'
import { SliderComponent } from '@syncfusion/ej2-react-inputs'
import '../../../App.css'
import WeekPicker from '../widgets/weekpicker'
import { MdCalendarToday} from 'react-icons/md'
import styled from 'styled-components'
import { useParams } from "react-router-dom";


const Link = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background-color: #F3795E;
  color: #FFF8F0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 0.813rem;
  cursor: pointer;
  &:hover {
    background-color: #E24623;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

function Dateslider(props) {
  const urldata = useParams();
  const urlDate = urldata.date;
  const la = new Date(urlDate);

  let today = new Date();
  today.setDate(today.getDate());
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  let last = new Date();
  last.setDate(last.getDate() - 6);
  let date = String(last.getDate()).padStart(2, "0");
  let month = String(last.getMonth() + 1).padStart(2, "0");
  let year = last.getFullYear();
  last = year + "-" + month + "-" + date;

  let min, max;
  if (urlDate === undefined) {
    min = new Date(last).getTime();
    max = new Date(today).getTime();
  } else {
    const b = new Date(la - 6 * 24 * 60 * 60 * 1000); //Managing the slider value according to the week selected
    min = new Date(b).getTime();
    max = new Date(urlDate).getTime();
  }
  const [startweek, setStartWeek] = useState(min);
  const [endweek, setEndWeek] = useState(max);
  const [showWeekPicker, setShowWeekPicker] = useState(false);
  const [showWeekPickerButton, setShowWeekPickerButton] = useState(true);
  const step = 86400000;

  // eslint-disable-next-line no-unused-vars
  const [value, setSelectedValue] = useState(
    new Date(urlDate || today).getTime() //setting the Date according to the url value.
  );

  // Slider ticks customization
  //const ticks = { placement: "After", largeStep: 2 * 86400000 };
  const tooltip = { placement: "Before", isVisible: true };

  function renderingTicksHandler(args) {
    let totalMiliSeconds = Number(args.value);
    // Converting the current milliseconds to the respective date in desired format
    args.text = new Date(totalMiliSeconds).toISOString().substring(0, 10);
  }

  function tooltipChangeHandler(args) {
    let totalMiliSeconds = Number(args.text);
    // Converting the current milliseconds to the respective date in desired format
    args.text = new Date(totalMiliSeconds).toISOString().substring(0, 10);
    props.handleDateChange(args.text);
  }

  const handleWeekChange = (startdateweek, enddateWeek) => {
    const min = new Date(startdateweek).getTime();
    const max = new Date(enddateWeek).getTime();
    setStartWeek(min);
    setEndWeek(max);
    setShowWeekPicker(false);
    setShowWeekPickerButton(true);
  };
  
  return (
    <>
      
        {showWeekPicker && <WeekPicker handleWeekChange={handleWeekChange} setShowWeekPicker={setShowWeekPicker}/>}
      
      <div id="container">
        <div className="kube__slider-wrap">
        {showWeekPickerButton && <Link title="Calender" onClick={ () => {setShowWeekPicker(true);}} style={{marginLeft:'auto',marginRight:'auto',}}><MdCalendarToday/></Link>}
          <div className="labeltext">Date</div>
          <SliderComponent
            id="slider"
            orientation="Vertical"
            type="MinRange"
            min={startweek}
            max={endweek}
            value={value}
            step={step}
            tooltip={tooltip}
            //ticks={ticks}
            showButtons={true}
            tooltipChange={tooltipChangeHandler}
            renderingTicks={renderingTicksHandler}
          />
        </div>
      </div>
    </>
  );
}

export default Dateslider;