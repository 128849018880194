import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DatePicker from "react-datepicker"
import { toggleSnackbar } from '../dashboard/store/actions'
import { useDispatch } from 'react-redux'


export default function ApplyLeaveDialogBox(props) {
    const dispatch = useDispatch()
    const {email : userEmail} = useParams()
    const [reasonForLeave, setReasonForLeave] = useState("")
    const [startDate, setStartDate] = useState(new Date().getTime() - 518400000)
    const [endDate, setEndDate] = useState(new Date().getTime() - 518400000)
    const [open, setOpen] = useState(false)
    const currYear = new Date().getFullYear().toString()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleStartDateChange = (date) => {
        setStartDate(date)
    }
    const handleEndDateChange = (date) => {
        setEndDate(date)
    }

    const handleReasonChange = (event) => {
        setReasonForLeave(event.target.value)
    }

    const convertDate = (dateInWords) => {
        let processDate = new Date(dateInWords)
        let currYear = processDate.getFullYear()
        let currMonth = parseInt(processDate.getMonth()) + 1
        if (Math.floor(currMonth / 10) === 0) {
            currMonth = '0' + currMonth
        }
        let currDay = processDate.getDate()
        if (Math.floor(currDay / 10) === 0) {
            currDay = '0' + currDay
        }

        return currYear + "-" + currMonth + "-" + currDay;
    }

    const getDaysArray = function (start, end) {
        let arr = []
        for (let date = new Date(start); date <= new Date(end); date.setDate(date.getDate() + 1)) {
            arr.push(convertDate(new Date(date)))
        }
       return arr
    }

    const handleSubmit = () => {
        const dates = getDaysArray(startDate, endDate)
        const status = "pending"
        const reason = reasonForLeave
        const userId = userEmail
        const year = currYear

        if (reason.length === 0) {
            dispatch(toggleSnackbar({
                status: true,
                message: "Please enter a reason for taking leave",
                severity: "error"
            }))
            handleClose()
            return
        }

        props.handleApplyLeave(dates, status, reason, userId, year)
        handleClose()
    }

    return (
        <div>
            <div >
                <button className="user-apply-leave-button util--btn" onClick={handleClickOpen}>
                    Apply For Leave
                </button>
            </div>
            <Dialog className="leave-dialog-box" open={open} onClose={handleClose}>
                <div className="user-apply-leave-dialog">
                    <h4 style={{ color: '#4B566C' }}>
                        NOTE: If the leave is for a single day, please keep the start and end date same.
                    </h4>
                    <DialogContent>
                        <div className="leave-dialog-datepickers">
                            <DatePicker fullWidth selected={startDate} onChange={handleStartDateChange} />
                            <DatePicker fullWidth selected={endDate} onChange={handleEndDateChange} />
                        </div>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Enter the reason for taking leave"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleReasonChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{color : '#f3795e'}} onClick={handleClose}>Cancel</Button>
                        <Button sx={{ color: '#f3795e' }} onClick={handleSubmit}>Apply</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}
