// Packages:
import React from 'react'
import styled from 'styled-components'
import logo from '../assets/logo-xl.png'
import { Button } from '@material-ui/core'

// Imports:
import '../App.css'
import { useNavigate } from 'react-router-dom'

// Styles:
const Wrapper = styled.div`
    width: 390px;
    height: 650px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
     text-align: center;
    margin-top: 53px;
`

const Landing = () => {
    const navigate = useNavigate()
    return (
        <Wrapper>
            <img src={logo} style={{marginTop:'170px'}} alt="kube-logo" />
            <h1 style={{marginTop:'25px'}}>Readyly</h1>
            <p style={{color:'grey', marginTop:'25px'}}>Information Technology {'&'} Services</p>
            <Button type='submit' style={{marginTop:'20px', backgroundColor:'#f27a5e',color:'white'}} onClick={() => navigate('login')}>Log in</Button>
        </Wrapper>
    )
}

export default Landing