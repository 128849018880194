import React, { useState } from 'react'
import styled from 'styled-components'
import { ImConnection } from 'react-icons/im'
import { MdPersonAddAlt1, MdSpaceDashboard } from 'react-icons/md'
import OverlayCard from './OverlayCard'
import { Button } from '../Bar'

// Styles:
const Wrapper = styled.div`
  position: absolute;
  top: calc(5rem + 2px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 5rem - 2px);
  color: #FFF8F0;
`

const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const BigButton = styled(Button)`
  width: 7.5rem;
  height: 7.5rem;
  margin: 0 3rem;
  font-size: 3rem;
  &:hover {
    background: #E24623;
    box-shadow: inset 0px 8px 20px rgba(0, 0, 0, 0.25);
  }
`

const ButtonText = styled.div`
  margin-bottom: 1.25rem;
  font-size: 1.20rem;
  font-weight: 500;
  margin-top: 1rem;
  color: #212121;
`

const Admin = () => {
  const [ showOverlayCard, setShowOverlayCard ] = useState(false)

  return (
    <>
      <Wrapper>
        <ButtonSection>
          <BigButton onClick={ () => setShowOverlayCard(true) }><MdPersonAddAlt1 /></BigButton>
          <ButtonText>Add User</ButtonText>
        </ButtonSection>
        <ButtonSection>
          <BigButton onClick={ () => setShowOverlayCard(true) }><ImConnection /></BigButton>
          <ButtonText>Setup VPN</ButtonText>
        </ButtonSection>
        <ButtonSection>
          <BigButton onClick={ () => setShowOverlayCard(true) }><MdSpaceDashboard /></BigButton>
          <ButtonText>Setup Workspace</ButtonText>
        </ButtonSection>
      </Wrapper>
      <OverlayCard isVisible={ showOverlayCard } setShowOverlayCard={ setShowOverlayCard } />
    </>
  )
}

export default Admin