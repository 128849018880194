import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import "../../../App.css";
import axios from "axios";
import PopApp from './PopApp'
import * as Endpoint from '../../../Endpoint'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toggleSnackbar } from "../store/actions";
import CryptoJS from 'crypto-js'
import { Auth } from "aws-amplify"

const Widget1 = ({
  imageURL,
  title,
  email,
  selectedDate,
  selectedTime,
  bagCol,
  toggle
}) => {
  const params = useParams()
  const {email : currUserEmail} = params;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [invalidate, setInvalidate] = React.useState(true)
  const dispatch = useDispatch()
  const [admin, setAdmin] = useState(false)
  const logInStatus = useSelector((state) => state.loginUser.LoggedInUser);
  const loggedInUserEmail = logInStatus.attributes.email;

  useEffect(() => {
    const authenticate = async () => {
      try {
        const userDetails = await Auth.currentAuthenticatedUser()
        let key = localStorage.getItem('role')
        let bytes = CryptoJS.AES.decrypt(key, process.env.REACT_APP_SECRET)
        let v1 = bytes.toString(CryptoJS.enc.Utf8);
        let v2 = (JSON.parse(v1))
        if (v2 === 'Admin' || v2 === 'Superadmin' || v2 === 'Manager') {
          setAdmin(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
    authenticate()
  }, [])


  const open = Boolean(anchorEl);
  const images = {
    myImage: require("../../../assets/Image_deleted_logo.png"),
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    bagCol = "#FF3131"
  }, [invalidate])


  const [openModal, setOpenModal] = useState(false);//Modal for Image
  const [imgPic, setImgPic] = useState(imageURL)

  const handleImgClick = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  async function deleteScreenshots() {
    try {
      const headers = {
        "X-API-Key": process.env.REACT_APP_API_KEY
      };
      
      const response = await axios.get(Endpoint.API_DELETE_SCREEN_SHOT, { params: { "userEmail": email, "selectedDate": selectedDate, "selectedTime": selectedTime } ,headers:headers})
      if (response.data === "sucessfully deleted image") {
        setImgPic(images.myImage)
      }
    } catch (err) {
      dispatch(toggleSnackbar({
        status: true,
        message: "Something went wrong...Try again",
        severity: "error"
      }))
    }
  }

  async function invalidateScreenshot(invalidationStatus) {
    setInvalidate(false)
    try {
      const postData = {
        "userEmail": email,
        "selectedDate": selectedDate,
        "selectedTime": selectedTime,
        "invalid_status": invalidationStatus
      }
      const headers = {
        "X-API-Key": process.env.REACT_APP_API_KEY
      };
      

      const response = await axios.post(Endpoint.API_INVALIDATE_SCREEN_SHOT_CUSTOM, postData,{
        headers: headers
      })
      if (response.data === "succesfully updated") {
        dispatch(toggleSnackbar({
          status: true,
          message: invalidationStatus === "True" ? "Invalidating the selected slot" : "Revalidating the selected slot",
          severity: "success"
        }))
        toggle()
      }
    } catch (err) {
      dispatch(toggleSnackbar({
        status: true,
        message: "Something went wrong...Try again",
        severity: "error"
      }))
    }
  }
  //When image is deleted and their is no image to display then we use tjis hanleOn error
  const handleOnError = (e) => {
    e.target.src = images.myImage
  }
  return (
    <div>
      {bagCol === "#FF3131" && <h3 style={{"textAlign" : "center"}}>INVALIDATED</h3>}
      {openModal && <PopApp open={openModal} setOpenModal={setOpenModal} ImgUrl={imageURL} animation={imageURL === images.myImage ? false : true} />}
      <div className="kube__tile-title">
        <p>{title}</p>
      </div>
      <div
        className="kube__tile-container"
        style={{
          boxSizing: "border-box",
          border: `1px solid ${bagCol}`,
          borderWidth: "1rem",
          borderRadius : "10px"
        }}
      >
        <button className="kube__tile-container-button">
          <img
            className="kube__tile-screenshot"
            src={imgPic}
            onError={handleOnError}
            alt="ScreenShots"
            onClick={handleImgClick}
          />
        </button>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            position: "absolute",
            top: "30px",
            right: "30px",
            cursor: "pointer",
          }}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {/* Delete button setup */}
          {!admin && <MenuItem
            onClick={() => {
              deleteScreenshots()
            }}
          >
            Delete
          </MenuItem>}
          {admin && (currUserEmail === loggedInUserEmail) && <MenuItem
            onClick={() => {
              deleteScreenshots()
            }}
          >
            Delete
          </MenuItem> }
          {admin && <MenuItem
            onClick={() => {
              invalidateScreenshot("True")
            }}
          >
            Invalidate
          </MenuItem>}
          {admin && <MenuItem
            onClick={() => {
              invalidateScreenshot("False")
            }}
          >
            Revalidate
          </MenuItem>}
        </Menu>
      </div>
    </div>
  )
}
export default Widget1




