import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from './store/actions'
import reducer from './store/reducers'
import withReducer from './store/withReducer'
import { Dateslider, Timeslider, Widget1 } from '..'
import '../../App.css'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'

const findBgCol = (total_intensity) => {
  switch (total_intensity) {
    case 0:
      return "#E3EECA"
    case 1:
      return "#C5E0A0"
    case 2:
      return "#A8D279"
    case 3:
      return "#D5D170"
    case 4:
      return "#AAB952"
    case 5:
      return "#7C9D39"
    case 6:
      return "#528124"
    default:
      return "#2D6514"
  }
}

function findMouseIntensityLvl(mouseClicks) {
  let upperLimit = 2500
  return mouseClicks / upperLimit
}

function findKeyIntensityLvl(keyStrokes) {
  let upperLimit = 250
  return keyStrokes / upperLimit
}


function findIntensityColour(screenshotsData) {
  if (screenshotsData.screen_url === "Null" && screenshotsData.cam_url === "NUll"){
    return "#FFFFFF";
  }

  let isInvalidated = screenshotsData["Invalid_status"]
  if (isInvalidated === "True") {
    return "#FF3131";
  }

  let mouseInt = findMouseIntensityLvl(
    parseInt(screenshotsData["mouse"])
  )
  let keysInt = findKeyIntensityLvl(
    parseInt(screenshotsData["keystrokes"])
  )

  let mean = Math.round(((mouseInt + keysInt) / 2) * 10)
  let intLevel = findBgCol(mean)

  return intLevel
}


const Dashboard = () => {
  const { email, date, time } = useParams()
  const [selectedDate, setSelectedDate] = useState(date)
  const [selectedTime, setSelectedTime] = useState(time)
  const [selectedSsUrl, setSsURL] = useState()
  const [selectedWsUrl, setWsURL] = useState()

  const images = {
    myImage: require("../../assets/no-image-pic.jpg"),
  }

  const dispatch = useDispatch();
  const screenshotsData = useSelector((dashboard) => dashboard.screenshots.data)
  const [loading, setloading] = useState(false)
  const [toggled, setToggled] = useState(false)

  useEffect(() => {
    dispatch(Actions.getKubeScreenshots(selectedDate, selectedTime, email, setloading))
  }, [email, selectedDate, selectedTime, dispatch, toggled])

  const toggle = () => {
    setToggled(toggled => !toggled)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleTimeChange = (time) => {
    setSelectedTime(time)
  }
  function currentSelectedDate() {
    return selectedDate
  }

  useEffect(() => {
    if (screenshotsData["webcamshot"] !== "Null") {
      setWsURL(screenshotsData["cam_url"])
    }
    else {
      setWsURL(images.myImage);
    }
    if (screenshotsData.screenshot !== "Null") {
      setSsURL(screenshotsData.screen_url)
    }
    else {
      setSsURL(images.myImage)
    }
  }, [screenshotsData, selectedWsUrl])

  let bagColor = findIntensityColour(screenshotsData)

  return (
    <>
      <div className="kube__dashboard">
        <div className="sliders1" style={{ paddingLeft: "2%" }}>
          <Dateslider
            handleDateChange={handleDateChange}
            selectedDate={currentSelectedDate}
          />
        </div>
        <div className="sliders2">
          <Timeslider handleTimeChange={handleTimeChange} />
        </div>
        <div className="sliders3" style={{ paddingLeft: "7%" }}>
          {loading ? (
            <div className="kube--util--center--div"
            >
              <div className='dot-flashing'></div>
            </div>
          ) : (
            <>
              <div className="kube__upper-tiles" >
                <Widget1
                  toggle={toggle}
                  imageURL={selectedSsUrl}
                  title="Screenshot"
                  email={email}
                  selectedDate={selectedDate}
                  selectedTime={selectedTime}
                  bagCol={bagColor}
                />
                <Widget1
                  toggle={toggle}
                  imageURL={selectedWsUrl}
                  title="Webcam Shot"
                  email={email}
                  selectedDate={selectedDate}
                  selectedTime={selectedTime}
                  bagCol={bagColor}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default withReducer(
  "dashboard",
  reducer
)(Dashboard)
