import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import {
    Dashboard,
    Navbar,
    Profile,
    Settings,
    Landing,
    Login,
    Workspace,
} from "./components";

import { AuthProvider } from "./utils/auth";
import { RequireAuth } from "./utils/RequireAuth";
import ForgotPassword from "./components/forgotpassword/ForgotPassword";
import ChangePassword from "./components/changepassword/ChangePassword";
import ScreenshotsGridView from "./components/dashboard/ScreenshotsGridView";
import InstallationSteps from "./components/installationinstructions/IntsallationInstructionsMacM1";
import InstallationStepsIntel from "./components/installationinstructions/IntsallationInstructionsMacIntel";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import LandingIcon from './components/dashboard/landing/LandingIcon'
import ErrorSnackbar from "./shared/ErrorSnackbar";
import ManageUserWorkspace from "./components/workspace/adminWorkspace/ManageUserWorkspace";
import NetworkComp from "./components/workspace/adminWorkspace/networkComp/NetworkComp";
import LeaveDashboard from "./components/leavemanagement/LeaveDashboard";
import AllUsersDetails from "./components/workspace/adminWorkspace/AllUsersDetails";

//Lazy loading is implemented for home and downloads page
const LazyDownload = React.lazy(() => import('./components/downloads/Downloads'));
const LazyVPNDownload = React.lazy(() => import('./components/downloads/VPNDownload'));
const Lazyuserselect = React.lazy(() => import('./components/userselect/Userselect'));

function App() {
    var CryptoJS = require("crypto-js");
    const logInStatus = useSelector((state) => state.loginUser.LoggedInUser);
    //Values for authentication
    const [values, setValues] = useState({
        isAuthenticated: false,
        isAuthenticating: true,
        user: null,
        isAdmin: false,
    });
    const setAuthStatus = (authenticated) => {
        setValues({ ...values, isAuthenticated: authenticated });
    };

    const setUser = (user) => {
        setValues({ ...values, user: user });
    };

    const setAdmin = (isAdmin) => {
        setValues({ ...values, isAdmin: isAdmin });
    };

    const snackbarState = useSelector((state) => state.errorSnackbar)

    useEffect(() => {
        const authenticate = async () => {
            try {
                const userDetails = await Auth.currentAuthenticatedUser();
                let key = localStorage.getItem('role')
                let bytes = CryptoJS.AES.decrypt(key, process.env.REACT_APP_SECRET)
                let v1 = bytes.toString(CryptoJS.enc.Utf8);
                let v2 = (JSON.parse(v1));
                if (v2 === 'Admin' || v2 === 'Superadmin') {
                    setAdmin(true);
                }
                const session = await Auth.currentSession();
                setAuthStatus(true);
            } catch (err) {
                console.log(err);
            }
            setValues({ ...values, isAuthenticating: false });
        };
        authenticate();
    }, []);

    //window.location.reload(true);

    const authProps = {
        isAuthenticated: values.isAuthenticated,
        user: values.user,
        isAdmin: values.isAdmin,
        setAuthStatus: setAuthStatus,
        setUser: setUser,
        setAdmin: setAdmin,
    };
    return (
        !values.isAuthenticating && (
            <AuthProvider>
                <div className="App">
                    {Object.keys(logInStatus).length > 0 ? (
                        <Routes>
                            <Route path="/" element={<Navigate to={`/LandingIcon/${logInStatus.attributes.email}`} replace />} />
                            <Route path="/downloads" element={<React.Suspense fallback='Loading...'><LazyDownload /></React.Suspense>} />
                            <Route path="/vpndownload" element={<React.Suspense fallback='Loading...'><LazyVPNDownload /></React.Suspense>} />
                            <Route path="LandingIcon/:email" element={<Navbar auth={authProps} />} >
                                <Route index element={<RequireAuth><LandingIcon /></RequireAuth>} />
                                <Route path="leaveandcompliance" element={<RequireAuth> <LeaveDashboard /> </RequireAuth>} />
                                <Route path="home" element={<React.Suspense fallback='Loading...'><Lazyuserselect /></React.Suspense>} />
                                <Route path="settings" element={<Settings />} />
                                <Route path="downloads" element={<React.Suspense fallback='Loading...'><LazyDownload /></React.Suspense>} />
                                <Route path="vpndownload" element={<React.Suspense fallback='Loading...'><LazyVPNDownload /></React.Suspense>} />
                                <Route path="profile" element={<Profile auth={authProps} />} />
                            </Route>
                            <Route path="screenshotsgridview/:email/:date" element={<Navbar auth={authProps} />}>
                                <Route index element={<RequireAuth><ScreenshotsGridView /></RequireAuth>} />
                                <Route path="home" element={<React.Suspense fallback='Loading...'><Lazyuserselect /></React.Suspense>} />
                                <Route path="settings" element={<Settings />} />
                                <Route path="downloads" element={<React.Suspense fallback='Loading...'><LazyDownload /></React.Suspense>} />
                                <Route path="vpndownload" element={<React.Suspense fallback='Loading...'><LazyVPNDownload /></React.Suspense>} />
                                <Route path="profile" element={<Profile auth={authProps} />} />
                            </Route>
                            <Route path="forgotpassword" element={<ForgotPassword />} />
                            <Route path="/changepassword" element={<ChangePassword />} />
                            <Route path="installationinstructions" element={<InstallationSteps />} />
                            <Route path="installationinstructionsIntel" element={<InstallationStepsIntel />} />
                            <Route path="dashboard/:email/:date/:time" element={<Navbar auth={authProps} />}>
                                <Route index element={<RequireAuth> <Dashboard /> </RequireAuth>} />
                                <Route path="home" element={<React.Suspense fallback='Loading...'> <Lazyuserselect /> </React.Suspense>} />
                                <Route path="settings" element={<Settings />} />
                                <Route path="downloads" element={<React.Suspense fallback='Loading...'><LazyDownload /></React.Suspense>} />
                                <Route path="vpndownload" element={<React.Suspense fallback='Loading...'><LazyVPNDownload /></React.Suspense>} />
                                <Route path="profile" element={<Profile auth={authProps} />} />
                            </Route>
                            {/* <Route path="workspace/:email" element={<Navbar auth={authProps} />}> */}
                            <Route path="accessmanagement/:email" element={<Navbar auth={authProps} />}>
                                <Route path="allusers" element={<AllUsersDetails />} />
                                <Route path="manage" element={<ManageUserWorkspace />} />
                                <Route path="networktopography" element={<NetworkComp />} />
                                <Route index element={<RequireAuth> <Workspace /> </RequireAuth>} />
                                <Route path="home" element={<React.Suspense fallback='Loading...'> <Lazyuserselect /> </React.Suspense>} />
                                <Route path="settings" element={<Settings />} />
                                <Route path="downloads" element={<React.Suspense fallback='Loading...'><LazyDownload /></React.Suspense>} />
                                <Route path="vpndownload" element={<React.Suspense fallback='Loading...'><LazyVPNDownload /></React.Suspense>} />
                                <Route path="profile" element={<Profile auth={authProps} />} />
                            </Route>
                        </Routes>
                    ) : (
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route path="forgotpassword" element={<ForgotPassword />} />
                            <Route path="/changepassword" element={<ChangePassword />} />
                            <Route path="downloads" element={<React.Suspense fallback='Loading...'><LazyDownload /></React.Suspense>} />
                            <Route path="vpndownload" element={<React.Suspense fallback='Loading...'><LazyVPNDownload /></React.Suspense>} />
                            <Route path="installationinstructions" element={<InstallationSteps />} />
                            <Route path="installationinstructionsIntel" element={<InstallationStepsIntel />} />
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                    )}
                    <ErrorSnackbar message={snackbarState.message} isOpen={snackbarState.status} severity={snackbarState.severity} />
                </div>
            </AuthProvider>
        )
    );
}
export default App;