import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import * as Actions from '../../../../dashboard/store/actions'
import * as Endpoint from '../../../../../Endpoint'
import axios from '../../../../../api/axios'
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
} from "react-table"
import moment from 'moment';
import WeekPicker from '../../../widgets/weekpicker'
import { USERCOLS } from "../../../../../shared/table_columns/UserHeaders";

const MOCK = [{
    "user_id": "Desiri",
    "working_hours": 1,
    "Public_Time_Off": 1,
    "Personal_Time_Off": 1,
    "Effective_hours": 1,
    "Compliance": 1,
    "intensity": 1,
    "without_webcam": 1
}, {
    "user_id": "Katey",
    "working_hours": 2,
    "Public_Time_Off": 2,
    "Personal_Time_Off": 2,
    "Effective_hours": 2,
    "Compliance": 2,
    "intensity": 2,
    "without_webcam": 2
}]

export const COLUMNS = [
    ...USERCOLS
]

const WeeklyCompliance = (props) => {
    let currentDate = moment();
    let weekStart = currentDate.clone().startOf('week');
    let weekEnd = currentDate.clone().endOf('week');

    const [startweek, setStartWeek] = useState(weekStart.format('YYYY-MM-DD'));
    const [endweek, setEndWeek] = useState(weekEnd.format('YYYY-MM-DD'));

    const [showWeekPicker, setShowWeekPicker] = useState(false)
    const [showWeekPickerButton, setShowWeekPickerButton] = useState(true)
    const currentYear = new Date().getFullYear().toString()

    const handleWeekChange = (startdateweek, enddateWeek) => {
        setStartWeek(startdateweek)
        setEndWeek(enddateWeek)

        setShowWeekPicker(false)
        setShowWeekPickerButton(true)
    }

    const dispatch = useDispatch()
    const location = useLocation()
    const { email: userEmail } = useParams()
    const [complianceData, setComplianceData] = useState([])
    const adminComplianceData = useSelector((compliance) => compliance.complianceReducer.weeklyComplianceData)
    const isDataLoading = useSelector((compliance) => compliance.complianceReducer.isWeeklyComplianceDataLoading)

    const userJIRAComplianceData = useSelector((compliance) => compliance.complianceReducer.userWeeklyJIRAComplianceData);
    const isUserJIRAComplianceDataLoading = useSelector((compliance) => compliance.complianceReducer.isUserWeeklyJIRAComplianceDataLoading);

    useEffect(() => {
        let mapping = [{}];

        if (adminComplianceData?.length > 0) {
            mapping[0] = {
                ...adminComplianceData[0],
                Done: userJIRAComplianceData.Done
            }
            
            setComplianceData(mapping);
        };
    }, [adminComplianceData, userJIRAComplianceData]);


    function changeDate(date) {
        const dateFormat = 'YYYY-MM-DD';
        const newDate = moment(new Date(date)).format(dateFormat);
        return newDate
    }

    useEffect(() => {
        const getUserCountry = async () => {
            try {
                const headers = {
                    "X-API-Key": process.env.REACT_APP_API_KEY
                  };
                  
                const res = await axios.get(
                    Endpoint.API_GET_USER_ROLE,
                    {
                        params: {
                            user_id: userEmail,
                        },
                        headers:headers
                    }
                )
                window.country = res.data.Country
                dispatch(Actions.getWeeklyComplianceData(userEmail, startweek, endweek, currentYear, window.country))
                dispatch(Actions.getUserWeeklyJIRAComplianceData(startweek, endweek, userEmail));
            } catch (err) {
                console.log(err)
            }
        }
        getUserCountry()
    }, [])

    useEffect(() => {
        dispatch(Actions.getWeeklyComplianceData(userEmail, startweek, endweek, currentYear, window.country))
        dispatch(Actions.getUserJIRAComplianceData(startweek, endweek, userEmail, true));
    }, [startweek, endweek])


    const columns = useMemo(() => (COLUMNS), [COLUMNS])
    const data = useMemo(() => {
        if (Object.keys(adminComplianceData).length > 0) {
            return complianceData;
        } else {
            return MOCK
        }
    }, [complianceData])

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = tableInstance

    return (
        <>
            {/* {(isDataLoading && isUserJIRAComplianceDataLoading) ? ( */}
            {(isDataLoading || isUserJIRAComplianceDataLoading || complianceData.length === 0) ? (
                <div className="kube__userselect-loading"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        zIndex: "100",
                        margin: "5rem auto auto"
                    }}
                >
                    <div className='dot-flashing'></div>
                </div>
            ) : (
                <>
                    {showWeekPicker && <WeekPicker handleWeekChange={handleWeekChange} setShowWeekPicker={setShowWeekPicker} />}
                    <div>
                        <div className='weekly-compliance-dates'>
                            {showWeekPickerButton && <button
                                className='week--change--button'
                                onClick={() => { setShowWeekPicker(true); }}
                            >
                                {`Week : ${startweek} to ${endweek}`}
                            </button>}
                        </div>
                        <table className='table' {...getTableProps()}>
                            <thead className='thead' >
                                {headerGroups.map((headerGroup) => (
                                    <tr className='trHead' {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th className='th' {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody className='tbody' {...getTableBodyProps()}>
                                {page.map((row, idx) => {
                                    prepareRow(row)
                                    return (
                                        <>
                                            <tr className='trBody' {...row.getRowProps()} key={idx}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td className='td' {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                    )
                                                }
                                                )
                                                }
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            )
            }
        </>
    )
}

export default WeeklyCompliance
