import * as Actions from "../actions";

const initialState = {
  LoggedInUser: {},
};

const LoggedInUserReducer = function (state = initialState, action) {
  switch (action.type) {
    case "LOGIN_USER_SUCCESS": {
      return {
        ...state,
        LoggedInUser : action.payload,
      };
    }
    case "LOGIN_USER_LOGOUT": {
        return {
          ...state,
          LoggedInUser :{},
        };
      }
    default: {
      return state;
    }
  }
};

export default LoggedInUserReducer;