import React, { useState } from 'react'
import styled from 'styled-components'
import '../../App.css'
import Link from '@material-ui/core/Link'
import FormGroup from "@material-ui/core/FormGroup"
import { useAuth } from '../../utils/auth'
import { RiAlertFill } from 'react-icons/ri'
import logo from '../../assets/logo-xl.png'

const Logo = styled.img`
    height: 2.6rem;
`

const ErrorSnackbar = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  margin-top: 1rem !important;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #EB5E55;
  color: #FDF0D5;
`
const ErrorText = styled.div`
  width: 90%;
  margin-left: 1rem;
  font-size: 0.7rem;
  text-align: initial;
`

const Login = (props) => {
  const [user, setUser] = useState('')
  const [error, setError] = useState(false)
  const auth = useAuth()
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [buttonColor, setButtonColor] = useState({
    background: '#fbd7cf',
    cursor: 'not-allowed'
  });

  const handleOrgChange = (event) => {
    event.preventDefault();
    if (event.target.value !== "") {
      setOrganization(true);
    }
  }

  const handleSubmitOrgButton = (event) => {
    event.preventDefault();

    const organizationExist = organization;     //get the actual org from the API call
    if (organizationExist) {
      setSubmitEnabled(true);
      setButtonColor({ background: "#f3795e", cursor: 'pointer' })
    } else {
      setButtonColor({ background: "#fbd7cf", cursor: 'not-allowed' })
    }

  }



  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const [state, setState] = React.useState({
    checkedB: true,
    checkedD: true
  });

  const handleError = () => {
    setError(true)
  }

  const handleLogin = (event) => {
    event.preventDefault();
    auth.login(user, values.password);

  };

  const handleCheckboxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="kube--login--wrapper util--container">
      <div className="kube--login--logo--container">
        <Logo src={logo} alt="logo" />
        <h3 style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '2rem',
          lineHeight: '5rem',
          color: '#333333',
        }}>Readyly</h3>
      </div>
      <div className="kube--login--organization">
        <input type="text" className="kube--login--inputs" placeholder='Organization' onChange={handleOrgChange} />
        <button style={{ cursor: 'pointer' }} className='kube--login--buttons' disabled={!organization} onClick={handleSubmitOrgButton} >Submit</button>
      </div>
      <div className="kube--login--divider--comp">
        <hr  style={{ border: "1px solid rgba(130, 130, 130, 0.1)"}} />
      </div>
      <div className="kube--login--credentials">
        <form id="login-form" noValidate autoComplete="off">
          {error ? <ErrorSnackbar>
            <RiAlertFill />
            <ErrorText>This is an error placeholder that will be replaced by an actual prompt once integrated with auth.js.</ErrorText>
          </ErrorSnackbar> : ''}
          <div style={
            {
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              alignItems: 'center'
            }
          }>
            <input disabled={!submitEnabled} type="email" className='kube--login--inputs' placeholder="E-mail" onChange={e => setUser(e.target.value)} />
            <input disabled={!submitEnabled} type="password" className='kube--login--inputs' placeholder="Password" value={values.password}
              onChange={handleChange('password')} />
          </div>
          <FormGroup row className='kube__signin-row3'>
            <Link href="/ForgotPassword">
              Forgot Password?
            </Link>
          </FormGroup>
        </form>
      </div>
      <div className="kube--login--loginbutton--container">
        <button style={buttonColor} className='kube--login--buttons' disabled={!organization} onClick={handleLogin} >Login</button>
      </div>
    </div>
  )
}

export default Login