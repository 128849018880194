import React from 'react'
import '../../App.css'
import { Link } from '@material-ui/core';
import Macinstructionimage1 from '../../assets/Macinstructionimage1.png'

const InstallationSteps = () => {
    

    const handleLoginBackButton = () => {
        window.history.back();
      }
    return (
        <div style={{marginLeft:'20px'}}>
            <img style={{marginTop:'20px'}} src="assets/back-button.png" alt="back" onClick={handleLoginBackButton} />
            <h2 style={{color:'#212121', marginTop:'20px'}}>Installation Steps for MAC M1</h2>
            
                
                <p style={{color:'#212121', marginTop:'25px'}} >1. Download the kube.zip file from the given link <Link href='/downloads'>Downloads</Link> 
                <br></br>
                <br></br>
                2. Unzip the downloaded zip file.
                <br></br>
                <br></br>
                3. Open system preferences, navigate to Security{'&'}Privacy-{'>'}Files and Folders.
                <br></br>
                <br></br>
                4. Now look for the Kube and see which folder it has access to, for reference see below image:
                <br></br>
                <img src={Macinstructionimage1} alt='instruction1' />
                <br></br>
                <br></br>
                5. **This step is required only if you are not able to see the Kube in Files and Folders, click on the lock icon at bottom left corner and unlock it then click on the + sign as shown in the above image and add Kube. 
                <br></br>
                <br></br>
                6. Now copy and paste the unziped Kube app file to the folder above, <b>now it's ready to run.</b>
                <br></br>
                <br></br>

                ~<b>For any further help and bug reporting contact on: <i>manpreet.singh@readyly.com</i></b>
                <br></br>
                <br></br>
                </p>


            
        </div>
    )
}

export default InstallationSteps