import React from 'react'
import LOGO from '../../../src/assets/logo-xl.png'
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { MdDownload, MdSettings, MdWorkspaces, MdLogout, MdArrowBack, MdGridView } from 'react-icons/md'
import { useAuth } from '../../utils/auth'
import { useSelector } from 'react-redux'
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { FaUsers } from 'react-icons/fa';
import { IoGrid, IoCalendarNumber } from 'react-icons/io5';
import { HiUser } from "react-icons/hi";

const Icon = styled.img`
  vertical-align: middle;
  width: 2.813rem;
  height: 2.813rem;
`

const Navbar = (props) => {
  const navigate = useNavigate()
  var CryptoJS = require("crypto-js");
  //Authentication things
  const auth = useAuth()
  const logInStatus = useSelector((state) => state.loginUser.LoggedInUser);

  const handleLogout = () => {
    auth.logout();
  }
  let admin;
  let manager;

  let key = localStorage.getItem('role')
  let bytes = CryptoJS.AES.decrypt(key, process.env.REACT_APP_SECRET)
  let v1 = bytes.toString(CryptoJS.enc.Utf8);
  let v2 = (JSON.parse(v1));
  if (v2 === 'Admin' || v2 === 'Superadmin') {
    admin = true;
  } else if (v2 === 'Manager') {
    manager = true;
  }
  else {
    admin = false;
  }
  //Date and time calculation for sending in dispatch action
  const newDate = new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('T')[0]
  var time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", second: '2-digit', hour12: false });

  return (
    <section className="display-row">
      <nav className="new--navbar">
        {/* SECTION 1  - HOME*/}
        <NavLink style={{ marginBottom: 'auto' }} to={`/LandingIcon/${logInStatus.attributes.email}`} ><IconButton><Icon src={LOGO} alt="kube-logo" /></IconButton></NavLink>

        {/* SECTION 2  - ITEMS*/}
        <Tooltip title="Previous Page" placement="right"><IconButton onClick={() => { navigate(-1) }} ><MdArrowBack /></IconButton></Tooltip>
        {admin || manager ? <Tooltip title="Users" placement="right"><IconButton as={Link} to={`home`}><FaUsers /></IconButton></Tooltip> : null}

        <Tooltip title="Grid View" placement="right"><IconButton as={Link} to={`/screenshotsgridview/${logInStatus.attributes.email}/${newDate}`} ><MdGridView /></IconButton></Tooltip>

        <Tooltip title="Leave and Compliance" placement="right"><IconButton as={Link} to={`/LandingIcon/${logInStatus.attributes.email}/leaveandcompliance`}><IoCalendarNumber /></IconButton></Tooltip>

        {admin ? (
          <div className="dropdown">
            <IconButton style={{ color: "#FF6C37", borderRadius: "10px", height: '2.5rem', width: '2.5rem', background: "#ECF0F3", boxShadow: '4px 4px 8px #D1D9E6, -4px -4px 8px #FFFFFF' }}><MdWorkspaces /></IconButton>
            <div className="dropdown-content">
              <NavLink to={`/accessmanagement/${logInStatus.attributes.email}/allusers`}>Users</NavLink>
              <NavLink to={`/accessmanagement/${logInStatus.attributes.email}/networktopography`}>Network</NavLink>
            </div>
          </div>
        ) : null}

        {!admin && <Tooltip title="Workspace" placement="right"><IconButton as={Link} to={`/accessmanagement/${logInStatus.attributes.email}`}><MdWorkspaces /></IconButton></Tooltip>}

        <Tooltip title="Profile" placement="right"><IconButton as={Link} to={`profile`}><HiUser /></IconButton></Tooltip>

        <div className="dropdown">
          <IconButton style={{ color: "#FF6C37", borderRadius: "10px", height: '2.5rem', width: '2.5rem', background: "#ECF0F3", boxShadow: '4px 4px 8px #D1D9E6, -4px -4px 8px #FFFFFF' }}><MdDownload /></IconButton>
          <div className="dropdown-content">
            <NavLink to={`downloads`}>Download Kube</NavLink>
            <NavLink to={`vpndownload`}>Download VPN</NavLink>
          </div>
        </div>

        {admin ? <Tooltip title="Settings" placement="right"><IconButton as={Link} to={`settings`}><MdSettings /></IconButton></Tooltip> : null}

        {/* SECTION 3  - LOGOUT*/}
        <Tooltip title="Logout" placement="right"><IconButton style={{ marginTop: 'auto', marginBottom: '.7rem' }} onClick={handleLogout}><MdLogout /></IconButton></Tooltip>

      </nav>
      <Outlet />

    </section>
  );
};

export default Navbar