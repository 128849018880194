import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import * as Actions from '../../../dashboard/store/actions'
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
} from "react-table"
import moment from 'moment';
import WeekPicker from '../../../dashboard/widgets/weekpicker'
import { Filter } from '../Filter'
import { CSVLink } from "react-csv";
import { ADMINHEADERS } from "../../../../shared/table_columns/AdminHeaders";
import DataTable from './workunitsmodal'
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton,Dialog } from '@mui/material';
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";


const MOCK = [{
    "user_id": "Desiri",
    "working_hours": 1,
    "Public_Time_Off": 1,
    "Personal_Time_Off": 1,
    "Effective_hours": 1,
    "Compliance": 1,
    "intensity": 1,
    "without_webcam": 1
}, {
    "user_id": "Katey",
    "working_hours": 2,
    "Public_Time_Off": 2,
    "Personal_Time_Off": 2,
    "Effective_hours": 2,
    "Compliance": 2,
    "intensity": 2,
    "without_webcam": 2
}]

export const COLUMNS = [
    ...ADMINHEADERS
]

const WeeklyUserCompliance = (props) => {
    let currentDate = moment();
    let weekStart = currentDate.clone().startOf('week');
    let weekEnd = currentDate.clone().endOf('week');

    const [startweek, setStartWeek] = useState(weekStart.format('YYYY-MM-DD'));
    const [endweek, setEndWeek] = useState(weekEnd.format('YYYY-MM-DD'));
    const [isvisible,setvisiblity] = useState(false)
    const [workunitdata,setworkunitsdata] = useState({})

    const [showWeekPicker, setShowWeekPicker] = useState(false)
    const [showWeekPickerButton, setShowWeekPickerButton] = useState(true)
    const currentYear = new Date().getFullYear().toString()


    const handleWeekChange = (startdateweek, enddateWeek) => {
        setStartWeek(startdateweek)
        setEndWeek(enddateWeek)

        setShowWeekPicker(false)
        setShowWeekPickerButton(true)
    }

    const dispatch = useDispatch()
    const { email: userEmail } = useParams()
    const [complianceData, setComplianceData] = useState([]);
    const adminComplianceData = useSelector((compliance) => compliance.complianceReducer.adminWeeklyComplianceData);
    const isDataLoading = useSelector((compliance) => compliance.complianceReducer.isAdminWeeklyComplianceDataLoading);

    const allUsersJIRAComplianceData = useSelector((compliance) => compliance.complianceReducer.allUsersWeeklyJIRAComplianceData);
    const isAllUsersJIRAComplianceDataLoading = useSelector((compliance) => compliance.complianceReducer.isAllUsersWeeklyJIRAComplianceDataLoading);

    useEffect(() => {
        let mapping;

        if (adminComplianceData.length > 0) {
            mapping = adminComplianceData?.map(element => {
                return {
                    ...element,
                    ...allUsersJIRAComplianceData[element.user_id]
                }
            });
            setComplianceData(mapping);
        }
    }, [adminComplianceData, allUsersJIRAComplianceData]);

    function changeDate(date) {
        const dateFormat = 'YYYY-MM-DD';
        const newDate = moment(new Date(date)).format(dateFormat);
        return newDate
    }

    useEffect(() => {
        dispatch(Actions.getAdminWeeklyComplianceData(startweek, endweek, currentYear, props.loggedInUserEmail));
        dispatch(Actions.getAllUsersWeeklyJIRAComplianceData(startweek, endweek));
    }, [startweek, endweek])

    useEffect(() => {
        if (Object.keys(adminComplianceData).length > 0) {
            setComplianceData(adminComplianceData)
        }
    }, [Object.keys(adminComplianceData).length])

    const columns = useMemo(() => (COLUMNS), [COLUMNS])
    const data = useMemo(() => {
        if (Object.keys(adminComplianceData).length > 0) {
            return complianceData;
        } else {
            return MOCK;
        }
    }, [complianceData])

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state,
        setGlobalFilter,
        prepareRow,
    } = tableInstance

    const { pageIndex, globalFilter, pageSize } = state

    return (
        <div className="admin--leavemanagement--wrapper">
            {(isDataLoading || isAllUsersJIRAComplianceDataLoading || complianceData.length === 0) ? (
                <div className="kube--util--center--div">
                    <div className='dot-flashing'>
                    </div>
                </div>
            ) : (
                < div className="admin--leavemanagement--content">
                    <div className="admin--leavemanagement--top--section">
                        {showWeekPicker && <WeekPicker handleWeekChange={handleWeekChange} setShowWeekPicker={setShowWeekPicker} />}
                        {showWeekPickerButton && <button
                            className='week--change--button'
                            onClick={() => { setShowWeekPicker(true); }}
                        >
                            {`Week : ${startweek} to ${endweek}`}
                        </button>}
                        <Filter filter={globalFilter} setFilter={setGlobalFilter} />
                        <div className="apply-leave-dialog">
                            <CSVLink data={complianceData} filename={"Weekly_compliance"}>
                                <button className="export--csv--button">
                                    Export CSV
                                </button>
                            </CSVLink>
                        </div>
                    </div>

                    <div className="admin--leavemanagement--hero--section">
                        <table className='table' {...getTableProps()}>
                            <thead className='thead'>
                                {headerGroups.map((headerGroup) => (
                                    <tr className='trHead' {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th className='th' {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody className='tbody' {...getTableBodyProps()}>
                                {page.map((row, idx) => {
                                    prepareRow(row)
                                    return (
                                        <>
                                            <tr {...row.getRowProps()} key={idx}>
                                                {row.cells.map((cell) => {
                                                    if (cell.column.Header === "Work Units") {
                                                        return (
                                                          <>
                                                            <td
                                                              {...cell.getCellProps()}
                                                            >
                                                              <div style={{"display":"grid","grid-template-columns": "repeat(2,50%)","text-align":"center","justify-items":"space-between"}}>
                                                              <span style={{"width":"10px","margin":"0px","padding":"10px","textAlign":"center",}}>{cell.value}</span>
                                                              <Tooltip title="Jira Tickets" arrow>
                                                              <IconButton color="info" aria-label="info"  onClick={()=>{
                                                                setvisiblity(true)
                                                                setworkunitsdata(complianceData[cell.row.id])
                                                              }}><InfoIcon /></IconButton>
                                                              </Tooltip>
                                                            </div> 
                                                               
                                                            </td>
                                                          </>
                                                        )
                                                      }
                                                    else{
                                                        return (
                                                            <td className='td' {...cell.getCellProps({
                                                                style: {
                                                                    minWidth: cell.column.minWidth,
                                                                    width: cell.column.width,
                                                                },
                                                            })}>{cell.render("Cell")}</td>
                                                        )
                                                    }
                                                }
                                                )
                                                }
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Dialog open = {isvisible} fullWidth ={true} maxWidth="lg" onClose = {()=>setvisiblity(false)}>
                    <DialogTitle>
                        <IconButton  aria-label="close" onClick={()=>setvisiblity(false)} style = {{"position":"relative","left":"90%"}}>
                        <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                        <DataTable  Tasks ={workunitdata.Details}/>
                    </Dialog>

                    <div className="admin--leavemanagement--bottom--section">
                        <div className="kube__userselect-pagination">
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                Prev
                            </button>
                            <span className="kube__userselect-pagination-details">
                                <span>
                                    Page{" "}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{" "}
                                </span>
                            </span>
                            <select value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}>
                                {[5, 10, 15, 20, 25].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                Next
                            </button>
                        </div>
                    </div>

                </div>
            )}
        </div>
    )
}

export default WeeklyUserCompliance