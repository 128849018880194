import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate} from 'react-router-dom'
import OverlayCard from './adminWorkspace/OverlayCard'
import UserDetails from './adminWorkspace/AllUsersDetails'

const SideNavbar = (props) => {
  const navigate = useNavigate()
  const [active, setActive] = useState("Workspace");
  const logInStatus = useSelector((state) => state.loginUser.LoggedInUser);
  const [showOverlayCard, setShowOverlayCard] = useState(false)
  const handleUser = () => {
    setActive("Users");
  }
  const handleVpn = () => {
    setActive("VPN");
    setShowOverlayCard(true);
  }


  return (
    <div className='sidenavbar__main'>
      <div className='Sidenavbar__sideBar'>
        <div className='sidenavbar__sideBar__main'>Access Management</div>
        <ul style={{ listStyle: 'none' }}>
          <li style={{ paddingLeft: '20px', fontWeight: '600', marginTop: '1rem', fontSize: '1.20rem', paddingRight: '5px' }}> <div>Workspace</div></li>
          <ul style={{ listStyle: 'none' }}>
            <li style={{ paddingLeft: '50px' }}><button onClick={handleUser} className='sidenavbar__sidebar__button'>Users</button></li>
          </ul>
          <li style={{ paddingLeft: '20px' }}><button className='sidenavbar__sidebar__button' onClick={handleVpn} style={{ fontWeight: '600', fontSize: '1.20rem' }} >Setup VPN</button></li>
          <li style={{ paddingLeft: '20px' }}><button className='sidenavbar__sidebar__button' onClick={() => { navigate("networktopography")} } style={{ fontWeight: '600', fontSize: '1.20rem' }} >  Network</button></li>
        </ul>
      </div>
      <OverlayCard isVisible={showOverlayCard} setShowOverlayCard={setShowOverlayCard} />
      <div>
        {active === 'Users' && <UserDetails />}
      </div>
    </div>

  )
}

export default SideNavbar