import React from 'react';

export default () => {
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType)
        event.dataTransfer.effectAllowed = 'move'
    };

    return (
        <aside>
            <div className="description"></div>
            <div className="desc-header">
                <h2>ELEMENTS</h2>
            </div>
            <div className="dndnode input" onDragStart={(event) => onDragStart(event, 'VPC')} draggable>
                VPC
            </div>
            <div className="dndnode VPN" onDragStart={(event) => onDragStart(event, 'VPN')} draggable>
                VPN
            </div>
            <div className="dndnode SecurityGroup" onDragStart={(event) => onDragStart(event, 'Security Group')} draggable>
                Security Group
            </div>
            <div className="dndnode Subnet" onDragStart={(event) => onDragStart(event, 'Subnet')} draggable>
                Subnet
            </div>
            <div className="dndnode Instance" onDragStart={(event) => onDragStart(event, 'Instance')} draggable>
                Instance
            </div>
            <div className="dndnode Router" onDragStart={(event) => onDragStart(event, 'Router')} draggable>
                Router
            </div>
        </aside>
    );
};