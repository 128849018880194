import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { MdDoneOutline, MdCancel, MdRefresh, MdCached, MdOutlineDelete } from 'react-icons/md'
import styled from 'styled-components'
import { Button } from '../Bar'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import * as Actions from '../../dashboard/store/actions'
import { toggleSnackbar } from '../../dashboard/store/actions/kube.actions'

const SmallButton = styled(Button)`
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  margin: 0 2rem;
  font-size: 2rem;
  &:hover {
    background: #E24623;
    box-shadow: inset 0px 8px 20px rgba(0, 0, 0, 0.25);
  }
`

const Container = styled.div`
    display : flex;
    justify-content: center;
`

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

const DeleteWorkspaceModal = (props) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const [disable, setDisable] = useState(true)
    const [submitButton, setSubmitButton] = useState({
        bgColor: "#FFF",
        color: "#F3795E"
    })
    const [open, setOpen] = useState(false)

    const modalHandleOpen = () => setOpen(true)
    const modalHandleClose = () => setOpen(false)

    const handleYes = () => {
        const currentDirectoryId = props.cell.row.original.workspace.Workspaces[0].DirectoryId
        const currentUserEmail = location.state.userEmail
        const currentWorkspaceId = props.cell.row.original.workspace.Workspaces[0].WorkspaceId

        dispatch(Actions.deleteWorkspace(currentDirectoryId, currentUserEmail, currentWorkspaceId))
        dispatch(toggleSnackbar({
            status: true,
            message: `Deleting the workspace ${currentWorkspaceId}`,
            severity: "warning"
        }))
        dispatch(Actions.getUserWorkspaceDetails(currentUserEmail))
        setOpen(false)
    }

    const handleNo = () => {
        setOpen(false)
        setDisable(true)
        setSubmitButton({
            ...submitButton,
            bgColor: "#FFF",
            color: "#F3795E"
        })
    }

    const handleDeleteInput = (event) => {
        if (event.target.value === "DELETE") {
            setDisable(false)
            setSubmitButton({
                ...submitButton,
                bgColor: "#F3795E",
                color: "#FFF",
                outline: "none"
            })
        }
    }

    return (
        <Container>
            <div className="istile" onClick={modalHandleOpen}>
                <SmallButton style={{ width: '2.5rem', height: '2.5rem' }}>
                    <MdOutlineDelete />
                </SmallButton>
            </div>

            <Modal
                open={open}
                onClose={modalHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2><strong style={{ color: 'red' }}>Warning</strong></h2>
                    <h3>Terminating a workspace is a permanent action and cannot be undone.</h3>
                    <h3>Type <strong>DELETE</strong>  to delete this workspace permanently</h3>
                    <input onChange={(event) => handleDeleteInput(event)} type="text" placeholder="DELETE"></input>
                    <div className="buttons-container">
                        <button style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: 'center',
                            width: '2rem',
                            height: '2rem',
                            backgroundColor: submitButton.bgColor,
                            color: submitButton.color,
                            borderRadius: "50%",
                            outline: "none"
                        }} disabled={disable} onClick={handleYes} ><MdDoneOutline /></button>
                        <Button onClick={handleNo}><MdCancel /></Button>
                    </div>
                </Box>
            </Modal>
        </Container>
    )
}

export default DeleteWorkspaceModal