import * as Actions from '../actions'

const initialState = {
    weeklyComplianceData: {},
    isWeeklyComplianceDataLoading: false,
    monthlyComplianceData: {},
    isMonthlyComplianceDataLoading: false,
    adminWeeklyComplianceData: {},
    isAdminWeeklyComplianceDataLoading: false,
    adminMonthlyComplianceData: {},
    isAdminMonthlyComplianceDataLoading: false,
    userJIRAComplianceData: {},
    isUserJIRAComplianceDataLoading: false,
    userWeeklyJIRAComplianceData: {},
    isUserWeeklyJIRAComplianceDataLoading: false,
    allUsersJIRAComplianceData: {},
    isAllUsersJIRAComplianceDataLoading: false,
    allUsersWeeklyJIRAComplianceData: {},
    isAllUsersWeeklyJIRAComplianceDataLoading: false,
}

const complianceReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_WEEKLY_COMPLIANCE_DATA:
            {
                return {
                    ...state,
                    weeklyComplianceData: action.payload
                }
            }
        case Actions.IS_WEEKLY_COMPLIANCE_DATA_LOADING:
            {
                return {
                    ...state,
                    isWeeklyComplianceDataLoading: action.payload
                }
            }
        case Actions.GET_MONTHLY_COMPLIANCE_DATA:
            {
                return {
                    ...state,
                    monthlyComplianceData: action.payload
                }
            }
        case Actions.IS_MONTHLY_COMPLIANCE_DATA_LOADING:
            {
                return {
                    ...state,
                    isMonthlyComplianceDataLoading: action.payload
                }
            }
        case Actions.GET_ADMIN_WEEKLY_COMPLIANCE_DATA:
            {
                return {
                    ...state,
                    adminWeeklyComplianceData: action.payload
                }
            }
        case Actions.IS_ADMIN_WEEKLY_COMPLIANCE_DATA_LOADING:
            {
                return {
                    ...state,
                    isAdminWeeklyComplianceDataLoading: action.payload
                }
            }
        case Actions.GET_ADMIN_MONTHLY_COMPLIANCE_DATA:
            {
                return {
                    ...state,
                    adminMonthlyComplianceData: action.payload
                }
            }
        case Actions.IS_ADMIN_MONTHLY_COMPLIANCE_DATA_LOADING:
            {
                return {
                    ...state,
                    isAdminMonthlyComplianceDataLoading: action.payload
                }
            }
        case Actions.GET_USER_WEEKLY_JIRA_COMPLIANCE_DATA:
            {
                return {
                    ...state,
                    userWeeklyJIRAComplianceData: action.payload
                }
            }
        case Actions.IS_USER_WEEKLY_JIRA_COMPLIANCE_DATA_LOADING:
            {
                return {
                    ...state,
                    isUserWeeklyJIRAComplianceDataLoading: action.payload
                }
            }
        case Actions.GET_USER_JIRA_COMPLIANCE_DATA:
            {
                return {
                    ...state,
                    userJIRAComplianceData: action.payload
                }
            }
        case Actions.IS_USER_JIRA_COMPLIANCE_DATA_LOADING:
            {
                return {
                    ...state,
                    isUserJIRAComplianceDataLoading: action.payload
                }
            }
        case Actions.GET_ALL_USERS_WEEKLY_JIRA_COMPLIANCE_DATA:
            {
                return {
                    ...state,
                    allUsersWeeklyJIRAComplianceData: action.payload
                }
            }
        case Actions.IS_ALL_USERS_WEEKLY_JIRA_COMPLIANCE_DATA_LOADING:
            {
                return {
                    ...state,
                    isAllUsersWeeklyJIRAComplianceDataLoading: action.payload
                }
            }
        case Actions.GET_ALL_USERS_JIRA_COMPLIANCE_DATA:
            {
                return {
                    ...state,
                    allUsersJIRAComplianceData: action.payload
                }
            }
        case Actions.IS_ALL_USERS_JIRA_COMPLIANCE_DATA_LOADING:
            {
                return {
                    ...state,
                    isAllUsersJIRAComplianceDataLoading: action.payload
                }
            }
        default:
            {
                return state
            }
    }
}

export default complianceReducer;