import React, { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DatePicker from "react-datepicker"
import { toggleSnackbar } from '../../../components/dashboard/store/actions'
import { useDispatch } from 'react-redux'


export default function AddHolidayDialogBox(props) {
    const dispatch = useDispatch()
    const [occasion, setOccasion] = useState("")
    const [currentDate, setCurrentDate] = React.useState(new Date().getTime() - 518400000)
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleDateChange = (date) => {
        setCurrentDate(date)
    }

    const handleOccasionNameChange = (event) => {
        setOccasion(event.target.value)
    }

    const handleSubmit = () => {
        if(occasion.length === 0){
            dispatch(toggleSnackbar({
                status: true,
                message: "Occasion value cannot be empty",
                severity: "error"
            }))
            handleClose()
            return
        }
        
        let processDate = new Date(currentDate)
        let currYear = processDate.getFullYear()
        let currMonth = parseInt(processDate.getMonth()) + 1

        if (Math.floor(currMonth / 10) === 0) {
            currMonth = '0' + currMonth
        }

        let currDate = processDate.getDate()

        if (Math.floor(currDate / 10) === 0) {
            currDate = '0' + currDate
        }

        const addDate = currYear + "-" + currMonth + "-" + currDate + "-" + occasion
        props.handleAddHoliday(addDate)
        setOccasion("")
        handleClose()
    }

    return (
        <div>
            <div >
                <button className="admin-add-holiday-button util--btn" onClick={handleClickOpen}>
                    Add Holiday
                </button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <div className="admin-add-holiday-dialog">
                    <DialogTitle style={{ color: '#4B566C' }}>Add new holiday</DialogTitle>
                    <DialogContent>
                        <DatePicker fullWidth selected={currentDate} onChange={handleDateChange} />
                        <TextField
                            margin="dense"
                            id="name"
                            label="Enter Occasion Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleOccasionNameChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{ color: '#f3795e' }} onClick={handleClose}>Cancel</Button>
                        <Button sx={{ color: '#f3795e' }} onClick={handleSubmit}>Add</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}
