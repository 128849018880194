import React, { useEffect } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import HolidayTable from './HolidayTable'
import { useState } from 'react'
import CryptoJS from 'crypto-js'
import { Auth } from "aws-amplify";
import { useSelector } from 'react-redux'
import UserLeaveManagement from './user/UserLeaveManagement'
import AdminLeaveManagement from './admin/AdminLeaveManagement'
import WeeklyUsersCompliance from './admin/usercompliancetable/WeeklyUsersCompliance'
import MonthlyUsersCompliance from './admin/usercompliancetable/MonthlyUsersCompliance'
import { styled } from '@mui/material/styles';

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#F3795E',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 40,
        width: '100%',
        backgroundColor: 'rgb(243, 121, 94)',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: '#f5947e',
        // color: '#f8af9e',
        // color: '#f3795E',
        '&.Mui-selected': {
            color: 'rgb(243, 121, 94)',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgb(243, 121, 94)',
        },
    }),
);

export default function BasicTabs() {
    const [value, setValue] = React.useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const logInStatus = useSelector((state) => state.loginUser.LoggedInUser);
    const loggedInUserEmail = logInStatus.attributes.email;
    const [admin, setAdmin] = useState(false);

    React.useEffect(() => {
        const authenticate = async () => {
            try {
                const userDetails = await Auth.currentAuthenticatedUser()
                let key = localStorage.getItem('role')
                let bytes = CryptoJS.AES.decrypt(key, process.env.REACT_APP_SECRET)
                let v1 = bytes.toString(CryptoJS.enc.Utf8)
                let v2 = (JSON.parse(v1))
                if (v2 === 'Admin' || v2 === 'Superadmin' || v2 === 'Manager') {
                    setAdmin(true)
                }
            } catch (err) {
                console.log(err)
            }
        }
        authenticate()
    }, [])

    const styles = {
        // color: '#F3795E',
        fontFamily: 'Inter',
        fontWeight: 700,
        background: '#ECF0F3',
        borderRadius: '10px',
        border: 'none',
    }

    return (
        <Box sx={{ width: '100%', background: '#ECF0F3', boxShadow: '0px 1px 3px rgb(0 0 0/ 0%)', borderRadius: '10px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    centered
                >
                    <StyledTab style={styles} label="PUBLIC HOLIDAYS" {...a11yProps(0)} />
                    <StyledTab style={styles} label="LEAVE MANAGEMENT" {...a11yProps(1)} />
                    {admin && <StyledTab style={styles} label="WEEKLY COMPLIANCE" {...a11yProps(2)} />}
                    {admin && <StyledTab style={styles} label="MONTHLY COMPLIANCE" {...a11yProps(3)} />}
                </StyledTabs>
                
            </Box>
            <TabPanel value={value} index={0}>
                <HolidayTable />
            </TabPanel>
            <TabPanel value={value} index={1}>
                {admin ? <AdminLeaveManagement loggedInUserEmail={loggedInUserEmail} /> : <UserLeaveManagement />}
            </TabPanel>
            {admin && <TabPanel value={value} index={2}>
                <WeeklyUsersCompliance loggedInUserEmail={loggedInUserEmail} />
            </TabPanel>}
            {admin && <TabPanel value={value} index={3}>
                <MonthlyUsersCompliance loggedInUserEmail={loggedInUserEmail} />
            </TabPanel>}
        </Box>
    )
}
