import React, { useState, useMemo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as Actions from "../dashboard/store/actions"
import reducer from "../dashboard/store/reducers"
import withReducer from "../dashboard/store/withReducer"
import styled from 'styled-components'
import { Button } from '../workspace/Bar'
import axios from "axios";
import * as Endpoint from '../../Endpoint'
import "../../App.css"
import { MdAccessTime } from 'react-icons/md'
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table"
import { Dateslider } from ".."
import "../table.css"
import { useNavigate, useParams } from "react-router-dom"
import ImageThumbnail from "./ImageThumbnail"
import DurationTimeOverlayCard from './widgets/DurationTimeOverlayCard'
import { toggleSnackbar } from "../dashboard/store/actions"
import CryptoJS from 'crypto-js'
import { Auth } from "aws-amplify"
import { IconButton } from '@material-ui/core'

const findBgCol = (total_intensity) => {
  switch (total_intensity) {
    case 0:
      // return "#E3EECA"
      return "#cbe3aa"
    case 1:
      // return "#C5E0A0"
      return "#b1d786"
    case 2:
      return "#A8D279"
    case 3:
      return "#D5D170"
    case 4:
      return "#AAB952"
    case 5:
      return "#7C9D39"
    case 6:
      return "#528124"
    default:
      return "#2D6514"
  }
}

const SmallButton = styled(Button)`
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  margin: 0 2rem;
  font-size: 2rem;
  &:hover {
    background: #E24623;
    box-shadow: inset 0px 8px 20px rgba(0, 0, 0, 0.25);
  }
`
const Selectbutton = styled.div`
  border: 1px solid red;
  background-color: #f3795e;
  padding: 5px;
  color: #fff;
  &:hover {
    cursor:pointer;
    background: #E24623;
    box-shadow: inset 0px 8px 20px rgba(0, 0, 0, 0.25);
  }
`

const DurationTimeTableContainer = styled.div`
  display : flex;
  justify-content : flex-end;
`

function findMouseIntLvl(mouseClicks) {
  let upperLimit = 2500
  return mouseClicks / upperLimit
}

function findkeyIntLvl(keyStrokes) {
  let upperLimit = 250
  return keyStrokes / upperLimit
}

export const COLUMNS = [
  {
    Header: "Hours",
    accessor: "hour",
  },
  {
    Header: "05",
    columns: [
      {
        Header: "SS",
        accessor: "1",
      },
      {
        Header: "WS",
        accessor: "2",
      },
    ],
  },
  {
    Header: "10",
    columns: [
      {
        Header: "SS",
        accessor: "3",
      },
      {
        Header: "WS",
        accessor: "4",
      },
    ],
  },
  {
    Header: "15",
    columns: [
      {
        Header: "SS",
        accessor: "5",
      },
      {
        Header: "WS",
        accessor: "6",
      },
    ],
  },
  {
    Header: "20",
    columns: [
      {
        Header: "SS",
        accessor: "7",
      },
      {
        Header: "WS",
        accessor: "8",
      },
    ],
  },
  {
    Header: "25",
    columns: [
      {
        Header: "SS",
        accessor: "9",
      },
      {
        Header: "WS",
        accessor: "10",
      },
    ],
  },
  {
    Header: "30",
    columns: [
      {
        Header: "SS",
        accessor: "11",
      },
      {
        Header: "WS",
        accessor: "12",
      },
    ],
  },
  {
    Header: "35",
    columns: [
      {
        Header: "SS",
        accessor: "13",
      },
      {
        Header: "WS",
        accessor: "14",
      },
    ],
  },
  {
    Header: "40",
    columns: [
      {
        Header: "SS",
        accessor: "15",
      },
      {
        Header: "WS",
        accessor: "16",
      },
    ],
  },
  {
    Header: "45",
    columns: [
      {
        Header: "SS",
        accessor: "17",
      },
      {
        Header: "WS",
        accessor: "18",
      },
    ],
  },
  {
    Header: "50",
    columns: [
      {
        Header: "SS",
        accessor: "19",
      },
      {
        Header: "WS",
        accessor: "20",
      },
    ],
  },
  {
    Header: "55",
    columns: [
      {
        Header: "SS",
        accessor: "21",
      },
      {
        Header: "WS",
        accessor: "22",
      },
    ],
  },
  {
    Header: "60",
    columns: [
      {
        Header: "SS",
        accessor: "23",
      },
      {
        Header: "WS",
        accessor: "24",
      },
    ],
  },
]

const ScreenshotsGridView = (props) => {
  const navigate = useNavigate()
  let today = new Date()
  today.setDate(today.getDate())
  let dd = String(today.getDate()).padStart(2, "0")
  let mm = String(today.getMonth() + 1).padStart(2, "0")
  let yyyy = today.getFullYear()
  today = yyyy + "-" + mm + "-" + dd
  const { email, date: todaysDate } = useParams()
  const dispatch = useDispatch()
  const [testData, setTestData] = useState([])
  const [showOverlayCard, setShowOverlayCard] = useState(false)
  const [admin, setAdmin] = useState(false)
  const [toggled, setToggled] = useState(false)

  useEffect(() => {
    const authenticate = async () => {
      try {
        const userDetails = await Auth.currentAuthenticatedUser()
        let key = localStorage.getItem('role')
        let bytes = CryptoJS.AES.decrypt(key, process.env.REACT_APP_SECRET)
        let v1 = bytes.toString(CryptoJS.enc.Utf8);
        let v2 = (JSON.parse(v1))
        if (v2 === 'Admin' || v2 === 'Superadmin' || v2 === 'Manager') {
          setAdmin(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
    authenticate()
  }, [])

  const screenshotsData = useSelector(
    (screenshotsgridview) => screenshotsgridview.screenshotsdata.data
  )
  const [selectedDate, setGridSelectedDate] = useState(today)
  const images = {
    myImage: require("../../assets/Image_deleted_logo.png"),
  }

  useEffect(() => {
    dispatch(Actions.getScreenshotsData(selectedDate, email))
  }, [email, selectedDate, dispatch, toggled])

  useEffect(() => {
    try {
      if (screenshotsData.length >= 0) {
        processData(screenshotsData)
      }
    } catch (err) {
      console.log(err)
    }
  }, [screenshotsData])

  const handleDateChange = (date) => {
    setGridSelectedDate(date)
    navigate("/screenshotsgridview/" + email + '/' + date)
  }

  function handleCellClick(cell, passedBgCol, active_app) {

    let t = cell.column.parent.Header
    if (t === "60") {
      t = t - 1
    }

    const time = cell.row.id + ":" + t + ":00"

    navigate("/dashboard/" + email + "/" + selectedDate + "/" + time, {
      state: passedBgCol,
    })
  }

  function processData(screenshotsData) {
    const final_data = []

    for (var hour = 0; hour < 24; hour++) {
      const processed_data = {}
      processed_data["hour"] = hour
      processed_data["WS"] = false
      processed_data["SS"] = false
      for (var slot = 1; slot < 25; slot++) {
        for (var i = 0; i < screenshotsData.length; i++) {
          var part = screenshotsData[i]["record_time_obj"]["slot"]
          if (
            screenshotsData[i]["record_time_obj"]["hour"] == hour &&
            2 * part === slot
          ) {
            var res = {}

            let mouseInt = findMouseIntLvl(
              parseInt(screenshotsData[i]["mouse_clicks"])
            )
            let keysInt = findkeyIntLvl(
              parseInt(screenshotsData[i]["keystrokes"])
            )

            let mean = Math.round(((mouseInt + keysInt) / 2) * 10)
            let intLevel = findBgCol(mean)

            let isInvalidated = screenshotsData[i]["Invalid_status"]
            let finalBgCol = (isInvalidated === "True") ? "#FF3131" : intLevel

            if (screenshotsData[i]["cam"] === "NA") {
              processed_data["WS"] = true
              res = {
                screenshot: images.myImage,
                bgCol: finalBgCol,
                app_url: screenshotsData[i]["active_app"]
              }
              processed_data[2 * part] = res
            }
            if (screenshotsData[i]["screen"] === "NA") {
              processed_data["SS"] = true
              res = {
                screenshot: images.myImage,
                bgCol: finalBgCol,
                app_url: screenshotsData[i]["active_app"]
              }
              processed_data[2 * part - 1] = res
            }

            if (screenshotsData[i]["cam"] !== "Null" && screenshotsData[i]["cam"] !== "NA") {
              processed_data["WS"] = true
              res = {
                screenshot: screenshotsData[i]["camshot_url"],
                bgCol: finalBgCol,
                app_url: screenshotsData[i]["active_app"]
              }
              processed_data[2 * part] = res
            }
            if (screenshotsData[i]["screen"] !== "Null" && screenshotsData[i]["screen"] !== "NA") {
              processed_data["SS"] = true
              res = {
                screenshot: screenshotsData[i]["screenshot_url"],
                bgCol: finalBgCol,
                app_url: screenshotsData[i]["active_app"]
              }
              processed_data[2 * part - 1] = res
            }
            break
          } else {
            processed_data[slot] = null
          }
        }
      }
      final_data[hour] = processed_data
    }

    setTestData(final_data)
  }

  const columns = useMemo(() => COLUMNS, [])
  const data = useMemo(() => testData, [testData])

  async function toggleSelectedRowsStatus(selectedRowIds, invalidationStatus) {
    const invalidateHours = Object.keys(selectedRowIds)
    if (invalidateHours.length === 0) {
      dispatch(toggleSnackbar({
        status: true,
        message: invalidationStatus === "True" ? "Please select slots to Invalidate" : "Please select slots to Revalidate",
        severity: "error",
      }))
      return
    }
    try {
      const postData = {
        "arr": invalidateHours,
        "user_id": email,
        "date": todaysDate,
        "invalid_status": invalidationStatus
      }
      const headers = {
        "X-API-Key": process.env.REACT_APP_API_KEY
      };
      

      const response = await axios.post(Endpoint.API_TOGGLE_SCREEN_SHOT_ROW_STATUS, postData,{
        headers: headers
      })
      if (response.data === "succesfully updated") {
        dispatch(toggleSnackbar({
          status: true,
          message: invalidationStatus === "True" ? "Invalidating selected slots..." : "Revalidating selected slots...",
          severity: "success",
        }))
        setToggled(toggled => !toggled)
      }
    } catch (err) {
      dispatch(toggleSnackbar({
        status: true,
        message: "Something went wrong...Try again later",
        severity: "error",
      }))
    }
  }


  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // Header: "Select",
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: { selectedRowIds } } =
    tableInstance

  return (
    <div className="gridview--top--level--wrapper">
      <DurationTimeOverlayCard currUserEmail={email} isVisible={showOverlayCard} setShowOverlayCard={setShowOverlayCard} />

      <div className="screenshot">
        <div className="gridview--left--section">
          <Dateslider handleDateChange={handleDateChange} />
          <div className="gridview--left--section--buttons">
            {
              admin &&
              <>
                <button
                  className="gridview--validation--button"
                  onClick={() => toggleSelectedRowsStatus(selectedRowIds, "True")}
                >
                  Invalidate: {Object.keys(selectedRowIds).length}
                </button>
                <button
                  className="gridview--validation--button"
                  onClick={() => toggleSelectedRowsStatus(selectedRowIds, "False")}
                >
                  Revalidate: {Object.keys(selectedRowIds).length}
                </button>
              </>
            }
            <button
              className="gridview--validation--button"
              onClick={() => setShowOverlayCard(true)}
            >
              Session Details
            </button>
          </div>
        </div>

        <div className="gridview--table">
          <table className='table' style={{ marginBottom: "50px" }} {...getTableProps()}>
            <thead className='thead'>
              {headerGroups.map((headerGroup) => (
                <tr className='trHead' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className='th'
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead >
            <tbody className='tbody' {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr className='trBody' {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (
                        cell.column.Header !== "Hours" &&
                        cell.value !== null &&
                        cell.value !== undefined
                      ) {
                        if (cell.column.Header === "SS") {
                          return (
                            <td
                              onClick={() =>
                                handleCellClick(cell, cell.value.bgCol, cell.value.app_url)
                              }
                              style={{
                                backgroundColor: cell.value.bgCol,
                                color: "white",
                              }}
                              {...cell.getCellProps()}
                            >
                              {
                                <ImageThumbnail
                                  src={cell.value.screenshot}
                                  style={{ width: 36, height: 39 }}
                                  app_url={cell.value.app_url}
                                />
                              }
                            </td>
                          )
                        } else {
                          return (
                            <td
                              onClick={() =>
                                handleCellClick(cell, cell.value.bgCol)
                              }
                              style={{
                                backgroundColor: cell.value.bgCol,
                                color: "white",
                              }}
                              {...cell.getCellProps()}
                            >
                              {
                                <ImageThumbnail
                                  src={cell.value.screenshot}
                                  style={{
                                    width: 36,
                                    height: 39,
                                  }}
                                  app_url={cell.value.app_url}
                                />
                              }
                            </td>
                          )
                        }
                      } else {
                        return (
                          <td
                            className='td'
                            onClick={() => handleCellClick(cell)}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      }
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
}

export default withReducer("screenshotsgridview", reducer)(ScreenshotsGridView)
