import React, { createContext, useContext, useEffect } from "react";
import { toggleSnackbar } from '../components/dashboard/store/actions/kube.actions'
import Amplify, { Auth } from "aws-amplify";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import awsmobile from "../aws-exports.js";
import { Params, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Endpoint from "../../src/Endpoint";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/axios.js";
import { useDispatch } from "react-redux";
import { CognitoUser } from "amazon-cognito-identity-js";
import ErrorSnackbar from "../shared/ErrorSnackbar.js";
Amplify.configure(awsmobile);


toast.configure();
const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    var cognitoUser;
    var CryptoJS = require("crypto-js")
    var poolData = {
        UserPoolId: awsmobile.aws_user_pools_id,
        ClientId: awsmobile.aws_user_pools_web_client_id,
    };
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const login = (username, password) => {
        var authenticationData = {
            Username: username,
            Password: password,
        };
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
        );

        var userData = {
            Username: username,
            Pool: userPool,
        };
        cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: async function (result) {
                //var accessToken = result.getAccessToken().getJwtToken();
                //var idToken = result.idToken.jwtToken;
                navigate("/screenshotsgridview/" + username);
                const headers = {
                    "X-API-Key": process.env.REACT_APP_API_KEY
                  };
                const res = await axios.get(
                    Endpoint.API_GET_USER_ROLE,
                    {
                        params: {
                            user_id: username,
                        },
                        headers: headers
                    }
                );
                if (res.status === 200) {
                    let role = JSON.stringify(res.data.role);
                    let encrypted = CryptoJS.AES.encrypt(role, process.env.REACT_APP_SECRET).toString();
                    localStorage.setItem("role", encrypted);
                }
                else {
                    console.warn(res.data);
                }
                const user = await Auth.currentAuthenticatedUser();
                // console.log("USer", user);
                if (user) {
                    dispatch({ type: "LOGIN_USER_SUCCESS", payload: user });
                }
                // window.location.reload(false);
            },
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                const newPassword = prompt("New Password Required");
                delete userAttributes.email_verified;
                delete userAttributes.email;
                //this delete userAttributes.email is not used in older version of api's,present api's include this delete userAttributes.email to set permanent password.
                cognitoUser.completeNewPasswordChallenge(
                    newPassword,
                    userAttributes,
                    this
                );
            },

            onFailure: function (err) {
                dispatch(toggleSnackbar({
                    status: true,
                    message: err.message,
                    severity: "error"
                }))
                // alert(err);
                navigate("/login");
            },
        });
    };
    const sendCode = (username) => {
        Auth.forgotPassword(username)
            .then((data) => {
                dispatch(toggleSnackbar({
                    status: true,
                    message: "Code sent successfully",
                    severity: "success"
                }))
                // alert("Code sent successfully");
            })
            .catch((err) => {
                dispatch(toggleSnackbar({
                    message: err.message,
                    status: true,
                    severity: "error",
                }))
                // alert(err.message);
            });
    };

    const changePassword = (oldpassword, newpassword) => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                return Auth.changePassword(user, oldpassword, newpassword);
            })
            .then((data) => {
                toast.success("Password changed successfuly", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const validatePassword = (username, code, new_password) => {
        Auth.forgotPasswordSubmit(username, code, new_password)
            .then((data) => {
                navigate("/login");
            })
            .catch((err) => {
                dispatch(toggleSnackbar({
                    status: true,
                    message: err.message,
                    severity: "error",
                }))
                // alert(err);
                navigate("/forgotpassword");
            });
    };

    const logout = () => {
        signOut();
    };
    async function signOut() {
        try {
            await Auth.signOut();
            dispatch({ type: "LOGIN_USER_LOGOUT" });
            navigate("/");
        } catch (error) {
            console.log("error signing out: ", error);
        }
    }

    useEffect(() => {
        async function Refresh() {
            const user = await Auth.currentAuthenticatedUser();
            // console.log("USer", user);
            if (user) {
                dispatch({ type: "LOGIN_USER_SUCCESS", payload: user });
            }
        }
        Refresh();
    }, []);
    return (
        <AuthContext.Provider
            value={{ login, logout, validatePassword, sendCode, changePassword }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};