import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as Actions from "../dashboard/store/actions"
import reducer from "../dashboard/store/reducers"
import withReducer from "../dashboard/store/withReducer"
import { useNavigate } from "react-router-dom"
import ReactPaginate from "react-paginate"
import "../../App.css"
import male from "../../assets/male.png"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import moment from "moment"
import styled from "styled-components"
import statusgreen from "../../assets/kube-app/statusgreen.svg"
import statusgrey from "../../assets/kube-app/statusgrey.svg"
import statusred from "../../assets/kube-app/statusred.svg"


const Icon = styled.img`
  padding: 10px;
`

toast.configure()

const date = new Date()
const dateFormat = "YYYY-MM-DD"
const newDate = moment(new Date(date)).format(dateFormat)
const time = date.toLocaleTimeString("en-GB")

const Usercard = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const logInStatus = useSelector((state) => state.loginUser.LoggedInUser);
  const email = logInStatus.attributes.email

  /* Time worked data */
  const timeworked = useSelector((usercard) => usercard.users.timeworked)
  const [data, setData] = useState([])
  const isUserTimeWorkedDataLoading = useSelector((usersReducer) => usersReducer.users.isUserTimeWorkedDataLoading)

  //dispatch action for getting timeworked
  useEffect(() => {
    if (props.startDate && props.endDate && newDate && time) {
      dispatch(
        Actions.getKubeUserTimeWorked(
          email,
          props.startDate,
          props.endDate,
          newDate,
          time,
        )
      )
    }
  }, [time, props.startDate, props.endDate])

  const isOnline = (userstatus) => {
    if (userstatus === "Online") return 2
    if (userstatus === "Away") return 1
    return 0
  }

  //custom sort function
  const compare = (a, b) => {
    if (isOnline(a.userstatus) > isOnline(b.userstatus)) return 1
    if (isOnline(a.userstatus) < isOnline(b.userstatus)) return -1
    if (isOnline(a.userstatus) === isOnline(b.userstatus)) return 0
  }

  const sortedUsersArr = timeworked?.sort(compare).reverse()

  function handleUserselect(email) {
    navigate("/LandingIcon/" + email)
  }
  useEffect(() => {
    if (sortedUsersArr.length > 0) {
      setData(sortedUsersArr)
      setLoading(false)
    }
  }, [timeworked, data, props.startDate, props.endDate])

  const [pageNumber, setPageNumber] = useState(0)
  const usersPerPage = 12
  const pagesVisited = pageNumber * usersPerPage
  const pageCount = Math.ceil(sortedUsersArr.length / usersPerPage)
  const [filtername, setFilterName] = useState("")

  let newFilteredItems = sortedUsersArr?.filter((item) => {
    if (filtername?.toLowerCase() === "") {
      return item
    } else if (item.name?.toLowerCase().includes(filtername?.toLowerCase())) {
      return item
    } else {
      return ""
    }
  })

  const displayUsers = newFilteredItems.slice(pagesVisited, pagesVisited + usersPerPage)?.map((user, i) => {
    return (
      <div className="usercard--userdetails--container">
        <div key={user?.name} onClick={() => handleUserselect(user?.name)}>
          <div className="kube--usercard-avatar--section">
            <span>
              <img className="kube__usercard-avatar" src={male} alt="avatar" />
            </span>
            <span>
              <Icon
                src={
                  user?.userstatus === "Online"
                    ? statusgreen
                    : user?.userstatus === "Offline"
                      ? statusred
                      : statusgrey
                }
              />
            </span>
          </div>
          <div className="kube--usercard--userdetails--section">
            <div >
              <span className="kube__usercard-title">Email: </span>
              <span className="kube__usercard-value">{user?.name}</span>
            </div>
            <div>
              <span className="kube__usercard-title">Created Date: </span>
              <span className="kube__usercard-value">
                {new Date(user?.createddate).toDateString()}
              </span>
            </div>
            <div>
              <div className="card-loader-dots">
                <div className="kube__usercard-title">Time Worked: </div>
                <div className="kube__usercard-value">
                  {user?.workedhours === null ? (<div className="dot-flashing"></div>) : (<div className="kube__usercard-value">{user?.workedhours}</div>)}
                </div>
              </div>
            </div>
            <div>
              <span className="kube__usercard-title">Last Seen: </span>
              <span className="kube__usercard-value">{user?.lastseen}</span>
            </div>
            <div>
              <span className="kube__usercard-title">User Status: </span>
              <span className="kube__usercard-value">{user?.userstatus}</span>
            </div>
          </div>
        </div>
      </div>
    )
  })

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  return (
    <div className="usercard--container">
      {isUserTimeWorkedDataLoading ? (
        <div className="kube__userselect-loading"
          style={{
            width: "70vw",
            height: "85vh",
            display: "flex",
            justifyContent: "center",
            zIndex: 100,
            margin: "16rem auto auto"
          }}
        >
          <div className='dot-flashing'></div>
        </div>
      ) : (
        <>
          <div className="kube__userselect-filter">
            <input
              type="search"
              onChange={(e) => {
                setFilterName(e.target.value)
              }}
              placeholder="Search"
            />
          </div>
          <div className="kube__usercard-container">
            <div className="flex-container">{displayUsers}</div>
          </div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </>)}
    </div>
  )
}

export default withReducer("usercard", reducer)(Usercard)
