import React, { useState } from 'react'
import '../../App.css'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useAuth } from '../../utils/auth'


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: '0 auto',
      width: '38ch'
    },
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '38ch'
  },
}));

const ChangePassword = () => {
  const classes = useStyles()
  const auth = useAuth()
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const [oldpass, setOldPass] = React.useState({
    oldpassword: '',
    showOldPassword: false,
  });

  const changePassword = (event) => {
    event.preventDefault();
    auth.changePassword(oldpass.oldpassword, values.password);
  }
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleOldChange = (prop) => (event) => {
    setOldPass({ ...oldpass, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowOldPassword = () => {
    setOldPass({ ...oldpass, showOldPassword: !oldpass.showOldPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownOldPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginBackButton = () => {
    window.history.back()
  }

  return (
    <div className="kube--login--wrapper util--container">
      <div className="kube--login--logo--container">

        <h3 style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          lineHeight: '5rem',
          color: '#4B566C',
        }}>Change Password</h3>
      </div>

      <div className="kube--login--credentials">
        <form id="login-form" noValidate autoComplete="off">
          <div style={
            {
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              alignItems: 'center'
            }
          }>

            <div className="visibility--pass--eye">
              <input
                id="kube--forgotpassword--password--input"
                type={oldpass.showOldPassword ? 'text' : 'password'}
                value={oldpass.oldpassword}
                onChange={handleOldChange('oldpassword')}
                placeholder="Enter Old Password" />
              <InputAdornment
                position="end"
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  onMouseDown={handleMouseDownOldPassword}
                >
                  {oldpass.showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </div>

            <div className="visibility--pass--eye">
              <input
                id="kube--forgotpassword--password--input"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                placeholder="Enter New Password" />
              <InputAdornment
                position="end"
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </div>

          </div>
        </form>
      </div>
      <div className="kube--login--loginbutton--container">
        <button className='kube--login--buttons' style={{ cursor: 'pointer' }} onClick={changePassword} >Change Password</button>
      </div>
    </div>
  )
}

export default ChangePassword