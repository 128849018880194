export const USERCOLS = [
    {
        Header: "Total Hrs",
        accessor: "total_hours",
    },
    {
        Header: "Public T.O.",
        accessor: "public_PTO",
    },
    {
        Header: "Personal T.O.",
        accessor: "PTO_count"
    },
    {
        Header: "Eff. Working Hrs",
        accessor: "effective_hours"
    },
    {
        Header: "Hrs Worked",
        accessor: "totalworkinghours"
    },
    {
        Header: "Average Intensity  %",
        accessor: "averageintensity"
    },
    {
        Header: "Work Units",
        accessor: "Done"
    },
    {
        Header: "Compliance  %",
        accessor: "compliance"
    },
    {
        Header: "Eff Compliance",
        accessor: "effective_compliance"
    }
]
