import * as Actions from '../actions'

const initialState = {
    adminAccessManagementData: [],
    isadminAccessManagementDataLoading : false,
    setValue: [],
    setUsers: [],
    deleteUser: [],
    userWorkspaceData: [],
    userWorkspaceDataLoading : false,
    toggleUserWorkspaceData: [],
    roleUser: [],
    addNewWorkspaceData: [],
    deleteWorkspaceData: [],
}

const workspacesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ACCESS_MANAGEMENT_DATA:
            {
                return {
                    ...state,
                    adminAccessManagementData: action.payload
                }
            }
        case Actions.IS_ACCESS_MANAGEMENT_DATA_LOADING:
            {
                return {
                    ...state,
                    isadminAccessManagementDataLoading: action.payload
                }
            }
        case Actions.GET_WORKSPACE_EDIT:
            {
                return {
                    ...state,
                    setValue: action.payload
                }
            }
        case Actions.GET_ADD_USERS:
            {
                return {
                    ...state,
                    setUsers: action.payload
                }
            }
        case Actions.GET_DELETE_USERS:
            {
                return {
                    ...state,
                    deleteUser: action.payload
                }
            }
        case Actions.GET_ROLE_USERS:
            {
                return {
                    ...state,
                    roleUser: action.payload
                }
            }
        case Actions.GET_USER_WORKSPACE_DETAILS:
            {
                return {
                    ...state,
                    userWorkspaceData: action.payload
                }
            }
        case Actions.IS_USER_WORKSPACE_DATA_LOADING:
            {
                return {
                    ...state,
                    userWorkspaceDataLoading: action.payload
                }
            }
        case Actions.TOGGLE_USER_WORKSPACE:
            {
                return {
                    ...state,
                    toggleUserWorkspaceData: action.payload
                }
            }
        case Actions.POST_ADD_NEW_WORKSPACE:
            {
                return {
                    ...state,
                    addNewWorkspaceData: action.payload
                }
            }
        case Actions.POST_DELETE_WORKSPACE:
            {
                return {
                    ...state,
                    deleteWorkspaceData: action.payload
                }
            }
        default:
            {
                return state
            }
    }
}

export default workspacesReducer