import React, { useEffect, useState } from 'react';
import '../../../../App.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from '../../store/actions'
import ReactApexChart from 'react-apexcharts'


const PieChart = (props) => {
  const dispatch = useDispatch();
  const usersData = useSelector((appData) => appData.statistics.appData);//get data for app data
  const isUserAppdataLoading = useSelector((appData) => appData.statistics.userAppdataLoading)
  const [appName, setAppName] = useState([]);
  const [appTime, setAppTime] = useState([]);

  function changeDate(date) {
    const dateFormat = 'YYYY-MM-DD';
    const newDate = moment(new Date(date)).format(dateFormat);
    return newDate;
  }
  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  function valshorter(value) {
    let arr1 = []; //Empty array for values in short format
    for (let i = 0; i < value.length; i++) {
      let val = truncate(value[i], 7);
      arr1.push(val)
    }
    return arr1;
  }

  useEffect(() => {
    const d1 = changeDate(props.startDate);
    const d2 = changeDate(props.endDate);
    var data1 = [];
    data1.push(props.email)
    dispatch(Actions.getKubeAppData(props.email, d1, d2));
  }, [props.startDate, props.endDate])

  useEffect(() => {
    updateData();
  }, [usersData])

  function updateData() {

    let arr1 = usersData.appnames?.slice(0, 5);
    let arr2 = usersData.time?.slice(0, 5);
    if (arr1?.length !== undefined) {
      let arr3 = valshorter(arr1)//Short the datalabel values due to stop to the resizing of pie chart.
      setAppName(arr3);
    }
    setAppTime(arr2);
  }

  const state = {
    series: appTime || [0, 0, 0, 0, 0],
    options: {
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270
        },
      },
      chart: {
        type: 'donut',
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center'
      },
      labels: appName || [0],
      yaxis: {
        tickplacement: 'on',
        tickAmount: 5,
      },
      dataLabels: {
        textAnchor: 'middle'
      },
      responsive: [{
        breakpoint: 80,
        options: {
          chart: {
            width: '200'
          },
          legend: {
            position: 'right',
            alignItems: 'flex-start'
          }
        }
      }]
    },
  };

  return (
    <>
      {isUserAppdataLoading ? (
        <div
          style={{
            display: "flex",
            // width: "25rem",
            // height: "23rem",
            width: "26rem",
            height: "19rem",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center"
          }}
        >
          <div className='dot-flashing'></div>
        </div>
      ) : (
        <div className='landing__pieChart' style={{ alignItems: 'flex-start' }}>
          <ReactApexChart options={state.options} series={state.series} type="donut" width={470} />
        </div>
        )}
    </>
  );
}

export default PieChart;