import React, { useEffect, useState } from 'react';
import '../../../../App.css';
import "react-datepicker/dist/react-datepicker.css";
import * as Actions from '../../store/actions';
import { useSelector, useDispatch } from 'react-redux'
import Chart from "react-apexcharts";
import { useNavigate, useParams } from "react-router-dom"
import { CircularProgress } from '@material-ui/core'
import TimeWidget from './TimeWidget';


const BarWidget = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { email: userEmail } = useParams()

  const usersData = useSelector((userGraph) => userGraph.users.userGraph);//get data for graph
  const logInStatus = useSelector((state) => state.loginUser.LoggedInUser);
  const isUserGraphDataLoading = useSelector((userGraph) => userGraph.users.userGraphDataLoading)
  const email = logInStatus.attributes.email
  const [labels, setLabel] = useState([]);
  const [dataValue, setDataValue] = useState([])
  useEffect(() => {
    setData();
  }, [props.startDate, props.endDate, usersData])


  function ChangeFormateDate(oldDate) {
    const p = oldDate.split(/\D/g)
    return [p[2], p[1]].join("/")
  }

  function setData() {
    let label = Object.keys(usersData);
    let newDataValue = Object.values(usersData);
    let barData = [];
    let dateSet = [];
    if (newDataValue.length > 0) {
      for (let i = 0; i < newDataValue.length; i++) {
        let x = typeof (newDataValue[i]);
        let y, z = 0;
        if (x === 'string') {
          y = (newDataValue[i]).substring(3);
          z = parseInt(y);
          z = z / 60;
        }
        barData[i] = ((parseInt(newDataValue[i]) + z).toFixed(2));
      }
      for (let i = 0; i < newDataValue.length; i++) {
        let x = typeof (label[i]);
        dateSet[i] = (ChangeFormateDate(label[i]));
      }
    }

    setLabel(dateSet)
    setDataValue(barData);
  }

  //Function to get range of date
  const getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  let rangeDates = getDaysArray(new Date(props.startDate), new Date(props.endDate));
  rangeDates = rangeDates.map((v) => {
    let date = new Date(v),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  })


  useEffect(() => {
    //On change of startDate and endDate this effect will run and fetch data
    dispatch(Actions.getKubeUsersGraphs(props.email, rangeDates));
  }, [props.startDate, props.endDate])

  const series = [
    {
      name: "Working Time",
      data: dataValue
    }
  ]
  var options = {
    // colors: ['#F44336', '#E91E63', '#9C27B0'],
    colors: ['#F44336'],
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
        borderRadius: 5,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: labels,
      labels: {
        rotate: 0,
        style: {
          colors: ['#F44336', '#E91E63', '#9C27B0'],
          fontSize: '12px'
        }
      }
    },
    chart: {
      events: {
        //To get the selected bar date and navigate to the doughnut view
        dataPointSelection: (event, chartContext, config) => {
          let pieDate = rangeDates[config.dataPointIndex]
          dispatch(Actions.getKubeDoughnut(userEmail, pieDate, pieDate));
          props.handleBarClick(pieDate)
        }
      },
      toolbar: {
        show: false
      },
      height: 350,
      type: 'bar',
    },
  };

  return (
    <div>
      <div>
        {
          isUserGraphDataLoading ? (
            <div
              style={{
                display: "flex",
                width: "31rem",
                height: "21rem",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center"
              }}
            >
              <div className='dot-flashing'></div>
            </div>
          ) : (
              <Chart
                options={options}
                series={series}
                type="bar"
                width="500"
              />
          )
        }
      </div>
    </div >
  );
}

export default BarWidget;