import React from 'react'
import '../../App.css'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import LOGO from '../../../src/assets/logo-xl.png'
import { Link } from '@material-ui/core'
import axios from '../../api/axios'
import * as Endpoint from '../../Endpoint'
import { toggleSnackbar } from '../dashboard/store/actions/kube.actions'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: '0 auto',
      width: '38ch',
      marginTop: '33px'
    },
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '38ch',
    margin: '0 auto'
  },
}));

const Downloads = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  //const [macApp, setMacApp] = useState('')
  // const [windowsApp, setWindowsApp] = useState('')
  const classes = useStyles()

  async function getDownloadLink(appName) {
    var url = null;
    try {
      const headers = {
        "X-API-Key": process.env.REACT_APP_API_KEY
      };
      
      const response = await axios.get(Endpoint.API_DOWNLOAD, { params: { "app_name": appName },headers:headers });
      url = response['data']['app_url']
      if (appName === 'windows') {
        document.getElementById("windows_download").href = url;
      }
      if (appName === 'linux') {
        document.getElementById("linux_download").href = url;
      }
      if (appName === 'mac_m1') {
        document.getElementById("mac_m1_download").href = url;
      }
      if (appName === 'mac_intel') {
        document.getElementById("mac_intel_download").href = url;
      }
    } catch (ex) {
      if (ex.message === "Request failed with status code 424") {
        dispatch(toggleSnackbar({
          status: true,
          message: ex.message,
          severity: "error"
        }))
        // alert("error");
      }
    }

    return url;
  }

  return (
    <div className='kube__settings-container'>
      <img src={LOGO} alt="logo-xl" />
      <form className={classes.root} noValidate autoComplete="off">
        <a className='kube__downloads__button' href={getDownloadLink('windows')} id='windows_download' download="kube" >
          Download Kube for Windows OS
        </a>
        <a className='kube__downloads__button' href={getDownloadLink('linux')} id='linux_download' download="kube" >
          Download Kube for Linux OS
        </a>
        <a className='kube__downloads__button' href={getDownloadLink('mac_m1')} id='mac_m1_download' download="kube" style={{ color: 'white'}}>
          Download Kube for Mac (M1)
        </a>
        <a className='kube__downloads__button' href={getDownloadLink('mac_intel')} id='mac_intel_download' download="kube" >
          Download Kube for Mac (Intel)
        </a>
        <p style={{ marginTop: '20px', color: '#212121' }}>Installation instructions for Mac (M1) <Link onClick={() => {
          navigate('/installationinstructions')
        }} style={{ fontSize: '15px', color: '#0080FF', cursor : 'pointer' }} >
          click here
        </Link></p>
        <p style={{ marginTop: '20px', color: '#212121' }}>Installation instructions for Mac (Intel) <Link onClick={() => {
          navigate('/installationinstructionsIntel')
        }} style={{ fontSize: '15px', color: '#0080FF', cursor : 'pointer' }} >
          click here
        </Link></p>
      </form>
    </div>
  )
}

export default Downloads