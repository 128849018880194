import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { MdAddToQueue, MdClose } from 'react-icons/md'
import * as Actions from '../../dashboard/store/actions/kube.actions'
import { useSelector, useDispatch } from 'react-redux'
import { toggleSnackbar } from '../../dashboard/store/actions/kube.actions'

// Styles:
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s ease;
`

const Card = styled.div`
  width: 25rem;
  height: 40rem;
  background-color: #FFF8F0;
  box-shadow: 0 4px 15px -5px black;
  transition: all 0.25s ease;
  text-align: center;
`

const Title = styled.div`
  font-size: 1rem;
  margin-top: 3rem;
  margin-bottom: 2.5rem;
  font-weight: bold;
  color: #000000
`

const CloseButton = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  user-select: none;
  cursor: pointer;
`

const Input = styled.div`
  margin-top: 1rem;
`

const InputTag = styled.div`
  margin-bottom: 0.25rem;
  font-weight: 700;
  font-size: 0.75rem;
`

const InputField = styled.input`
  padding: 0.25rem 1rem;
  font-family: 'Roboto';
  font-size: 1rem;
  outline: none;
  width: 18rem;
  height: 2rem;
  margin-top: 0.5rem;
  background: rgba(243, 121, 94, 0.2);
  border: 1px solid #F3795E;
  box-sizing: border-box;
  &:active, &:focus {
    background: #FFFFFF;
    border: 1px solid black;
    box-shadow: none;
  }
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.3rem;
  margin: 0 auto;
  margin-top: 2rem;
  background-color: #F3795E;
  color: #FFF8F0;
  border-radius: 50%;
  box-shadow: ${props => props.isDisabled ? '0 0 0 0 transparent' : `0px 8px 20px rgba(0, 0, 0, 0.25)`};
  filter: ${props => props.isDisabled ? 'opacity(0.5)' : 'opacity(1)'};
  user-select: none;
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  transition: all 0.25s ease;
  &:active {
    box-shadow: 0 0 0 0 transparent;
  }
  &:hover {
    background-color: #E24623;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

// Functions:
const NewWorkspaceOverlayCard = ({ currUserEmail, isVisible, setShowOverlayCard }) => {
    const dispatch = useDispatch();
    const logInStatus = useSelector((state) => state.loginUser.LoggedInUser);

    //initially, some values are hardcoded for the UI part. Can be changed later according to the specific requirements 
    const [formData, setFormData] = useState({
        DirectoryId: '', username: '', bundleid: '',
        runningMode: 'AUTO_STOP', timeout: '', userencryption: '1',
        rootencryption: '1', tags_key: 'Name', tags_value: ''
    })

    const [wrapperVisibility, setWrapperVisibility] = useState(false)
    const [wrapperZIndex, setWrapperZIndex] = useState(-1)
    const [cardVisibility, setCardVisibility] = useState(false)
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setRefresh(false);
        handleRefresh();
    }, [refresh])


    //initially, some values are hardcoded for the UI part. Can be changed later according to the specific requirements 
    const handleRefresh = () => {
        setFormData({
            DirectoryId: '', username: '', bundleid: '',
            runningMode: 'AUTO_STOP', timeout: '', userencryption: '0',
            rootencryption: '0', tags_key: 'Name', tags_value: ''
        })
    }

    // Effects:
    useEffect(() => {
        if (isVisible) {
            setWrapperZIndex(1)
            setWrapperVisibility(true)
            setCardVisibility(true)
        } else {
            setCardVisibility(false)
            setWrapperVisibility(false)
            setWrapperZIndex(-1)
        }
    }, [isVisible])

    //form validation :
    const handleSubmit = (e) => {
        e.preventDefault();
        validate(formData)
    }

    const validate = (values) => {
        const errors = {};
        if (!values.DirectoryId) {
            errors.DirectoryId = "DirectoryId is required!";
        }
        if (!values.bundleid) {
            errors.bundleid = "bundleid is required!";
        }
        if (!values.rootencryption) {
            errors.rootencryption = "rootencryption Id is required!";
        }
        if (!values.runningMode) {
            errors.runningMode = "runningMode is required!";
        }
        /* if (!values.tags_key) {
            errors.tags_key = "tags_key is required!";
        } */
        if (!values.tags_value) {
            errors.tags_value = "tags_value is required!";
        }
        if (!values.timeout) {
            errors.timeout = "timeout is required!";
        }
        if (!values.username) {
            errors.username = "username is required!";
        }

        if (Object.keys(errors).length !== 0) {
            dispatch(toggleSnackbar({
                status: true,
                message: 'Can\'t create a new workspace, please fill out all the fields',
                severity: "error"
            }))
            return
        } else {
            dispatch(Actions.addNewWorkspace(formData.DirectoryId, formData.username, formData.bundleid, formData.runningMode, formData.timeout, formData.userencryption, formData.rootencryption, formData.tags_key, formData.tags_value))
            setRefresh(true);
            dispatch(toggleSnackbar({
                status: true,
                message: 'Requested for a new workspace, please wait...',
                severity: "info"
            }))
            dispatch(Actions.getUserWorkspaceDetails(currUserEmail))
        }
    }

    // Return:
    return (
        <Wrapper style={{ zIndex: wrapperZIndex, filter: wrapperVisibility ? 'opacity(1)' : 'opacity(0)' }}>
            <Card style={{ filter: cardVisibility ? 'opacity(1)' : 'opacity(0)' }}>
                <CloseButton onClick={() => setShowOverlayCard(false)}><MdClose /></CloseButton>
                <Title>Add New Workspace</Title>
                <>
                    <form>
                        <Input>
                            <InputTag>Select Directory</InputTag>
                            <select name='group' required
                                onChange={(event) =>
                                    setFormData({ ...formData, DirectoryId: event.target.value })
                                }
                                value={formData.DirectoryId}
                                style={{ width: '22rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem' }}>
                                <option hidden value>Select one...</option>
                                <option value="d-906755eecd" >corp.amazonworkspaces.com(d-906755eecd)</option>
                                <option value="d-90675d708f" >sunlight-ws-thesunlight.ai(d-90675d708f)</option>
                            </select>
                        </Input>
                        <Input >
                            <InputTag>Enter Username</InputTag>
                            <div className='orgField'>
                                <InputField type='text' required placeholder='Example-user.user_surname' name='org'
                                    onChange={(event) =>
                                        setFormData({ ...formData, username: event.target.value })
                                    }
                                    value={formData.username} style={{ width: '22rem', height: '2.5rem', border: '1px solid black', backgroundColor: 'white' }} />
                            </div>
                        </Input>
                        <Input>
                            <InputTag>Select Bundles</InputTag>
                            <select name='group' required onChange={(event) =>
                                setFormData({ ...formData, bundleid: event.target.value })
                            }
                                value={formData.bundleid} style={{ width: '22rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem' }}>
                                <option hidden value>Select one...</option>
                                <option value="wsb-clj85qzj1" >Standard with Amazon Linux 2</option>
                                <option value="wsb-8vbljg4r6" >Standard with Windows 10 (Server 2016 based)</option>
                            </select>
                        </Input>

                        <Input >
                            <InputTag>AutoStop Timeout</InputTag>
                            <select name='group' required
                                onChange={(event) =>
                                    setFormData({ ...formData, timeout: event.target.value })
                                }
                                value={formData.timeout} style={{ width: '22rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem' }}>
                                <option hidden value>Select one...</option>
                                <option value="60">60 Minutes</option>
                                <option value="120">120 Minutes</option>
                            </select>
                        </Input>
                        <Input>
                            <InputTag>User Tags Key</InputTag>
                            <select name='group'
                                onChange={(event) =>
                                    setFormData({ ...formData, tags_key: event.target.value })
                                }
                                value={formData.tags_key} required style={{ width: '22rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem' }}>
                                <option hidden value>Select one...</option>
                                <option value="Name" >Name</option>
                            </select>
                        </Input>
                        <Input >
                            <InputTag>User Tags Value</InputTag>
                            <div className='orgField'>
                                <InputField type='text' required placeholder='Enter Full Username' name='org'
                                    onChange={(event) =>
                                        setFormData({ ...formData, tags_value: event.target.value })
                                    }
                                    style={{ width: '22rem', height: '2.5rem', border: '1px solid black', backgroundColor: 'white' }} />
                            </div>
                        </Input>
                        <Button onClick={handleSubmit}><MdAddToQueue /></Button>
                    </form>
                </>
            </Card>
        </Wrapper>
    )
}

// Exports:
export default NewWorkspaceOverlayCard