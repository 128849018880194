import React, { useState, useEffect } from 'react'
import '../../../App.css'
import { Doughnut } from 'react-chartjs-2'
import { IoCaretBackSharp } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

export const doughnutOptions = {
  cutoutPercentage: 80
};

export const doughnut = {
  labels: [],
  datasets: [
    {
      label: '# of Votes',
      data: [],
      backgroundColor: [
        'rgba(215, 33, 33, 1)',
        'rgba(38, 77, 215, 1)',
        'rgba(199, 202, 53, 1)',
        'rgba(49, 184, 79, 1)',
        'rgba(88, 86, 204, 1)',
        'rgba(215, 33, 33, 1)',
        'rgba(38, 77, 215, 1)',
        'rgba(199, 202, 53, 1)',
        'rgba(49, 184, 79, 1)',
        'rgba(88, 86, 204, 1)',
        'rgba(38, 77, 215, 1)'
      ]
    },
  ],
}


const DoughnutWidget = (props) => {
  const navigate = useNavigate()
  const { email } = useParams()
  const usersData = useSelector((doughnut) => doughnut.statistics.doughnut);//get data for doughnut
  const [labels, setLabels] = useState([])
  const [data, SetData] = useState([])
  const [dataset, setDataset] = useState(doughnut)
  const [date, setDate] = useState("")
  let pieDate = props.doughnutDate;
  const sumofArray = (val) => {
    return val?.reduce((acc, value) => acc + value, 0);
  }

  function display(a) {
    var hours = Math.trunc(a / 60);
    var minutes = a % 60;
    return ((hours || 0) + "h :" + (minutes || 0) + "m");
  }

  useEffect(() => {
    let values = usersData.time?.slice(0, 10);
    setLabels(usersData.appnames?.slice(0, 10));//set app name here 
    SetData(values);//timing of app but do remember to convert it in float values from string
    setDate(pieDate)//Date is coming from doughnut date
  }, [usersData])

  useEffect(() => {
    if (labels?.length > 0 && data?.length > 0) {
      let newDataSets = {
        labels: labels,
        datasets: [
          {
            label: '# of Votes',
            data: data,
            backgroundColor: [
              'rgba(215, 33, 33, 1)',
              'rgba(38, 77, 215, 1)',
              'rgba(199, 202, 53, 1)',
              'rgba(49, 184, 79, 1)',
              'rgba(88, 86, 204, 1)',
              'rgba(215, 33, 33, 1)',
              'rgba(38, 77, 215, 1)',
              'rgba(199, 202, 53, 1)',
              'rgba(49, 184, 79, 1)',
              'rgba(88, 86, 204, 1)',
              'rgba(38, 77, 215, 1)'
            ]
          }
        ]
      }
      setDataset(newDataSets);
    }
  }, [labels, data])

  const handleDoughnutBackButton = () => {
    props.handleDoughnutBackButton();
  }

  const handleAppTime = () => {
    props.handleAppTime();
  }

  const showGridView = () => {
    navigate("/screenshotsgridview/" + email + '/' + date)
  }

  return (
    <div className="kube__tile-container">
      {Object.keys(usersData).length === 0 ? (
        <div
          style={{
            display: "flex",
            width: "35rem",
            height: "25rem",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center"
          }}
        >
          <div className='dot-flashing'></div>
        </div>
      ) : (
        <div>
          <button className="kube__back-button" onClick={handleDoughnutBackButton}><IoCaretBackSharp /></button>
          <div className='kube__doughnut-chart'>
            <Doughnut data={dataset} options={doughnutOptions} />
          </div>
          <div className='kube__doughnut-date'>{date}</div>
          <div className='kube__doughnut-time'>{display(sumofArray(usersData.time))}</div>
          {/* <div style={{ textAlign: "center" }}>
        <button className='kube__app-time' onClick={handleAppTime}>View App Time</button>
      </div> */}
          <div style={{ textAlign: "center" }}>
            <button className='kube__app-time' onClick={showGridView}>Gridview</button>
          </div>
        </div >
      )}
    </div>
  )
}

export default DoughnutWidget