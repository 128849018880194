import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { MdPersonAddAlt1, MdClose } from 'react-icons/md'
import * as Actions from '../../dashboard/store/actions/kube.actions'
import { useSelector, useDispatch } from 'react-redux'
import { toggleSnackbar } from '../../dashboard/store/actions/kube.actions'

// Styles:
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s ease;
`

const Card = styled.div`
  width: 25rem;
  height: 41rem;
  /* background-color: #FFF8F0; */
  background-color: #ECF0F3;
  box-shadow: 0 4px 15px -5px black;
  transition: all 0.25s ease;
  text-align: center;
  border-radius: 10px;
`

const Title = styled.div`
  font-size: 1rem;
  margin-top: 3rem;
  margin-bottom: 2.5rem;
  font-weight: bold;
  color: #4B566C;
  font-family: Inter;
  font-size: 20px;
`

const CloseButton = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  user-select: none;
  cursor: pointer;
`

const Input = styled.div`
  margin-top: 1rem;
`

const InputTag = styled.div`
  margin-bottom: 0.25rem;
  font-weight: 700;
  font-size: 0.75rem;
`

const InputField = styled.input`
  padding: 0.25rem 1rem;
  font-family: 'Inter';
  font-weight: bold;
  font-size: 1rem;
  outline: none;
  width: 18rem;
  height: 2rem;
  margin-top: 0.5rem;
  background: rgba(243, 121, 94, 0.2);
  border: 1px solid #F3795E;
  box-sizing: border-box;
  border-radius: 10px;
  &:active, &:focus {
    background: #FFFFFF;
    border: 1px solid black;
    box-shadow: none;
  }
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.3rem;
  margin: 0 auto;
  margin-top: 2rem;
  background-color: #F3795E;
  color: #FFF8F0;
  border-radius: 50%;
  box-shadow: ${props => props.isDisabled ? '0 0 0 0 transparent' : `0px 8px 20px rgba(0, 0, 0, 0.25)`};
  filter: ${props => props.isDisabled ? 'opacity(0.5)' : 'opacity(1)'};
  user-select: none;
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  transition: all 0.25s ease;
  &:active {
    box-shadow: 0 0 0 0 transparent;
  }
  &:hover {
    background-color: #E24623;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

// Functions:
const OverlayCard = ({ isVisible, setShowOverlayCard }) => {
  const dispatch = useDispatch();
  const logInStatus = useSelector((state) => state.loginUser.LoggedInUser)
  let response = useSelector((workspace) => workspace.workspaces.setUsers) //fetching data of overall user
  const details = useSelector((workspace) => workspace.workspaces.roleUser) //fetching data of role and group

  const authEmail = logInStatus.attributes.email //Email Id of current logged in user
  // State:
  const [user, setUser] = useState({
    name: '', email: '', org: '', group: '', role: ''
  });
  const [wrapperVisibility, setWrapperVisibility] = useState(false)
  const [wrapperZIndex, setWrapperZIndex] = useState(-1)
  const [cardVisibility, setCardVisibility] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [snackbar, setSnackbar] = useState('')

  const [checked, setChecked] = useState(false)
  useEffect(() => {
    setRefresh(false)
    handleRefresh()
  }, [refresh])

  useEffect(() => {
    setSnackbar(response)
    if (cardVisibility === true && isSubmit === true) {
      if (response === "User added Successfully") {
        dispatch(toggleSnackbar({
          status: true,
          message: response,
          severity: "info"
        }))
      }
      else {
        dispatch(toggleSnackbar({
          status: true,
          message: response,
          severity: "error"
        }))
      }
    }
  }, [response])

  const handleRefresh = () => {

    setUser({
      name: '', email: '', org: '', group: '', role: '', timezone: '', country: '', managerEmail: ''
    })
    setChecked(false)
  }

  // Effects:
  useEffect(() => {
    if (isVisible) {
      setWrapperZIndex(1)
      setWrapperVisibility(true)
      setCardVisibility(true)
    } else {
      setCardVisibility(false)
      setWrapperVisibility(false)
      setWrapperZIndex(-1)
    }
  }, [isVisible])

  //handleInput function:
  let name, value;
  const handleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  }

  //form validation :

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(user));
    setIsSubmit(true);
    if (authEmail && user.name && user.email && user.org && user.group && user.role && user.timezone && user.country && user.managerEmail) {
      dispatch(Actions.getAddUsers(authEmail, user.name, user.email, user.org, user.group, user.role, user.timezone, user.country, user.managerEmail));
      setRefresh(true);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.username = "Username is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    }
    if (!values.org) {
      errors.org = "Org Id is required!";
    }
    if (!values.group) {
      errors.group = "Group is required!";
    }
    if (!values.role) {
      errors.role = "Role is required!";
    }
    if (!values.timezone) {
      errors.timezone = "Timezone is required!";
    }
    if (!values.country) {
      errors.country = "Country is required!";
    }
    if (!values.managerEmail) {
      errors.managerEmail = "Managers Email is required!";
    }
    return errors;
  };

  // Return:
  return (
    <Wrapper style={{ zIndex: wrapperZIndex, filter: wrapperVisibility ? 'opacity(1)' : 'opacity(0)' }}>
      <Card style={{ filter: cardVisibility ? 'opacity(1)' : 'opacity(0)' }}>
        <CloseButton onClick={() => setShowOverlayCard(false)}><MdClose /></CloseButton>
        <Title>Add User</Title>
        <>
          <form>
            <select name='org' onChange={handleInput} value={user.org} style={{ width: '18rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem', boxShadow: '0px 0px' }}>
              <option hidden value>Select Organization</option>
              <option value="5e9706c43ee0632bad80b887">Readyly</option>
            </select>
            <p className='errorPresent'>{formErrors.org}</p>

            <Input>
              <InputField type='text' placeholder='Name' name='name' value={user.name} onChange={handleInput} />
            </Input>
            <p className='errorPresent'>{formErrors.username}</p>

            <Input>
              <InputField type='text' placeholder='Email' name='email' value={user.email} onChange={handleInput} />
            </Input>
            <p className='errorPresent'>{formErrors.email}</p>

            <Input>
              <InputField type='text' placeholder='Managers Email' name='managerEmail' value={user.managerEmail} onChange={handleInput} />
            </Input>
            <p className='errorPresent'>{formErrors.managerEmail}</p>

            <Input>
              <select name='group' onChange={handleInput} value={user.group} style={{ width: '18rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem', boxShadow: '0px 0px' }}>
                {/*{createSelectItems}*/}
                <option hidden value>Select Group</option>
                <option value="Engineering">Engineering</option>
                <option value="Business">Business</option>
              </select>
            </Input>
            <p className='errorPresent'>{formErrors.group}</p>

            <Input>
              <select name='role' onChange={handleInput} value={user.role} style={{ width: '18rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem', boxShadow: '0px 0px' }}>
                <option hidden value>Select Role</option>
                {/* {createSelect} */}
                <option value="User">User</option>
                <option value="Admin">Admin</option>
                <option value="Manager">Manager</option>
                <option value="SuperAdmin">SuperAdmin</option>
              </select>
            </Input>
            <p className='errorPresent'>{formErrors.role}</p>

            <select name='timezone' onChange={handleInput} value={user.timezone} style={{ width: '18rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem', boxShadow: '0px 0px' }}>
              <option hidden value>Select Timezone</option>
              <option value="IST">IST</option>
              <option value="EST">EST</option>
              <option value="PH">PH</option>
            </select>
            <p className='errorPresent'>{formErrors.timezone}</p>

            <select name='country' onChange={handleInput} value={user.country} style={{ width: '18rem', height: '2.5rem', background: 'rgba(243, 121, 94, 0.2)', border: '1px solid #F3795E', fontSize: '1rem', fontFamily: 'Roboto', padding: '0.25rem 0.7rem', boxShadow: '0px 0px' }}>
              <option hidden value>Select Country</option>
              <option value="India">India</option>
              <option value="USA">USA</option>
              <option value="Philippines">Philippines</option>
            </select>
            <p className='errorPresent'>{formErrors.country}</p>

            <Button onClick={handleSubmit}><MdPersonAddAlt1 /></Button>
          </form>
        </>
      </Card>
    </Wrapper>
  )
}


// Exports:
export default OverlayCard