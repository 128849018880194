import React, { useState } from 'react'
import BarGraph from './BarGraph'
import DoughnutWidget from '../../graphs/DoughnutWidget';
import TimeWidget from './TimeWidget';
import PopApp from '../../graphs/PopApp';

const BarGraphWidget = ({ email, startDate, endDate }) => {
    const [display, setDisplay] = useState("bar");
    const [doughnutDate, setDoughnutDate] = useState("");
    const [openModal, setOpenModal] = useState(false)

    const handleBarClick = (pieDate) => {
        if (pieDate === undefined) {
            console.log("undefined");
        }
        else {
            setDoughnutDate(pieDate);
            setDisplay("doughnut");
        }
    }

    const handleDoughnutBackButton = () => {
        setDisplay("bar");
    }

    const handleTimeClick = () => {
        setOpenModal(true);
    }

    let comp;
    let title;
    if (display === "bar") {
        comp =
            <>
                <BarGraph handleBarClick={handleBarClick} email={email} startDate={startDate} endDate={endDate} />
                <TimeWidget handleTimeClick={handleTimeClick} email={email} startDate={startDate} endDate={endDate} />
                {openModal && <PopApp open={openModal} setOpenModal={setOpenModal} />}
            </>
        title = "Bar Graph"
    }
    if (display === "doughnut") {
        comp = <DoughnutWidget doughnutDate={doughnutDate} handleDoughnutBackButton={handleDoughnutBackButton} />
        title = "Doughnut Graph"
    }

    return (
        <div>
            {comp}
        </div>
    )
}

export default BarGraphWidget