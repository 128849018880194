import React from "react";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";
import "../../../App.css";
import { useParams } from "react-router-dom";
import { Button } from "../../workspace/Bar";
function Timeslider(props) {
  const urlData = useParams();
  const urlTime = urlData.time.split(":");
  const min = new Date(2013, 6, 13, 0).getTime();
  const max = new Date(2013, 6, 13, 24).getTime();
  const value = new Date(2013, 6, 13, urlTime[0],urlTime[1],urlTime[2]).getTime();
  const step = 300000;
  // Slider ticks customization
  //const ticks = { placement: "After", largeStep: 24 * 300000 }
  const tooltip = { placement: "Before", isVisible: true };

  function renderingTicksHandler(args) {
    let totalMiliSeconds = Number(args.value);
    args.text = new Date(totalMiliSeconds).toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  function tooltipChangeHandler(args) {
    let totalMiliSeconds = Number(args.text);
    args.text = new Date(totalMiliSeconds).toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    props.handleTimeChange(args.text);
  }

  return (
    <div className="kube__slider-screenshots">
      <div id="container">
        <div className="kube__slider-wrap">
        <Button style={{
                visibility:"hidden",padding:"20px",
              }}>
            ''
        </Button>
          <div className="labeltext">Time</div>
          <SliderComponent
            id="slider"
            orientation="Vertical"
            type="MinRange"
            min={min}
            max={max}
            value={value}
            step={step}
            tooltip={tooltip}
            //ticks={ticks}
            showButtons={true}
            tooltipChange={tooltipChangeHandler}
            renderingTicks={renderingTicksHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default Timeslider;