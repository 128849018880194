import * as Actions from '../actions'

const initialState = {
   userSessionDetails : {}
}

const userSessionReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_USER_SESSION_DETAILS:
            {
                return {
                    ...state,
                    userSessionDetails: action.payload
                }
            }
        default: {
            return state
        }
    }
}

export default userSessionReducer