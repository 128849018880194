import * as Actions from '../actions'
let initialState = {
    slotsdata : [],
    isinvalidslotsDataLoading: false,
}
const invalidatedreducer = (state = initialState,actions) =>{
    switch (actions.type) {
        case Actions.GET_INVALIDATED_SLOTS_DETAILS:
            {
                return {
                    ...state,
                    slotsdata: actions.payload
                }
            }
        case Actions.GET_INVALIDATED_SLOTS_DETAILS_LOADING:
            {
                return {
                    ...state,
                    isinvalidslotsDataLoading: actions.payload
                }
            }  
        default :{
            return state
        }
    
}
}
export default invalidatedreducer