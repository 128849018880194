import React, {useState, useEffect} from 'react'
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  margin:"3%",
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ff7961',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const style = {
  position: "absolute",
  overflowY:"scroll",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  height:"75%",
  bgcolor: "#ffffff",
};

//------------------------------- MODAL FOR APPS IN DETAILED VIEW WITH TIMING IN 3RD QUADRANT--------------------------//

 function MoreAppsThird(props){
  const usersData = useSelector((doughnut)=>doughnut.statistics.doughnut);//get data for doughnut
  const [appName, setAppName] = useState([]);
  const [appTime, setAppTime] = useState([]);
  useEffect(() => {
    if(usersData.appnames<1){
        setAppName(["No Data"]);
        setAppTime(["0"]);
    }
    else{
    setAppName(usersData.appnames);
    setAppTime(usersData.time);
    }
}, [usersData])

return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-body" sx={style}>
        <div className="app">
        <div className="row">
        <Stack spacing={2}>
        {appName?.map((item,i)=>{
                    return(
                        <div key={i}>
                        <Item>
                            <span className='kube__app-container-info'>
                                <div className='kube__app-container-name'>{item}</div>
                                <div className='kube__app-container-time'>{appTime[i] + " minutes"}</div>
                            </span>
                        </Item>
                        </div>
                        
                    )
                })}
      </Stack>
        </div>
      </div>
        </Box>
      </Modal>
    </div>
  );
}


export default MoreAppsThird;
