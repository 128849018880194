import * as Actions from '../actions'

const initialState = {
    publicHolidaysData: {},
    isAdminActiveLeaveApplicationsDataLoading:false,
    adminActiveLeaveApplicationsData : {},
    userActiveLeaveApplicationsData : {}
}

const leaveManagementReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_PUBLIC_HOLIDAYS:
            {
                return {
                    ...state,
                    publicHolidaysData: action.payload
                }
            }
        case Actions.IS_ADMIN_ACTIVE_LEAVE_APPLICATIONS_DATA_LOADING:
            {
                return {
                    ...state,
                    isAdminActiveLeaveApplicationsDataLoading: action.payload
                }
            }
        case Actions.GET_ADMIN_ACTIVE_LEAVE_APPLICATIONS:
            {
                return {
                    ...state,
                    adminActiveLeaveApplicationsData: action.payload
                }
            }
        case Actions.GET_USER_ACTIVE_LEAVE_APPLICATIONS:
            {
                return {
                    ...state,
                    userActiveLeaveApplicationsData: action.payload
                }
            }
        default:
            {
                return state
            }
    }
}

export default leaveManagementReducer;