import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import DurationTimeTable from './DurationTimeTable'
// Styles:
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s ease;
`

const Card = styled.div`
  width: 44rem;
  height: 28rem;
  box-shadow: 0 4px 15px -5px black;
  transition: all 0.25s ease;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  background-color: #ECF0F3;
  border-radius: 10px;
`

const Title = styled.div`
  font-size: 20px;
  font-family : Inter;
  font-weight: 500;
  margin-top: 3rem;
  margin-bottom: 2.5rem;
  font-weight: bold;
  color: #4B566C;
`

const CloseButton = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  user-select: none;
  cursor: pointer;
`

// Functions:
const DurationTimeOverlayCard = ({ currUserEmail, isVisible, setShowOverlayCard }) => {

  const [wrapperVisibility, setWrapperVisibility] = useState(false)
  const [wrapperZIndex, setWrapperZIndex] = useState(-1)
  const [cardVisibility, setCardVisibility] = useState(false)

  // Effects:
  useEffect(() => {
    if (isVisible) {
      setWrapperZIndex(1)
      setWrapperVisibility(true)
      setCardVisibility(true)
    } else {
      setCardVisibility(false)
      setWrapperVisibility(false)
      setWrapperZIndex(-1)
    }
  }, [isVisible])

  // Return:
  return (
    <Wrapper style={{ zIndex: wrapperZIndex, filter: wrapperVisibility ? 'opacity(1)' : 'opacity(0)' }}>
      <Card style={{ filter: cardVisibility ? 'opacity(1)' : 'opacity(0)' }}>
        <CloseButton onClick={() => setShowOverlayCard(false)}><MdClose /></CloseButton>
        <Title>Session Details</Title>
        <>
          <DurationTimeTable />
        </>
      </Card>
    </Wrapper>
  )
}

// Exports:
export default DurationTimeOverlayCard
