import React, { useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import {useSelector,useDispatch} from 'react-redux'
import * as Actions from './../store/actions'
import ImageThumbnail from '../ImageThumbnail'
import { useNavigate, useParams } from "react-router-dom"
import { width } from '@mui/system';
import '../../../App.css'

const findBgCol = (total_intensity) => {
  switch (total_intensity) {
    case 0:
      // return "#E3EECA"
      return "#cbe3aa"
    case 1:
      // return "#C5E0A0"
      return "#b1d786"
    case 2:
      return "#A8D279"
    case 3:
      return "#D5D170"
    case 4:
      return "#AAB952"
    case 5:
      return "#7C9D39"
    case 6:
      return "#528124"
    default:
      return "#2D6514"
  }
}

function Invalidslotstable(props) {
  const navigate = useNavigate()
  const [startDate,setstartdate] = useState(props.invalidobj.startDate)
  const [endDate,setEndDate] = useState(props.invalidobj.endDate)
  const isinvalidslotsDataLoading = useSelector((state)=>state.invalidslots.isinvalidslotsDataLoading)

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Actions.getInvalidatedslotsdetails(props.invalidobj.email,startDate,endDate));
  }, [startDate, endDate])

  const slotsdetails = useSelector((state)=>state.invalidslots.slotsdata)
  
  const data = React.useMemo(
     () =>slotsdetails.map((item,index)=>{
      return{'camshot': item.camshot_url,'screenshot':item.screenshot_url,'recordtime':item.record_time,'keystrokes':item.keystrokes,'mouse_clicks':item.mouse_clicks,'Invalid status':item.Invalid_status}
     }),
     [slotsdetails]
 )

 
  const columns = React.useMemo(
     () =>{
   
     return(
     [
       {
         Header: 'Record time',
         accessor: 'recordtime', // accessor is the "key" in the data
         width: 5
       },
       {
         Header: 'Invalid status',
         accessor: 'Invalid status',
         width:5
       },
       {
         Header: 'Screenshot',
         accessor: 'screenshot', // accessor is the "key" in the data
         width:5
       },
       {
        Header: 'Camshot',
        accessor: 'camshot', // accessor is the "key" in the data
        width:5
      }
      
     ])} ,
     []
 )

 const NodataAvailablebetweenselecteddates = () =>{
  return(
    <div id='invalidatedslotsdiv'>
    <h1> No Invalidated data between selected date range </h1>
    </div>
  )

 }


 const generatebgcolors = (keystrokes,mouseclicks) =>{
  let mean = Math.round(((mouseclicks + keystrokes) / 2) * 10)
 let finalBgCol = findBgCol(mean)
 return finalBgCol

 }
 function handleCellClick(cell, passedBgCol) {
  let email = props.invalidobj.email
  let date = cell.row.values.recordtime.slice(0,10)
  let time = cell.row.values.recordtime.slice(11,cell.row.values.recordtime.length)
  navigate("/dashboard/" + email + "/" + date + "/" + time, {
    state: passedBgCol,
  })
}
 

  const {
   getTableProps,
   getTableBodyProps,
   headerGroups,
   rows,
   prepareRow,
 } = useTable({ columns, data }, useSortBy);

  return (
     <div style={{minHeight:520}}>

      {(isinvalidslotsDataLoading) ? (
                <div className="kube--util--center--div">
                    <div className='dot-flashing'>
                    </div>
                </div>
            ) : (slotsdetails.length > 0) ? 

                  <table {...getTableProps()} >
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                              <th
                                  {...column.getHeaderProps(column.getSortByToggleProps() )}
                                  style={{
                                    borderBottom: 'solid 3px red',
                                    color: 'White',
                                    width: '1px'
                                  }}
                              >
                                {column.render('Header')}
                                <span>
                                  {column.isSorted
                                      ? column.isSortedDesc
                                          ? '🔽'
                                          : '🔼'
                                      : ''}
                                </span>
                              </th>
                          ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                      prepareRow(row)
                      return (
                          <tr {...row.getRowProps()} style ={{"width":"10px"}}>
                            {row.cells.map(cell => {
                              if (cell.column.Header === 'Camshot'){
                                return(
                                  <td     onClick={() =>
                                    handleCellClick(cell,generatebgcolors(parseInt(cell.row.original.keystrokes),parseInt(cell.row.original.mouse_clicks)))
                                  }
                                          style={{
                                            backgroundColor: generatebgcolors(parseInt(cell.row.original.keystrokes),parseInt(cell.row.original.mouse_clicks)),
                                            color: "white",
                                            border: "1px solid black"
                                          }}
                                          {...cell.getCellProps()}
                                        >
                                          {
                                            <ImageThumbnail
                                              src={cell.value}
                                              style={{ width: 40, height: 40 }}
                                            />
                                          }
                                        </td>
                                )

                              }
                              else if (cell.column.Header === 'Screenshot'){
                                return(
                                  <td     onClick={() =>
                                    handleCellClick(cell,generatebgcolors(parseInt(cell.row.original.keystrokes),parseInt(cell.row.original.mouse_clicks)))
                                  }
                                          style={{
                                            backgroundColor: generatebgcolors(parseInt(cell.row.original.keystrokes),parseInt(cell.row.original.mouse_clicks)),
                                            color: "white",
                                            border: "1px solid black"
                                          }}
                                          {...cell.getCellProps()}
                                        >
                                          {
                                            <ImageThumbnail
                                              src={cell.value}
                                              style={{ width: 40, height: 40 }}
                                            />
                                          }
                                        </td>
                                )

                              }
                              else{
                                return (
                                  <td
                                      {...cell.getCellProps()}
                                      style={{
                                        padding: '10px',
                                        border: 'solid 1px gray',
                                      }}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                              )

                              }
                              
                            })}
                          </tr>
                      )
                    })}
                    </tbody>
                  </table> : <NodataAvailablebetweenselecteddates/>

            }
     </div>
 );
}

export default Invalidslotstable;