import React, { useState } from 'react'
import { MdDoneOutline, MdCancel, MdRefresh, MdCached } from 'react-icons/md'
import { Button } from '../Bar'
import Box from '@mui/material/Box' 
import Modal from '@mui/material/Modal' 

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
} 

const ConfirmationModal = (props) => {
    const [open, setOpen] = React.useState(false) 

    const modalHandleOpen = () => setOpen(true) 
    const modalHandleClose = () => setOpen(false) 

    const handleYes = () => {
        setOpen(false)
        props.handleWorkspaceToggle(props.cell)         
    } 

    const handleNo = () => {
        setOpen(false)
    } 

    const workspaceStatus = props.cell.row?.original.workspace.Workspaces[0].State

    return (
        <div>
            <div onClick={modalHandleOpen}>
                {(
                    workspaceStatus !== 'STOPPED' && workspaceStatus !== 'AVAILABLE') ?
                    (
                        <div className="loaderDots"><div className="dot-flashing"></div></div>
                    ) : (
                        <div className="toggle-btn-container">
                            <div className={`toggle-btn-${(workspaceStatus === "STOPPED") ? "play" : "pause"} toggle-btn-center`}></div>
                        </div>
                    )}
            </div>
            <Modal
                open={open}
                onClose={modalHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h3>Are you sure want to toggle this workspace?</h3>
                    <div className="buttons-container">
                        <Button onClick={handleYes}><MdDoneOutline /></Button>
                        <Button onClick={handleNo}><MdCancel /></Button>
                    </div>
                </Box>
            </Modal>
        </div>
    ) 
}

export default ConfirmationModal