import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import User from '../userWorkspace/User.js'
import NewWorkspaceOverlayCard from './NewWorkspaceOverlayCard.js'

const ManageUserWorkspace = () => {
  const location = useLocation()
  const [showOverlayCard, setShowOverlayCard] = useState(false)
  const currUserEmail = location.state.userEmail

  return (
    <div className="manage--user--workspace--wrapper">
      <NewWorkspaceOverlayCard currUserEmail={currUserEmail} isVisible={showOverlayCard} setShowOverlayCard={setShowOverlayCard} />

      <div className="manage--user--workspace--top--section">
        <button type="addUser"
          className='manage--user--workspace--add--wspc util--btn'
          onClick={() => setShowOverlayCard(true)}
        >
          Add New Workspace
        </button>
      </div>

        <User />
    </div>
  )
}

export default ManageUserWorkspace